import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import "./quiz.css";

const QuizCard = ({ question }) => {
    const [isRevealed, setIsRevealed] = useState(false);
  
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="question-text">{question.question}</h3>
          <div className="options-container">
            {question.options.map((option, index) => (
              <label 
                key={index} 
                className={`option-item ${isRevealed && (index + 1) === question.answer ? 'revealed' : ''}`}
              >
                <input
                  type="radio"
                  name={`question-${question.question}`}
                  checked={isRevealed && (index + 1) === question.answer}
                  disabled
                />
                <span className="option-text">{option}</span>
              </label>
            ))}
          </div>
        </div>
        <button 
          className="reveal-button"
          onClick={() => setIsRevealed(true)}
          disabled={isRevealed}
        >
          {isRevealed ? 'Answer Revealed' : 'Get Answer'}
        </button>
      </div>
    );
  };
// Example usage:
const App = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API + '/quizzes');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setQuestions(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  if (loading) {
    return <div className="loading">Loading quizzes...</div>;
  }

  if (error) {
    return <div className="error">Error loading quizzes: {error}</div>;
  }
  
  return (
    <>
     <Helmet>
        <title>Amazon FunZone Questions</title>
        <meta name="description" content="Gaet answers of all questions from Amazon FunZone, Amazon Spin and Win" />
      </Helmet>
    <div className="quiz-container">
      {questions.length > 0 ? (
        questions.map((question) => (
          <QuizCard 
            key={question._id} // Use proper unique ID from your API
            question={question}
          />
        ))
      ) : (
        <div className="no-quizzes">No quizzes available</div>
      )}
    </div>
    </>
  );
};

export default App;