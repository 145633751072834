import React, { useState, useEffect } from 'react';
import './ForeclosureCalculator.css';
import PromoCard from './Link';
const ForeclosureCalculator = () => {
  // Loan details
  const [loanAmount, setLoanAmount] = useState(5000000);
  const [interestRate, setInterestRate] = useState(8.5);
  const [loanTenure, setLoanTenure] = useState(5);
  const [interestType, setInterestType] = useState('reducing');
  
  // Foreclosure details
  const [foreclosureAmount, setForeclosureAmount] = useState(1000000);
  const [foreclosureMonth, setForeclosureMonth] = useState(12);
  
  // Results
  const [flatEmi, setFlatEmi] = useState(0);
  const [reducingEmi, setReducingEmi] = useState(0);
  const [interestSaved, setInterestSaved] = useState(0);
  const [tenureReduced, setTenureReduced] = useState(0);
  const [savings, setSavings] = useState(0);
  const [paymentSchedule, setPaymentSchedule] = useState([]);

  // Calculate EMI
  const calculateEmi = (principal, rate, tenure, type) => {
    const monthlyRate = rate / 12 / 100;
    const months = tenure * 12;
    
    if (type === 'flat') {
      const interest = principal * rate * tenure / 100;
      return (principal + interest) / months;
    } else {
      // Reducing balance
      return principal * monthlyRate * Math.pow(1 + monthlyRate, months) / 
             (Math.pow(1 + monthlyRate, months) - 1);
    }
  };

  // Calculate loan schedule with foreclosure
  const calculateForeclosure = () => {
    const flat = calculateEmi(loanAmount, interestRate, loanTenure, 'flat');
    const reducing = calculateEmi(loanAmount, interestRate, loanTenure, 'reducing');
    
    setFlatEmi(flat);
    setReducingEmi(reducing);
    
    // Calculate foreclosure impact
    const monthlyRate = interestRate / 12 / 100;
    let balance = loanAmount;
    let totalInterest = 0;
    let schedule = [];
    
    for (let month = 1; month <= loanTenure * 12; month++) {
      const interest = balance * monthlyRate;
      let principal = reducing - interest;
      
      if (month === foreclosureMonth) {
        principal += Math.min(foreclosureAmount, balance);
      }
      
      totalInterest += interest;
      balance -= principal;
      
      schedule.push({
        month,
        principal,
        interest,
        balance
      });
      
      if (balance <= 0) break;
    }
    
    // Calculate savings
    const totalWithoutForeclosure = reducing * loanTenure * 12;
    const totalWithForeclosure = (reducing * foreclosureMonth) + foreclosureAmount;
    setSavings(totalWithoutForeclosure - totalWithForeclosure);
    setPaymentSchedule(schedule);
  };

  useEffect(() => {
    calculateForeclosure();
  }, [loanAmount, interestRate, loanTenure, interestType, foreclosureAmount, foreclosureMonth]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="foreclosure-calculator">
      <h1>Loan Foreclosure Calculator</h1>
      <p className="subtitle">Compare flat vs reducing EMI and calculate savings from prepayment</p>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Loan Details</h2>
            
            <div className="input-row">
              <label htmlFor="loanAmount">Loan Amount (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="loanAmount"
                  min="100000"
                  max="50000000"
                  step="100000"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(parseInt(e.target.value) || 0)}
                  min="100000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="interestRate">Interest Rate (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="interestRate"
                  min="5"
                  max="20"
                  step="0.1"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="20"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="loanTenure">Loan Tenure (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="loanTenure"
                  min="1"
                  max="30"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(parseInt(e.target.value) || 0)}
                  min="1"
                  max="30"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label>Interest Type</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="interestType"
                    value="reducing"
                    checked={interestType === 'reducing'}
                    onChange={() => setInterestType('reducing')}
                  />
                  Reducing Balance
                </label>
                <label>
                  <input
                    type="radio"
                    name="interestType"
                    value="flat"
                    checked={interestType === 'flat'}
                    onChange={() => setInterestType('flat')}
                  />
                  Flat Rate
                </label>
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Foreclosure Details</h2>
            
            <div className="input-row">
              <label htmlFor="foreclosureAmount">Foreclosure Amount (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="foreclosureAmount"
                  min="10000"
                  max={loanAmount}
                  step="10000"
                  value={foreclosureAmount}
                  onChange={(e) => setForeclosureAmount(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={foreclosureAmount}
                  onChange={(e) => setForeclosureAmount(parseInt(e.target.value) || 0)}
                  min="10000"
                  max={loanAmount}
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="foreclosureMonth">Foreclosure After (Months)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="foreclosureMonth"
                  min="1"
                  max={loanTenure * 12}
                  value={foreclosureMonth}
                  onChange={(e) => setForeclosureMonth(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={foreclosureMonth}
                  onChange={(e) => setForeclosureMonth(parseInt(e.target.value) || 0)}
                  min="1"
                  max={loanTenure * 12}
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="emi-comparison">
            <h2>EMI Comparison</h2>
            <div className="emi-cards">
              <div className={`emi-card ${interestType === 'flat' ? 'active' : ''}`}>
                <h3>Flat Rate EMI</h3>
                <p className="amount">{formatCurrency(flatEmi)}</p>
                <p className="note">Total Interest: {formatCurrency(flatEmi * loanTenure * 12 - loanAmount)}</p>
              </div>
              
              <div className={`emi-card ${interestType === 'reducing' ? 'active' : ''}`}>
                <h3>Reducing Balance EMI</h3>
                <p className="amount">{formatCurrency(reducingEmi)}</p>
                <p className="note">Total Interest: {formatCurrency(reducingEmi * loanTenure * 12 - loanAmount)}</p>
              </div>
            </div>
          </div>
          
          <div className="foreclosure-savings">
            <h2>Foreclosure Savings</h2>
            <div className="savings-cards">
              <div className="savings-card">
                <h3>Total Savings</h3>
                <p className="amount">{formatCurrency(savings)}</p>
              </div>
              
              <div className="savings-card">
                <h3>Interest Saved</h3>
                <p className="amount">{formatCurrency(savings)}</p>
              </div>
            </div>
          </div>
          
          <div className="payment-schedule">
            <h2>Payment Schedule</h2>
            <div className="schedule-table">
              <div className="table-header">
                <div>Month</div>
                <div>Principal</div>
                <div>Interest</div>
                <div>Balance</div>
              </div>
              {paymentSchedule.slice(0, 5).map((payment) => (
                <div className="table-row" key={payment.month}>
                  <div>{payment.month}</div>
                  <div>{formatCurrency(payment.principal)}</div>
                  <div>{formatCurrency(payment.interest)}</div>
                  <div>{formatCurrency(payment.balance)}</div>
                </div>
              ))}
              {paymentSchedule.length > 5 && (
                <div className="table-footer">...showing first 5 of {paymentSchedule.length} months</div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="seo-content">
        <h2>Loan Foreclosure Calculator - Compare Flat vs Reducing EMI</h2>
        <p>
          Our <strong>loan foreclosure calculator</strong> helps you understand the financial impact of 
          prepaying your home loan, personal loan, or any other reducing balance loan. 
          Compare <strong>flat and reducing EMI</strong> calculations to see how much you can save 
          through <strong>loan closure</strong> or partial prepayment.
        </p>
        
        <h3>Key Features:</h3>
        <ul>
          <li>Compare <strong>flat rate vs reducing rate of interest</strong> calculations</li>
          <li>Calculate exact savings from <strong>loan prepayment</strong></li>
          <li>View detailed <strong>payment schedule</strong></li>
          <li>Works for <strong>HBL HRMS</strong>, <strong>KCC loan</strong>, <strong>PNB housing loan</strong> and other bank loans</li>
        </ul>
      </div>
      <PromoCard type="finance"/>
      <PromoCard/>
    </div>
  );
};

export default ForeclosureCalculator;