import { useState, useEffect } from 'react';
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    setMatches(media.matches);
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [query]);

  return matches;
};

const cardStyles = {
  cardContainer: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto 16px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #e5e7eb',
    display: 'flex',
    minHeight: '120px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
  },
  leftSection: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '16px',
    // borderRight: '2px dashed #89dd52', //border line
    borderRight: '2px dashed #ccc', //border line
    position: 'relative'
  },
  rightSection: {
    width: '75%',
    paddingLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left' // Added left alignment for entire section
  },
  offerText: {
    fontSize: '24px',
    fontWeight: 'bold',
    // color: '#dc2626', //Left text color
    // color: '#89dd52',
    color: '#2864a8',
    textAlign: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '8px',
    color: '#1f2937',
    textAlign: 'left' // Explicit left alignment
  },
  getOfferButton: {
    backgroundColor: '#2864a8',
    // backgroundColor: '#dc2626',
    // backgroundColor: '#89dd52', //button color
    color: 'white',
    padding: '8px 24px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: 'auto',
    width: '100%'
  },
  popupOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  },
  popupContent: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    maxWidth: '90%',
    width: '500px',
    position: 'relative',
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    background: 'none',
    border: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: '#666'
  },
  popupTitle: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
    color: '#333'
  },
  couponCode: {
    fontSize: '2rem',
    fontWeight: 'bold',
    margin: '1rem 0',
    padding: '1rem',
    backgroundColor: '#f3f4f6',
    borderRadius: '4px',
    wordBreak: 'break-all'
  },
  copyButton: {
    backgroundColor: '#3b82f6',
    color: 'white',
    padding: '0.75rem 2rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    marginTop: '1rem',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#2563eb'
    }
  }

};

const Card = ({ card }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMediumScreen = useMediaQuery('(min-width: 768px)');
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      
      // Reset button text after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleGetOffer = () => {
    if (card.type === 'coupon') {
      setShowCouponPopup(true);
    } else if (card.type === 'redirect') {
      setRedirecting(true);
      window.location.href = card.action;
    }
  };

  const responsiveStyles = {
    cardContainer: {
      ...cardStyles.cardContainer,
      flexDirection: isMediumScreen ? 'row' : 'column',
      backgroundColor: '#fff'
    },
    leftSection: {
      ...cardStyles.leftSection,
      width: isMediumScreen ? '25%' : '100%',
      height: isMediumScreen ? 'auto' : '120px',
      borderRight: isMediumScreen ? '2px dashed #ccc' : 'none', //border line
      borderBottom: !isMediumScreen ? '2px dashed #ccc' : 'none', //border line
      padding: isMediumScreen ? '0 16px 0 0' : '0 0 16px 0',
      marginBottom: !isMediumScreen ? '16px' : 0
    },
    rightSection: {
      ...cardStyles.rightSection,
      width: isMediumScreen ? '75%' : '100%',
      paddingLeft: isMediumScreen ? '16px' : 0
    }
  };

  return (
    <div style={responsiveStyles.cardContainer}>
      {/* Left Section with Offer Text and Button */}
      <div style={responsiveStyles.leftSection}>
        <div style={cardStyles.offerText}>{card.offerText}</div>
        <button style={cardStyles.getOfferButton}
         onClick={handleGetOffer}
         disabled={redirecting}
         >
          Get Offer
        </button>
        {showCouponPopup && (
        <div style={cardStyles.popupOverlay} onClick={() => setShowCouponPopup(false)}>
          <div style={cardStyles.popupContent} onClick={(e) => e.stopPropagation()}>
            <button 
              style={cardStyles.closeButton}
              onClick={() => setShowCouponPopup(false)}
            >
              &times;
            </button>
            <h3 style={cardStyles.popupTitle}>Your Coupon Code</h3>
            <div style={cardStyles.couponCode}>{card.action}</div>
            <button
              style={cardStyles.copyButton}
              // onClick={() => navigator.clipboard.writeText(card.action)}
              onClick={() => handleCopy(card.action)}
            >
              {isCopied ? 'Code Copied!' : 'Copy Code'}
            </button>
          </div>
        </div>
      )}
      </div>

      {/* Right Section with Content */}
      <div style={responsiveStyles.rightSection}>
        <div>
          <div style={cardStyles.title}>{card.title}</div>
          <button 
            style={{
              color: '#2563eb',
            //   color: '#89dd26',
              marginBottom: '8px',
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              padding: 0,
              fontSize: '14px',
              textAlign: 'left', // Added left alignment
              width: '100%', // Added full width
              display: 'block'
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Hide Info' : 'More Info'}
          </button>

          {isExpanded && (
            <div style={{ marginBottom: '12px' }}>
                {card.details.map((detail, index) => (
                <p 
                    key={index} 
                    style={{ 
                    color: '#4b5563', 
                    fontSize: '14px', 
                    lineHeight: '1.3', // Reduced from 1.5
                    textAlign: 'left',
                    marginBottom: '4px' // Added small bottom margin
                    }}
                >
                    <span style={{ 
                    marginRight: '8px', 
                    fontSize: '14px',
                    lineHeight: '1.3'
                  }}>•</span>
                    {detail}
                </p>
                ))}
            </div>
            )}
        </div>

        <div style={{ 
          display: 'flex', 
          gap: '16px', 
          fontSize: '14px', 
          color: '#6b7280', 
          flexWrap: 'wrap',
          textAlign: 'left' // Added left alignment
        }}>
        <div style={{  //Vendor text
          padding: '4px 8px', 
          fontSize: '14px',
          color: '#4b5563',
          fontWeight: '500',
          textAlign: 'right',
          backgroundColor: '#f3f4f6', 
          borderRadius: '4px',
        }}>
          {card.vendor}
        </div>
          {card.stats.map((stat, index) => (
            <span 
              key={index} 
              style={{ 
                padding: '4px 8px', 
                backgroundColor: '#f3f4f6', 
                borderRadius: '4px',
                textAlign: 'left' // Explicit left alignment
              }}
            >
              {stat}
            </span>
          ))}
        </div>
       
      </div>
    </div>
  );
};

// CardsPage component remains the same
const CardSkeleton = () => {
  const isMediumScreen = useMediaQuery('(min-width: 768px)');
  const shimmerAnimation = {
    background: 'linear-gradient(90deg, #f3f4f6 25%, #e5e7eb 50%, #f3f4f6 75%)',
    backgroundSize: '200% 100%',
    animation: 'shimmer 1.5s infinite',
  };

  const responsiveStyles = {
    cardContainer: {
      ...cardStyles.cardContainer,
      flexDirection: isMediumScreen ? 'row' : 'column',
    },
    leftSection: {
      ...cardStyles.leftSection,
      width: isMediumScreen ? '25%' : '100%',
      height: isMediumScreen ? 'auto' : '120px',
      borderRight: isMediumScreen ? '2px dashed #ccc' : 'none',
      borderBottom: !isMediumScreen ? '2px dashed #ccc' : 'none',
      padding: isMediumScreen ? '0 16px 0 0' : '0 0 16px 0',
      marginBottom: !isMediumScreen ? '16px' : 0
    },
    rightSection: {
      ...cardStyles.rightSection,
      width: isMediumScreen ? '75%' : '100%',
      paddingLeft: isMediumScreen ? '16px' : 0
    }
  };

  return (
    <div style={responsiveStyles.cardContainer}>
      {/* Left Section Skeleton */}
      <div style={responsiveStyles.leftSection}>
        <div style={{
          ...cardStyles.offerText,
          width: '80%',
          height: '32px',
          borderRadius: '4px',
          ...shimmerAnimation
        }} />
        <div style={{
          ...cardStyles.getOfferButton,
          height: '36px',
          backgroundColor: '#e5e7eb',
          ...shimmerAnimation
        }} />
      </div>

      {/* Right Section Skeleton */}
      <div style={responsiveStyles.rightSection}>
        <div>
          <div style={{
            ...cardStyles.title,
            width: '60%',
            height: '24px',
            borderRadius: '4px',
            marginBottom: '16px',
            ...shimmerAnimation
          }} />
          <div style={{
            width: '80px',
            height: '16px',
            borderRadius: '4px',
            marginBottom: '16px',
            ...shimmerAnimation
          }} />
          
          <div style={{ marginBottom: '12px' }}>
            {[1, 2, 3].map((_, index) => (
              <div 
                key={index}
                style={{
                  width: '100%',
                  height: '14px',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  ...shimmerAnimation
                }}
              />
            ))}
          </div>
        </div>

        <div style={{ 
          display: 'flex', 
          gap: '16px',
          flexWrap: 'wrap'
        }}>
          <div style={{
            width: '80px',
            height: '24px',
            borderRadius: '4px',
            ...shimmerAnimation
          }} />
          {[1, 2].map((_, index) => (
            <div 
              key={index}
              style={{
                width: '60px',
                height: '24px',
                borderRadius: '4px',
                ...shimmerAnimation
              }}
            />
          ))}
        </div>
      </div>

      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }
        `}
      </style>
    </div>
  );
};


const CardsPage = (props) => {
  const { endpoint} = props;
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API + endpoint);
        // const response = await fetch(process.env.REACT_APP_API + '/coupons/vendor/MakeMyTrip');
        if (!response.ok) {
          throw new Error('Oops no coupon found. Please try again later.');
        }
        const data = await response.json();
        
        // Transform API data to match our existing structure
        const transformedCards = data.data.map(coupon => ({
          id: coupon._id,
          offerText: coupon.offer_text,
          vendor: coupon.vendor,
          title: coupon.title,
          action: coupon.action,
          type: coupon.type,
          details: coupon.details,
          stats: coupon.stats
        }));

        setCards(transformedCards);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, []);

  if (loading) {
    return (
      <div>
      <CardSkeleton/>
      <CardSkeleton/>
      </div>
    );
  }

  if (error) {
    return (
      <div>
      <CardSkeleton/>
      <CardSkeleton/>
      </div>
    );
  }

  return (
    <div style={{
      // minHeight: '100vh',
      // backgroundColor: '#f9fafb',
      padding: '8px 10px 10px 10px'
    }}>
      {cards.map((card) => (
        <Card key={card.id} card={card} />
      ))}
    </div>
  );
};

export default CardsPage;