import SearchItem from "../pages/SearchItem";
import { Helmet } from 'react-helmet-async';

const BedSheetBlog = () => {
    const styles = {
        container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: 1.6,
        maxWidth: 1200,
        margin: '0 auto',
        padding: 20,
        paddingTop: 50
        },
        heading1: {
        color: '#1a237e',
        borderBottom: '2px solid #f50057',
        paddingBottom: 10,
        marginBottom: 25,
        },
        heading2: {
        color: '#283593',
        marginTop: 30,
        textAlign: 'left',
        },
        heading3: {
        paddingTop: '30px',
        color: '#3f51b5',
        textAlign: 'left',
        },
        offerSection: {
        backgroundColor: '#f5f5f5',
        padding: 15,
        borderRadius: 8,
        margin: '20px 0',
        textAlign: 'left',
        },
        keyword: {
        color: '#f50057',
        fontWeight: 'bold',
        textAlign: 'left',
        },
        termsNote: {
        backgroundColor: '#fff8e1',
        padding: 15,
        borderLeft: '4px solid #ffc107',
        margin: '15px 0',
        textAlign: 'left',
        },
        list: {
        paddingLeft: 20,
        textAlign: 'left',
        },
        dl: {
        margin: '20px 0',
        textAlign: 'left',
        },
        dt: {
        fontWeight: 'bold',
        marginTop: 15,
        textAlign: 'left',
        },
        dd: {
        marginLeft: 20,
        textAlign: 'left',
        },
        p2: {
        textAlign: 'left',
        },
        span: {
        textAlign: 'left',
        },
        li: {
        textAlign: 'left',
        }
    };

    return (
        <div>
        <Helmet>
          <title>Cotton double bedsheet combo offer online</title>
          <meta name="description" content="Unbeatable Cotton Double Bedsheet Combo Offers Online: Upgrade Your Bedroom in Style" />
        </Helmet>
        <div style={styles.container}>
          <h1 style={styles.heading1}>Unbeatable Cotton Double Bedsheet Combo Offers Online: Upgrade Your Bedroom in Style</h1>
      
          {/* Bus Booking Offers Section */}
          <section style={styles.offerSection}>
            <h2 style={styles.heading2}>Cotton double bedsheet combo offer online</h2>
            
            <p2>
            When it comes to creating a cozy and inviting bedroom, the right bedsheet can make all the difference. Cotton bedsheets, in particular, are a timeless favorite for their softness, breathability, and durability. And now, with the rise of online shopping, you can find incredible deals on cotton double bedsheet combos that not only elevate your sleep experience but also add a touch of elegance to your bedroom decor.
            </p2>
            <div>  </div>
            
      
            <h3 style={styles.heading3}>Why Choose Cotton Double Bedsheets?</h3>
            <p2>
            <span style={styles.keyword}>Comfort Redefined:</span> Cotton is known for its natural softness, making it perfect for a good night’s sleep. It’s gentle on the skin and ideal for all seasons, keeping you cool in summer and warm in winter.
            </p2>
            <div></div>
            <p2>
            <span style={styles.keyword}>Durability:</span> High-quality cotton bedsheets are long-lasting and can withstand frequent washing without losing their texture or color.
            </p2>
            <div></div>
            <p2>
            <span style={styles.keyword}>Breathability:</span> Cotton is a breathable fabric, ensuring proper airflow and preventing discomfort caused by sweating.
            </p2>
            <div></div>
            <p2><span style={styles.keyword}>Hypoallergenic:</span> For those with sensitive skin or allergies, cotton is a safe and comfortable choice.</p2>
            <h2 style={styles.heading2}>Here are the best Cotton double bedsheet combo offer online</h2>
          </section>
      
        </div>
        <div className="card-container">
        <SearchItem search="bedsheet"/>
        </div>
        </div>
      );
  };

  export default BedSheetBlog;