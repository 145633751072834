import React, { useEffect, useContext } from "react";
import HomeItem from "./HomeItem";
// import CategorySelector from "./CategorySelector";
import TrendingCards from "./TrendingCards";
// import SidePanel from "../SidePanel";
import "../Cards/card-style.css";
// import io from "socket.io-client";
import { userContext } from "../../App";
import SeoContent from '../pages/Seo'
import { Helmet } from 'react-helmet-async';

import { ToastContainer} from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// socket.onAny((eventName, ...args) => {
// //  console.log("event: " + eventName + " val: " + args);
//  //toast("Wow so easy!");
//  //alert(args);
// });

const Home = () => {
  //console.log("Called Home.js");
  const { dispatch } = useContext(userContext);
  var newDealCount = 0;
  // const [newDealCount, setNewDealCount] = useState(0);
  // var connectionOptions = {
  //   "force new connection": false,
  //   reconnectionAttempts: "Infinity",
  //   timeout: 1000,
  //   //"transports" : ["http"]
  //   transports: ["websocket", "polling", "flashsocket"],
  // };
  // // const socket = io.connect("http://localhost:5000", connectionOptions);
  // const socket = io.connect("https://eashopping.herokuapp.com/", connectionOptions);
  // socket.on("new_item", (arg) => {
  //   // console.log(arg);
  //   setNewDealCount(newDealCount+1);
  //   toast("New Deal: \n" + arg, {
  //     position: "bottom-right",
  //     autoClose: 15000,
  //     hideProgressBar: true,
  //     closeOnClick: false,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  //   //alert(arg)
  // });

  useEffect(() => {
    dispatch({ type: "Notification", payload: newDealCount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDealCount]);

  return (
    <>
      <Helmet>
          <title>Today’s Best Online Shopping Deals – Save Big Now!</title>
          <meta name="description" content="Find the best deals online shopping today! Exclusive discounts on fashion, electronics & more. Be a smart deal shopper—compare prices and save big at www.shoppingdeals.in" />
      </Helmet>
      {/* <div className="home-page"> */}
        <ToastContainer 
          limit={1} 
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"/> 
        <div className="card-container">
            {/* <CategorySelector /> */}
            <TrendingCards />
            <a target="_blank"  rel="noreferrer" href="https://www.amazon.in/gp/bestsellers/computers?_encoding=UTF8&linkCode=ib1&tag=shoppingd090d-21&linkId=a927b5ffe29803bab5d476a2099f3521&ref_=ihub_curatedcontent_f4fbaaa8-a059-47a9-b961-32aae09d16e8">Best Sellers in Computers & Accessories</a>
              <HomeItem />
              {/* <HomeComponent/> */}
              <SeoContent />
          </div>
            {/* <SidePanel/> */}


    <p >
      Get best loot deals only on shoppingDeals. Welcome to our online loot
      deals website, where you can find incredible discounts on a wide range of
      products and services. Are you looking for dress shop near me? Instead you
      can visit shoppingdeals.in for cloth shopping at an incredible price. From
      electronics to fashion, travel, and everything in between, we've got you
      covered with the best premium deals available online. Our user-friendly
      platform makes it easy to navigate through our loot deals and find the
      perfect offer that suits your needs. Whether you're looking for a bargain
      on your favorite brand or trying something new, we have something for
      everyone. Save big and shop smarter with our online elite deals website.
      Start exploring now and see what amazing deals await you! Our online
      premium loot deals website offers an extensive range of products and
      services at unbeatable prices, making it the perfect destination for savvy
      shoppers. Our team of filepart experts works tirelessly to source the best
      deals from top retailers, ensuring that you get the most value for your
      money. With easy-to-use search filters, you can quickly find what you're
      looking for and discover exciting new deals that you never knew existed.
      Plus, our website is designed with SEO in mind, meaning that you can
      easily find us on search engines and take advantage of our fantastic rs 1
      deal. Don't miss out on the savings - join our online community today and
      start enjoying huge discounts on all your favorite products and services!
    </p>
    <p>
      Title: Unveiling Unbeatable Clearance Sales and Exclusive Deals on Amazon
      and Flipkart Meta Description: Discover irresistible clearance sales and
      exclusive deals on Amazon and Flipkart. Explore a wide range of products
      at discounted prices. Hurry, limited stock available! Introduction:
      Looking for the best deals and discounts? You're in luck! Amazon and
      Flipkart, two of the leading online marketplaces, are hosting incredible
      clearance sales and offering exclusive deals on a variety of products. In
      this article, we'll dive into the world of unbeatable clearance sales and
      explore the exciting offers available on these popular platforms. Section
      1: Unbeatable Clearance Sales Amazon Clearance Sale: Amazon, renowned for
      its vast selection and competitive prices, is the go-to destination for a
      wide range of products. Their clearance sale offers customers the chance
      to save big on various categories, including electronics, home appliances,
      fashion, beauty, and more. Don't miss out on this opportunity to snag
      high-quality items at significantly discounted prices. Flipkart Clearance
      Sale: Flipkart, a household name in the Indian e-commerce market, is known
      for its extensive product range and customer-friendly services. Their
      clearance sale is a treasure trove of unbeatable deals, covering
      everything from electronics and fashion to home essentials and
      accessories. Take advantage of Flipkart's discounted prices and upgrade
      your shopping experience. Section 2: Exclusive Amazon Deals Amazon Deal of
      the Day: Keep an eye out for Amazon's "Deal of the Day," which offers
      limited-time discounts on a variety of products. From electronics and
      appliances to books and toys, these deals provide significant savings for
      savvy shoppers. Check back regularly to uncover the latest offers and grab
      your desired items at incredible prices. Amazon Prime Day: As an Amazon
      Prime member, you gain access to exclusive deals during Amazon Prime Day.
      This annual event features massive discounts and lightning deals across
      multiple categories. Enjoy the benefits of Prime membership, such as fast
      shipping and access to Prime Video and Music, while taking advantage of
      the unbeatable deals available during this special sale. Section 3:
      Exciting Flipkart Deals Flipkart Big Billion Days: Prepare yourself for
      the highly anticipated Flipkart Big Billion Days, a multi-day event where
      you'll find jaw-dropping discounts on a vast array of products. From
      smartphones and laptops to fashion and home decor, this sale has something
      for everyone. Mark your calendars and get ready to save big during this
      exciting event. Flipkart Daily Deals: Flipkart's Daily Deals section
      offers time-limited discounts on popular products. Each day brings new
      opportunities to snag incredible deals across various categories. Make it
      a habit to check Flipkart's Daily Deals regularly, as you might discover
      fantastic offers that are too good to resist. Conclusion: Amazon and
      Flipkart are your ultimate destinations for unbeatable clearance sales and
      exclusive deals. Take advantage of the discounted prices and limited-time
      offers on these popular platforms. Whether you're looking for electronics,
      fashion, home essentials, or more, these clearance sales and deals have
      you covered. Shop now and make the most of the incredible savings
      available on Amazon and Flipkart!
    </p>
    <p>
      Title: Celebrate Independence Day and Diwali with Spectacular Sales and
      Unforgettable Deals! Meta Description: Embrace the spirit of celebration
      with our Independence Day and Diwali sales. Discover fantastic discounts
      and exclusive offers on a wide range of products. Don't miss out on the
      opportunity to save big this festive season! Independence Day Sell: As the
      nation commemorates its independence, we join the celebration with an
      exciting Independence Day sell. Explore our curated collection of products
      across categories such as fashion, electronics, home decor, and more, all
      available at irresistible prices. This is the perfect time to honor our
      nation's freedom while indulging in some well-deserved retail therapy.
      Diwali Sell: Diwali, the festival of lights, is a time for joy,
      togetherness, and giving. Our Diwali sell is designed to make your festive
      season even more special with incredible discounts and exclusive offers.
      From dazzling ethnic wear and sparkling jewelry to home appliances and
      festive decor, we have everything you need to make this Diwali truly
      memorable. Why Choose Our Independence Day and Diwali Sales: - Unbeatable
      Discounts: Enjoy significant discounts on a wide range of products during
      our Independence Day and Diwali sales. We bring you the best deals to make
      your celebrations even more delightful. - Extensive Product Range:
      Discover a diverse selection of products to suit your preferences and
      needs. Whether you're looking for traditional attire, electronics, home
      essentials, or gifts for loved ones, we have you covered. - Quality
      Assurance: We prioritize quality to ensure that you receive nothing but
      the best. Each product in our Independence Day and Diwali sales meets our
      stringent quality standards, providing you with a seamless shopping
      experience. - Exclusive Offers: Be on the lookout for exclusive offers and
      promotions available only during our Independence Day and Diwali sales.
      You won't find these deals anywhere else, making your shopping experience
      with us truly exceptional. Celebrate in Style: With our Independence Day
      and Diwali sales, you can celebrate in style without breaking the bank.
      Take advantage of the incredible savings and special offers to elevate
      your festivities and create unforgettable memories. Whether you're
      shopping for yourself or searching for the perfect gifts, our sales have
      something for everyone. Conclusion: Embrace the spirit of celebration and
      seize the opportunity to save big during our Independence Day and Diwali
      sales. Explore our wide range of products, enjoy unbeatable discounts, and
      indulge in the joy of shopping this festive season. Don't miss out on this
      chance to make your Independence Day and Diwali celebrations even more
      remarkable. Shop now and revel in the excitement of our spectacular sales!
    </p>
    </>
  );
};

export default Home;
