import React, { createContext, useReducer, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./components/pages/Home";
import AdminHome from "./components/Admin/pages/AdminHome";
import Login from "./components/pages/Login";
import ProductListPage from "./components/pages/SiteMap";
import SignUp from "./components/pages/Signup";
import LogOut from "./components/pages/Logout";
import AddItem from "./components/Admin/AddItem";
import UpdateItem from "./components/Admin/UpdateItem";
import SearchTerm from "./components/Admin/SearchTerm";
import ItemClicked from "./components/Admin/ItemClicked";
import AdminLog from "./components/Admin/AdminLog";
import Category from "./components/pages/Category";
import UnderPrice from "./components/pages/UnderPrice";
import Trending from "./components/pages/Trending";
import AboutUs from "./components/pages/AboutUs";
import ItemDetalis from "./components/pages/ItemDetails";
// import BlogHomePage from "./components/blogs/BlogHome";
import BlogDetail from './components/blogs/BlogDetail'; 
import BlogRedirect from './components/blogs/BlogRedirect'; 
import BlogBedsheet from './components/Categories/BedsheetHtml'; 
import MiniAc from './components/Categories/MiniAc'; 
import Laptop from './components/Categories/Laptop'; 
import WomenFashion from './components/Categories/WomenFashion'; 
import CouponHome from './components/coupon/CouponHome'; 
import Quiz from './components/quiz/quiz'; 
import QuizAdd from './components/quiz/quizAdd'; 
import QuizUpdate from './components/quiz/quizUpdate'; 
import CouponMakeMyTrip from './components/coupon/CouponMakeMyTrip'; 
import CouponRedBus from './components/coupon/CouponRedBus'; 
import CouponAbhiBus from './components/coupon/CouponAbhiBus'; 
import CouponUpdate from './components/Admin/CouponUpdate'; 
import CouponAdd from './components/Admin/CouponAdd'; 
import Admin from "./components/pages/Admin";
import Errorpage from "./components/pages/Errorpage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Route, Routes, useLocation  } from "react-router-dom";
import ReactGa from "react-ga";
import { initialState, reducer } from './reducer/UseReducer';

import CouponPaytm from "./components/coupon/CouponPaytm";
import RentVsBuy from "./components/finance/RentVsBuy";
import CreditCardCalculator from "./components/finance/CreditCardCalculator";
import CarLeaseVsBuy from "./components/finance/CarLeasevsBuy";
import EducationCost from "./components/finance/EducationCost";
import RetirementPlanner from "./components/finance/RetirementPlanner";
import SipVsLumpSum from "./components/finance/SipVsLumpSum";
import RealEstateROI from "./components/finance/RealEstateROIBlog";
import ForclosourCalculator from "./components/finance/ForclosourCalculator";
import NriInvest from "./components/finance/NriInvestment";
import EmiCalculator from "./components/finance/EmiCalculator";
import MarriageCost from "./components/finance/MarriageCost";
import SideHustle from "./components/finance/SideHustle";
import FinanceTools from "./components/finance/FinanceTools";

export const userContext = createContext();
function App() {
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // ReactGa.initialize("UA-180229272-3");
      ReactGa.initialize("G-0TB1B3EK5X");
      ReactGa.pageview(window.location.pathname + window.location.search);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (!window.adsbygoogle) {
      window.adsbygoogle = [];
      window.adsbygoogle.push({
        google_ad_client: "ca-pub-3625231310138511",
        enable_page_level_ads: true
      });
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);



  // ExternalRedirect Component
  function ExternalRedirect({ to }) {
    const navigate = useNavigate();

    useEffect(() => {
      window.location.href = to;
    }, [to, navigate]);

    return null;
  }

  return (
    <>
      <userContext.Provider value={{ state, dispatch }}>
        <Sidebar />
        <Routes>
          <Route exact path="/" element={<Home />}>
            {/* <Home /> */}
          </Route>

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Azscaledd" element={<AddItem />}>
            {/* <AddItem /> */}
          </Route>
          {/* } */}

          <Route path="/update" element={<UpdateItem />}>
            {/* <UpdateItem /> */}
          </Route>

          <Route path="/category/:id" element={<Category />}>
            {/* <Category /> */}
          </Route>

          <Route path="/loot-deal/:description/:id" element={<ItemDetalis />}>
            {/* <ItemDetalis/> */}
          </Route> 

          <Route path="/price/:id" element={<UnderPrice />}>
            {/* <UnderPrice /> */}
          </Route>

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Szscalearchterm" element={<SearchTerm />}>
            {/* <SearchTerm /> */}
          </Route>
          {/* } */}

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Izscaltemclicked" element={<ItemClicked />}>
            {/* <ItemClicked /> */}
          </Route>
          {/* } */}

          <Route path="/Azscaldminlog" element={<AdminLog />}>
            {/* <AdminLog /> */}
          </Route>

          <Route path="/blogs/iphone-15-case" element={<ExternalRedirect to="https://blog.shoppingdeals.in/posts/iphone-15-pro-cover/" />}>
            {/* <BlogRedirect /> */}
          </Route>

          <Route path="/mini-ac-portable-ac" element={<MiniAc />}></Route>

          <Route path="/bedsheet" element={<BlogBedsheet />}>
          </Route>

          <Route path="/laptop" element={<Laptop />}>
          </Route>

          <Route path="/women-fashion-discount" element={<WomenFashion />}>
          </Route>


          <Route path="/finance/rent-vs-buy-calculator" element={<RentVsBuy />}>
          </Route>

          <Route path="/finance/retirement-planner" element={<RetirementPlanner />}></Route>
          <Route path="/finance/emi-calculator" element={<EmiCalculator />}></Route>
          <Route path="/finance/car-lease-vs-buy" element={<CarLeaseVsBuy />}></Route>
          <Route path="/finance/education-cost-calculator" element={<EducationCost />}></Route>
          <Route path="/finance/sip-vs-lumpsum-calculator" element={<SipVsLumpSum />}></Route>
          <Route path="/finance/real-estate-roi-calculator" element={<RealEstateROI />}></Route>
          <Route path="/finance/loan-forclosour-calculator" element={<ForclosourCalculator />}></Route>
          <Route path="/finance/credit-card-calculator" element={<CreditCardCalculator />}></Route>
          <Route path="/finance/nri-investment-calculator" element={<NriInvest />}></Route>
          <Route path="/finance/marriage-cost-planner" element={<MarriageCost />}></Route>
          <Route path="/finance/side-hustle-roi" element={<SideHustle />}></Route>
          <Route path="/finance" element={<FinanceTools />}></Route>
          
          {process.env.NODE_ENV === 'development' &&
            <Route path="/blogs:id" element={<BlogDetail />}>
              {/* <BlogDetail /> */}
            </Route>
          }
          {process.env.NODE_ENV === 'development' &&
            <Route path="/coupon" element={<CouponHome />}>
              {/* <CouponHome /> */}
            </Route>
          }
          <Route path="/amazon-quiz" element={<Quiz />}>
          </Route>
          {process.env.NODE_ENV === 'development' &&
            <Route path="/quiz-add" element={<QuizAdd />}>
            </Route>
          }
          {process.env.NODE_ENV === 'development' &&
            <Route path="/quiz-update" element={<QuizUpdate />}>
            </Route>
          }
          {process.env.NODE_ENV === 'development' &&
            <Route path="/coupon-update" element={<CouponUpdate />}>
              {/* <CouponUpdate /> */}
            </Route>
          }
          {process.env.NODE_ENV === 'development' &&
            <Route path="/coupon-add" element={<CouponAdd />}>
              {/* <CouponAdd /> */}
            </Route>
          }
          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/redbus-coupon-codes" element={<CouponRedBus />}>
              {/* <CouponRedBus /> */}
            </Route>
            <Route path="/abhibus-coupon-codes" element={<CouponAbhiBus />}>
              {/* <CouponRedBus /> */}
            </Route>
            <Route path="/paytm-bus-flight-promo-codes-2023" element={<CouponPaytm />}>
            </Route>
          {/* } */}
          {/* {process.env.NODE_ENV === 'development' && */}
            <Route path="/unlock-unbeatable-travel-deals-cheap-flights-hotels-makemytrip-coupons" element={ <CouponMakeMyTrip/>}>
              {/* <CouponMakeMyTrip /> */}
            </Route>
          {/* } */}
          

          <Route path="/loot-deals" element={<Trending />}>
            {/* <Trending /> */}
          </Route>

          <Route path="/trending" element={<Trending />}>
            {/* <Trending /> */}
          </Route>

          <Route path="/aboutus" element={<AboutUs />}>
            {/* <AboutUs /> */}
          </Route>

          <Route path="/project/shiva/admin" element={<Admin />}>
            {/* <Admin /> */}
          </Route>

          <Route path="/best-deals-on-amazon-india" element={<Home />}>
            {/* <Home /> */}
          </Route>

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Uzscalepdate" element={<AdminHome />}>
            {/* <AdminHome /> */}
          </Route>
          {/* } */}

          <Route path="/login" element={<Login />}>
            {/* <Login /> */}
          </Route>

          <Route path="/sitemap" element={<ProductListPage />}>
            {/* <ProductListPage /> */}
          </Route>

          <Route path="/signup" element={<SignUp />}>
            {/* <SignUp /> */}
          </Route>

          <Route path="/logout" element={<LogOut />}>
            {/* <LogOut /> */}
          </Route>

          <Route path="*" element={<Errorpage />}>
            {/* <Errorpage /> */}
          </Route>
        </Routes>
      </userContext.Provider>
      </>
  );
}

export default App;
