import React from 'react';
import { Helmet } from 'react-helmet-async';
import SearchItem from "../pages/SearchItem";

const styles = {
    container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.6,
    maxWidth: 1200,
    margin: '0 auto',
    padding: 20,
    paddingTop: 50
    },
    heading1: {
    color: '#1a237e',
    borderBottom: '2px solid #f50057',
    paddingBottom: 10,
    marginBottom: 25,
    },
    heading2: {
    color: '#283593',
    marginTop: 30,
    textAlign: 'left',
    },
    heading3: {
    paddingTop: '30px',
    color: '#3f51b5',
    textAlign: 'left',
    },
    offerSection: {
    backgroundColor: '#f5f5f5',
    padding: 15,
    borderRadius: 8,
    margin: '20px 0',
    textAlign: 'left',
    },
    keyword: {
    color: '#f50057',
    fontWeight: 'bold',
    textAlign: 'left',
    },
    termsNote: {
    backgroundColor: '#fff8e1',
    padding: 15,
    borderLeft: '4px solid #ffc107',
    margin: '15px 0',
    textAlign: 'left',
    },
    list: {
    paddingLeft: 20,
    textAlign: 'left',
    },
    dl: {
    margin: '20px 0',
    textAlign: 'left',
    },
    dt: {
    fontWeight: 'bold',
    marginTop: 15,
    textAlign: 'left',
    },
    dd: {
    marginLeft: 20,
    textAlign: 'left',
    },
    p2: {
    textAlign: 'left',
    },
    span: {
    textAlign: 'left',
    },
    li: {
    textAlign: 'left',
    }
};

const ExternalLinkButton = () => {
  const handleRedirect = () => {
    window.location.href = 'https://www.amazon.in/s?k=portable+ac&camp=3638&creative=24630&linkCode=ur2&linkId=2024a05fa37fe0437cce32e3e4d53c57&tag=shoppingd09fa-21';
    // Or for new tab: window.open('https://www.amazon.in/s?k=portable+ac&camp=3638&creative=24630&linkCode=ur2&linkId=2024a05fa37fe0437cce32e3e4d53c57&tag=shoppingd09fa-21', '_blank');
  };

  return (
    <button 
      onClick={handleRedirect}
      aria-label="Check More Mini AC on Store"
      className="btn btn-primary"
    >
      Check More Mini AC on Store
    </button>
  );
};

const MiniAc = () => {
return (
<div>
    <Helmet>
        <title>Best Portable Air Conditioners & Mini AC Deals on Amazon</title>
        <meta name="description" content="Stay Cool Anywhere: Best Portable Air Conditioners & Mini AC Deals on Amazon" />
    </Helmet>
    <div style={styles.container}>  
      <section style={styles.offerSection}>  
          <h1 style={styles.heading1}>Stay Cool Anywhere: Best Portable Air Conditioners & Mini AC Deals on Amazon</h1>  
          <h2 style={styles.heading2}>Portable Air Conditioners – Cooling On the Go!</h2>  
          <p2>  
              <strong>Portable air conditioners</strong> are a game-changer for homes, offices, and small spaces. Unlike traditional ACs, they require no permanent installation and can be moved from room to room. Whether you need cooling in a rented apartment, a garage, or a dorm, a portable AC is the perfect solution.  
          </p2>  

          <h2 style={styles.heading2}>Mini AC – Compact Cooling for Small Spaces</h2>  
          <p2>  
              A <strong>mini AC</strong> is ideal for personal cooling in tight spaces. These compact units are energy-efficient, lightweight, and often come with USB-powered options for desk use. Perfect for bedrooms, workstations, or even cars, mini ACs provide instant relief without high electricity bills.  
          </p2>  

          <h2 style={styles.heading2}>Offers on Amazon – Grab the Best Deals!</h2>  
          <p2>  
              Amazon frequently runs discounts on cooling appliances. Check out the latest <strong>offers on Amazon</strong> for portable air conditioners and mini ACs, especially during:  
              • <strong>Prime Day</strong> (July)  
              • <strong>Great Indian Festival</strong> (October)  
              • <strong>Summer Season Sales</strong> (March-June)  
              You can find <strong>extra discounts with bank offers</strong> and exchange deals.  
          </p2>  

          <h2 style={styles.heading2}>Top Portable AC Brands on Amazon</h2>  
          <p2>  
              • <strong>Honeywell</strong> – Reliable & powerful cooling  
              • <strong>LG</strong> – Energy-efficient models  
              • <strong>BLUEMONT</strong> – Budget-friendly mini ACs  
              • <strong>Voltas</strong> – Trusted Indian brand  
              • <strong>Orient Electric</strong> – Compact & portable designs  
          </p2>  

          <h2 style={styles.heading2}>How to Choose the Best Portable AC?</h2>  
          <p2>  
              ✅ <strong>BTU Rating</strong> – Higher BTU = Better cooling (8,000-14,000 BTU for rooms)  
              ✅ <strong>Energy Efficiency</strong> – Look for 5-star rated models  
              ✅ <strong>Noise Level</strong> – Below 50 dB for quiet operation  
              ✅ <strong>Portability</strong> – Wheels & lightweight design  
              ✅ <strong>Additional Features</strong> – Dehumidifier, fan mode, remote control  
          </p2>  

          <h2 style={styles.heading2}>Amazon Coupon Codes & Discount Tips</h2>  
          <p2>  
              • Use <strong>Amazon coupon codes</strong> at checkout for extra savings.  
              • Check "Today’s Deals" for limited-time offers.  
              • Subscribe to brands for exclusive discounts.  
              • Use <strong>Amazon Pay later</strong> for no-cost EMI.  
          </p2>  

          <p2><strong>Final Tips for Buying a Portable AC:</strong></p2>  
          <p2>  
              🔹 Compare prices on Amazon, Flipkart, and brand websites.  
              🔹 Read customer reviews before purchasing.  
              🔹 Check warranty & return policies.  
              🔹 Book early in summer for best availability.  
          </p2>  

          <p2>With the right portable air conditioner or mini AC, you can beat the heat without breaking the bank. Check Amazon’s latest offers and stay cool all year round! ❄️</p2>  
      </section>  
    <h2 style={styles.heading2}>Here’s a Quick Comparison of Top Portable ACs on Amazon</h2>  
  </div>  
    <div className="card-container">
        <SearchItem search="miniac"/>
    </div>
    <ExternalLinkButton />
    
</div>
);
};

export default MiniAc;

