import React, { useState, useEffect } from 'react';
import './RentVsBuy.css';
import { Helmet } from 'react-helmet-async';
import PromoCard from './Link';

const RentVsBuy = () => {
  // Rent inputs
  const [currentRent, setCurrentRent] = useState(30000);
  const [yearlyRentIncrease, setYearlyRentIncrease] = useState(10);
  const [comparisonYears, setComparisonYears] = useState(10);
  
  // SIP inputs
  const [sipAmount, setSipAmount] = useState(70000);
  const [expectedAnnualReturn, setExpectedAnnualReturn] = useState(12);
  
  // Home loan inputs
  const [homeLoanAmount, setHomeLoanAmount] = useState(8000000);
  const [loanTenure, setLoanTenure] = useState(10);
  const [interestRate, setInterestRate] = useState(8.5);
  
  // Results
  const [totalRentPaid, setTotalRentPaid] = useState(0);
  const [totalInvestmentValue, setTotalInvestmentValue] = useState(0);
  const [totalLoanCost, setTotalLoanCost] = useState(0);
  const [monthlyEmi, setMonthlyEmi] = useState(0);
  
  // Calculate EMI
  const calculateEMI = () => {
    const monthlyRate = interestRate / 12 / 100;
    const months = loanTenure * 12;
    const emi = homeLoanAmount * monthlyRate * 
               Math.pow(1 + monthlyRate, months) / 
               (Math.pow(1 + monthlyRate, months) - 1);
    setMonthlyEmi(emi);
    return emi;
  };
  
  // Calculate results
  useEffect(() => {
    // Calculate EMI
    const emi = calculateEMI();
    
    // Calculate rent and investment growth
    let rent = currentRent;
    let totalRent = 0;
    let totalInvestment = 0;
    let sip = emi - currentRent;
    
    for (let year = 1; year <= comparisonYears; year++) {
      // Calculate yearly rent
      totalRent += rent * 12;
      
      // Calculate investment growth
      for (let month = 1; month <= 12; month++) {
        totalInvestment = (totalInvestment + sip) * (1 + (expectedAnnualReturn / 12 / 100));
      }
      
      // Increase rent for next year
      rent *= (1 + yearlyRentIncrease / 100);
      sip = emi - rent;
      sip = sip > 0 ? sip : 0; // Can't have negative SIP
    }
    
    // Calculate total loan cost if buying
    const totalLoan = emi * loanTenure * 12;
    
    // Update state
    setTotalRentPaid(totalRent);
    setTotalInvestmentValue(totalInvestment);
    setTotalLoanCost(totalLoan);
  }, [
    currentRent, yearlyRentIncrease, comparisonYears,
    sipAmount, expectedAnnualReturn,
    homeLoanAmount, loanTenure, interestRate
  ]);
  
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };
  
  return (
    <div className="rent-vs-buy-container">
      {/* <h2>Rent vs Buy Calculator</h2> */}
      
      <div className="input-section">
        <div className="input-group rent-inputs">
          <h3>Rent Details</h3>
          
          <div className="input-row">
            <label htmlFor="currentRent">Current Monthly Rent (₹)</label>
            <input
              id="currentRent"
              type="number"
              value={currentRent}
              onChange={(e) => setCurrentRent(Number(e.target.value))}
              min="0"
            />
          </div>
          
          <div className="input-row">
            <label htmlFor="yearlyRentIncrease">
              Yearly Rent Increase (%): {yearlyRentIncrease}%
            </label>
            <input
              id="yearlyRentIncrease"
              type="range"
              min="0"
              max="20"
              step="0.5"
              value={yearlyRentIncrease}
              onChange={(e) => setYearlyRentIncrease(Number(e.target.value))}
            />
            <input
              type="number"
              value={yearlyRentIncrease}
              onChange={(e) => setYearlyRentIncrease(Number(e.target.value))}
              min="0"
              max="20"
              step="0.5"
              className="slider-input"
            />
          </div>
          
          <div className="input-row">
            <label htmlFor="comparisonYears">
              Comparison Period (Years): {comparisonYears}
            </label>
            <input
              id="comparisonYears"
              type="range"
              min="1"
              max="30"
              value={comparisonYears}
              onChange={(e) => setComparisonYears(Number(e.target.value))}
            />
            <input
              type="number"
              value={comparisonYears}
              onChange={(e) => setComparisonYears(Number(e.target.value))}
              min="1"
              max="30"
              className="slider-input"
            />
          </div>
        </div>
        
        <div className="input-group investment-inputs">
          <h3>Investment Details</h3>
          
          <div className="input-row">
            <label htmlFor="expectedAnnualReturn">
              Expected Annual Return (%): {expectedAnnualReturn}%
            </label>
            <input
              id="expectedAnnualReturn"
              type="range"
              min="0"
              max="20"
              step="0.5"
              value={expectedAnnualReturn}
              onChange={(e) => setExpectedAnnualReturn(Number(e.target.value))}
            />
            <input
              type="number"
              value={expectedAnnualReturn}
              onChange={(e) => setExpectedAnnualReturn(Number(e.target.value))}
              min="0"
              max="20"
              step="0.5"
              className="slider-input"
            />
          </div>
        </div>
        
        <div className="input-group loan-inputs">
          <h3>Home Loan Details</h3>
          
          <div className="input-row">
            <label htmlFor="homeLoanAmount">Home Loan Amount (₹)</label>
            <input
              id="homeLoanAmount"
              type="number"
              value={homeLoanAmount}
              onChange={(e) => setHomeLoanAmount(Number(e.target.value))}
              min="0"
            />
          </div>
          
          <div className="input-row">
            <label htmlFor="loanTenure">Loan Tenure (Years)</label>
            <input
              id="loanTenure"
              type="number"
              value={loanTenure}
              onChange={(e) => setLoanTenure(Number(e.target.value))}
              min="1"
              max="30"
            />
          </div>
          
          <div className="input-row">
            <label htmlFor="interestRate">Interest Rate (% p.a.)</label>
            <input
              id="interestRate"
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(Number(e.target.value))}
              min="0"
              max="20"
              step="0.1"
            />
          </div>
        </div>
      </div>
      
      <div className="results-section">
        <div className="result-card">
          <h3>If You Rent</h3>
          <p>Total Rent Paid: <strong>{formatCurrency(totalRentPaid)}</strong></p>
          <p>Investment Value: <strong>{formatCurrency(totalInvestmentValue)}</strong></p>
          <p>Net Savings: <strong>{formatCurrency(totalInvestmentValue - totalRentPaid)}</strong></p>
        </div>
        
        <div className="result-card">
          <h3>If You Buy</h3>
          <p>Monthly EMI: <strong>{formatCurrency(monthlyEmi)}</strong></p>
          <p>Total Loan Cost: <strong>{formatCurrency(totalLoanCost)}</strong></p>
          <p>Property Value: <strong>{formatCurrency(homeLoanAmount)}</strong></p>
        </div>
      </div>
      
      <div className="summary">
        <h3>Summary</h3>
        <p>
          After {comparisonYears} years, renting would leave you with <strong>
          {formatCurrency(totalInvestmentValue - totalRentPaid)}</strong> in savings/investments,
          while buying would give you a property worth <strong>{formatCurrency(homeLoanAmount)} </strong>
          at a total cost of <strong>{formatCurrency(totalLoanCost)}</strong>.
        </p>
      </div>
      {/* <BlogPost/> */}
    </div>
  );
};

// export default RentVsBuy;

const BlogPost = () => {
    return (
        <div>
        <Helmet>
            <title>Rent vs Buy Calculator India</title>
            <meta name="description" content="The Ultimate Guide to Deciding Between Renting vs Buying a House" />
        </Helmet>
      <article className="blog-post">
        <header className="blog-header">
          <h1>Rent vs Buy Calculator India: The Ultimate Guide to Deciding Between Renting vs Buying a House</h1>
          <p className="meta">Last Updated: {new Date().toLocaleDateString('en-IN')} • 8 min read</p>
        </header>
  
        <section className="blog-content">
          <div className="intro-section">
            <p className="lead">
              <strong>"Should I rent or buy a house in India?"</strong> This is one of the most common financial dilemmas faced by urban Indians. 
              Our <strong>Rent vs Buy Calculator India</strong> tool helps you make this crucial decision with data-driven insights.
            </p>
          </div>
  
          <div className="calculator-cta">
            <h2>Try Our Interactive Rent vs Buy Calculator</h2>
            <p>
              Before we dive deeper, use our calculator to see personalized results based on your situation:
            </p>
            {/* This would be where you embed your RentVsBuy component */}
            {/* <div className="calculator-placeholder"> */}
                <RentVsBuy/>
                <PromoCard type="finance"/>
                <PromoCard/>
              {/* [Rent vs Buy Calculator Component Would Appear Here] */}
            {/* </div> */}
          </div>
  
          <h2>Is It Better to Rent or Buy a House in India?</h2>
          <p>
            The answer depends on multiple factors including your financial situation, career stage, 
            and personal preferences. Let's examine the key considerations:
          </p>
  
          <div className="comparison-section">
            <div className="pros-cons">
              <h3>Buying a House (Owning)</h3>
              <ul className="pros">
                <li>✅ Asset creation and potential appreciation</li>
                <li>✅ Stability and sense of ownership</li>
                <li>✅ Freedom to customize your living space</li>
                <li>✅ Potential tax benefits on home loan</li>
              </ul>
              <ul className="cons">
                <li>❌ Large upfront costs (down payment, registration, etc.)</li>
                <li>❌ Long-term financial commitment</li>
                <li>❌ Maintenance responsibilities</li>
                <li>❌ Reduced liquidity of funds</li>
              </ul>
            </div>
  
            <div className="pros-cons">
              <h3>Renting a House</h3>
              <ul className="pros">
                <li>✅ Lower initial investment</li>
                <li>✅ Flexibility to relocate easily</li>
                <li>✅ No maintenance headaches</li>
                <li>✅ Potential to invest savings elsewhere</li>
              </ul>
              <ul className="cons">
                <li>❌ No asset creation</li>
                <li>❌ Annual rent increases</li>
                <li>❌ Limited control over property</li>
                <li>❌ No tax benefits like home loans</li>
              </ul>
            </div>
          </div>
  
          <h2>Key Metrics to Consider in the Rent vs Buy Decision</h2>
  
          <h3>1. Rental Yield Calculator India</h3>
          <p>
            Rental yield is a crucial metric when comparing renting vs buying a house. It's calculated as:
          </p>
          <div className="formula">
            Rental Yield = (Annual Rent / Property Price) × 100
          </div>
          <p>
            In most Indian cities, rental yields range between 2-4%. If the yield is below 3%, 
            renting might be financially better in the short to medium term.
          </p>
  
          <h3>2. Price-to-Rent Ratio</h3>
          <p>
            This ratio compares the cost of buying versus renting:
          </p>
          <div className="formula">
            Price-to-Rent Ratio = Property Price / Annual Rent
          </div>
          <ul>
            <li>Below 15: Buying is generally favorable</li>
            <li>15-20: Depends on other factors</li>
            <li>Above 20: Renting is usually better</li>
          </ul>
  
          <h2>Financial Implications of Renting vs Buying a House</h2>
          <p>
            Our <strong>Rent vs Buy Calculator India</strong> considers these complex financial aspects:
          </p>
          <ul>
            <li><strong>Home loan EMI:</strong> Principal + interest payments over the loan tenure</li>
            <li><strong>Opportunity cost:</strong> What you could earn by investing your down payment elsewhere</li>
            <li><strong>Property appreciation:</strong> Historical growth rates in your city</li>
            <li><strong>Rent increases:</strong> Typical annual hikes in your area</li>
            <li><strong>Tax benefits:</strong> Deductions available on home loan interest and principal</li>
          </ul>
  
          <div className="case-study">
            <h3>Case Study: Mumbai vs Bangalore</h3>
            <p>
              In Mumbai where property prices are extremely high (price-to-rent ratios often 30+), 
              renting frequently makes more financial sense. In Bangalore where ratios are typically 
              18-22, the decision is more nuanced and depends on specific circumstances.
            </p>
          </div>
  
          <h2>When Does Owning a House Make Sense?</h2>
          <ul>
            <li>You plan to stay in the same city for 10+ years</li>
            <li>You have stable income to service the loan</li>
            <li>You've accounted for all additional costs (maintenance, property tax, etc.)</li>
            <li>The emotional value of ownership is important to you</li>
            <li>You're buying for self-use rather than investment</li>
          </ul>
  
          <h2>When Should You Continue Renting?</h2>
          <ul>
            <li>Your job requires frequent relocation</li>
            <li>You're in the early stages of your career</li>
            <li>Rental yields in your area are very low (less then 3%)</li>
            <li>You can invest the savings for higher returns</li>
            <li>You prefer liquidity and flexibility</li>
          </ul>
  
          <div className="conclusion">
            <h2>Final Thoughts: Rent vs Buy Calculator India</h2>
            <p>
              The <strong>"rent or buy"</strong> decision is highly personal and depends on both financial 
              and lifestyle factors. Our <strong>Rent vs Buy Calculator India</strong> tool helps you 
              quantify the financial aspects, but remember to also consider:
            </p>
            <ul>
              <li>Your stage of life and career</li>
              <li>Family requirements</li>
              <li>Risk appetite</li>
              <li>Long-term goals</li>
            </ul>
            <p>
              Whether you choose to own a house or continue renting, what matters most is making 
              an informed decision that aligns with your overall financial plan.
            </p>
          </div>
        </section>
  
        <footer className="blog-footer">
          <div className="cta-box">
            <h3>Ready to Crunch Your Numbers?</h3>
            <p>
              Use our <strong>Rent vs Buy Calculator India</strong> at the top of this page to 
              get personalized insights for your situation.
            </p>
          </div>
          <div className="tags">
            <span>#RentVsBuy</span>
            <span>#HomeLoan</span>
            <span>#RealEstateIndia</span>
            <span>#FinancialPlanning</span>
          </div>
        </footer>
      </article>
      </div>
    );
  };
  
  export default BlogPost;