import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Helmet } from 'react-helmet-async';
import "./SideHustle.css";
import PromoCard from './Link';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SideHustleCalculator = () => {
  // Side hustle options
  const sideHustles = [
    { id: 'food_delivery', name: 'Food Delivery', icon: '🚗' },
    { id: 'ride_sharing', name: 'Ride Sharing', icon: '🚕' },
    { id: 'tutoring', name: 'Online Tutoring', icon: '📚' },
    { id: 'freelancing', name: 'Freelancing', icon: '💻' },
    { id: 'content_creation', name: 'Content Creation', icon: '🎥' }
  ];

  // User inputs
  const [selectedHustles, setSelectedHustles] = useState(['food_delivery', 'tutoring']);
  const [hoursPerWeek, setHoursPerWeek] = useState(15);
  const [investmentPeriod, setInvestmentPeriod] = useState(10);
  const [expectedReturn, setExpectedReturn] = useState(12);

  // Hustle-specific parameters
  const [hustleParams, setHustleParams] = useState({
    food_delivery: { hourlyRate: 150, expenses: 30, platformFee: 20 },
    ride_sharing: { hourlyRate: 200, expenses: 40, platformFee: 25 },
    tutoring: { hourlyRate: 300, expenses: 5, platformFee: 15 },
    freelancing: { hourlyRate: 400, expenses: 10, platformFee: 10 },
    content_creation: { hourlyRate: 250, expenses: 15, platformFee: 0 }
  });

  // Results
  const [comparisonResults, setComparisonResults] = useState([]);
  const [investmentProjection, setInvestmentProjection] = useState([]);

  useEffect(() => {
    calculateROI();
  }, [selectedHustles, hoursPerWeek, hustleParams, investmentPeriod, expectedReturn]);

  const calculateROI = () => {
    // Calculate net earnings for each selected hustle
    const results = selectedHustles.map(hustleId => {
      const params = hustleParams[hustleId];
      const grossWeekly = params.hourlyRate * hoursPerWeek;
      const expensesWeekly = params.expenses * hoursPerWeek;
      const platformFeeWeekly = grossWeekly * (params.platformFee / 100);
      const netWeekly = grossWeekly - expensesWeekly - platformFeeWeekly;
      const netMonthly = netWeekly * 4;
      const netYearly = netMonthly * 12;

      return {
        id: hustleId,
        name: sideHustles.find(h => h.id === hustleId).name,
        icon: sideHustles.find(h => h.id === hustleId).icon,
        grossWeekly,
        netWeekly,
        netMonthly,
        netYearly,
        expensesWeekly,
        platformFeeWeekly
      };
    });

    setComparisonResults(results);

    // Calculate investment projection for first selected hustle
    if (results.length > 0) {
      const yearlyInvestment = results[0].netYearly;
      const projections = [];
      for (let year = 0; year <= investmentPeriod; year++) {
        projections.push({
          year,
          value: yearlyInvestment * ((Math.pow(1 + expectedReturn/100, year) - 1) / (expectedReturn/100))
        });
      }
      setInvestmentProjection(projections);
    }
  };

  const handleHustleToggle = (hustleId) => {
    if (selectedHustles.includes(hustleId)) {
      setSelectedHustles(selectedHustles.filter(id => id !== hustleId));
    } else {
      setSelectedHustles([...selectedHustles, hustleId]);
    }
  };

  const handleParamChange = (hustleId, field, value) => {
    setHustleParams({
      ...hustleParams,
      [hustleId]: {
        ...hustleParams[hustleId],
        [field]: parseFloat(value) || 0
      }
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="side-hustle-calculator">
      <Helmet>
          <title>Side Hustle ROI Calculator | ShoppingDeals</title>
          <meta name="description" content="Compare gig economy options and see what your extra time could be worth" />
      </Helmet>
      <div className="calculator-header">
        <h1>Side Hustle ROI Calculator</h1>
        <p className="subtitle">Compare gig economy options and see what your extra time could be worth</p>
      </div>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Your Side Hustles</h2>
            
            <div className="hustle-options">
              {sideHustles.map(hustle => (
                <div 
                  key={hustle.id}
                  className={`hustle-option ${selectedHustles.includes(hustle.id) ? 'selected' : ''}`}
                  onClick={() => handleHustleToggle(hustle.id)}
                >
                  <span className="hustle-icon">{hustle.icon}</span>
                  <span className="hustle-name">{hustle.name}</span>
                </div>
              ))}
            </div>
            
            <div className="input-row">
              <label htmlFor="hoursPerWeek">Hours Per Week</label>
              <div className="range-container">
                <input
                  type="range"
                  id="hoursPerWeek"
                  min="1"
                  max="40"
                  value={hoursPerWeek}
                  onChange={(e) => setHoursPerWeek(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={hoursPerWeek}
                  onChange={(e) => setHoursPerWeek(parseInt(e.target.value) || 1)}
                  min="1"
                  max="40"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Investment Parameters</h2>
            
            <div className="input-row">
              <label htmlFor="investmentPeriod">Investment Period (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentPeriod"
                  min="1"
                  max="30"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value) || 1)}
                  min="1"
                  max="30"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="expectedReturn">Expected Return (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="expectedReturn"
                  min="5"
                  max="20"
                  step="0.5"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(parseFloat(e.target.value) || 5)}
                  min="5"
                  max="20"
                  step="0.5"
                />
              </div>
            </div>
          </div>
          
          {selectedHustles.length > 0 && (
            <div className="input-group">
              <h2>Hustle-Specific Parameters</h2>
              
              {selectedHustles.map(hustleId => {
                const hustle = sideHustles.find(h => h.id === hustleId);
                const params = hustleParams[hustleId];
                
                return (
                  <div key={hustleId} className="hustle-params">
                    <h3>{hustle.icon} {hustle.name}</h3>
                    
                    <div className="input-row">
                      <label>Hourly Rate (₹)</label>
                      <input
                        type="number"
                        value={params.hourlyRate}
                        onChange={(e) => handleParamChange(hustleId, 'hourlyRate', e.target.value)}
                        min="0"
                      />
                    </div>
                    
                    <div className="input-row">
                      <label>Expenses per Hour (₹)</label>
                      <input
                        type="number"
                        value={params.expenses}
                        onChange={(e) => handleParamChange(hustleId, 'expenses', e.target.value)}
                        min="0"
                      />
                    </div>
                    
                    <div className="input-row">
                      <label>Platform Fee (%)</label>
                      <input
                        type="number"
                        value={params.platformFee}
                        onChange={(e) => handleParamChange(hustleId, 'platformFee', e.target.value)}
                        min="0"
                        max="50"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        
        <div className="results-section">
          {comparisonResults.length > 0 && (
            <>
              <div className="summary-cards">
                {comparisonResults.map(result => (
                  <div key={result.id} className="summary-card">
                    <div className="hustle-header">
                      <span className="hustle-icon">{result.icon}</span>
                      <h3>{result.name}</h3>
                    </div>
                    <p className="amount">{formatCurrency(result.netWeekly)}</p>
                    <p className="subtext">Weekly Earnings</p>
                    <div className="breakdown">
                      <span>Gross: {formatCurrency(result.grossWeekly)}</span>
                      <span>Expenses: {formatCurrency(result.expensesWeekly)}</span>
                      <span>Fees: {formatCurrency(result.platformFeeWeekly)}</span>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="earnings-comparison">
                <h2>Earnings Comparison</h2>
                <div className="chart-container">
                  <Bar 
                    data={{
                      labels: comparisonResults.map(result => result.name),
                      datasets: [
                        {
                          label: 'Gross Weekly Earnings',
                          data: comparisonResults.map(result => result.grossWeekly),
                          backgroundColor: 'rgba(54, 162, 235, 0.7)'
                        },
                        {
                          label: 'Net Weekly Earnings',
                          data: comparisonResults.map(result => result.netWeekly),
                          backgroundColor: 'rgba(75, 192, 192, 0.7)'
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Gross vs Net Earnings'
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
                            }
                          }
                        }
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: function(value) {
                              return formatCurrency(value);
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
              
              <div className="investment-potential">
                <h2>Investment Potential</h2>
                <p className="potential-text">
                  Investing your <strong>{comparisonResults[0].name}</strong> earnings of {formatCurrency(comparisonResults[0].netYearly)} annually at {expectedReturn}% could grow to:
                </p>
                <div className="potential-amount">
                  {formatCurrency(investmentProjection[investmentProjection.length - 1]?.value || 0)}
                </div>
                <p className="potential-subtext">in {investmentPeriod} years</p>
                
                <div className="chart-container">
                  <Line 
                    data={{
                      labels: investmentProjection.map(item => item.year),
                      datasets: [{
                        label: 'Investment Value',
                        data: investmentProjection.map(item => item.value),
                        borderColor: '#4caf50',
                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                        fill: true,
                        tension: 0.1
                      }]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Investment Growth Over Time'
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
                            }
                          }
                        }
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: function(value) {
                              return formatCurrency(value);
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <PromoCard type="finance"/>
        <PromoCard/>
      <div className="educational-content">
        <h2>Side Hustle Insights</h2>
        
        <div className="insights">
          <div className="insight">
            <h3>Food Delivery Realities</h3>
            <ul>
              <li><strong>Hourly range:</strong> ₹120-200 after expenses</li>
              <li><strong>Peak times:</strong> Evenings and weekends pay 20-30% more</li>
              <li><strong>Vehicle costs:</strong> Fuel and maintenance average ₹30-50/hour</li>
            </ul>
          </div>
          
          <div className="insight">
            <h3>Tutoring Advantages</h3>
            <ul>
              <li><strong>Subject premium:</strong> STEM subjects pay ₹400-600/hour</li>
              <li><strong>Scalability:</strong> Group classes multiply earnings</li>
              <li><strong>Low costs:</strong> Just 5-10% platform fees typically</li>
            </ul>
          </div>
          
          <div className="insight highlight">
            <h3>The Power of Consistency</h3>
            <p>
              Just 2 hours/day of freelancing at ₹400/hour becomes ₹2.9L monthly. Invested at 12% for 10 years, 
              this grows to ₹66 lakh - potentially funding early retirement or a home downpayment.
            </p>
          </div>
        </div>
        
        <div className="case-study">
          <h3>Real Example: Swiggy vs Tutoring</h3>
          <p>
            Rahul earned ₹15k/month delivering food (4hrs/day). Switching to tutoring math (2hrs/day) earned him 
            ₹18k with less time. After 5 years, his invested tutoring earnings grew to ₹14L while his delivery 
            earnings would have been just ₹9L.
          </p>
        </div>
        
        <div className="tips-section">
          <h3>Maximizing Your Side Hustle</h3>
          <ul>
            <li><strong>Track expenses:</strong> Deduct fuel, phone, internet for tax savings</li>
            <li><strong>Skill stacking:</strong> Combine skills (design + coding) for higher rates</li>
            <li><strong>Automate investing:</strong> Set up SIPs from hustle income</li>
            <li><strong>Time blocking:</strong> Schedule hustle hours like a real job</li>
            <li><strong>Platform hopping:</strong> Use multiple apps to find best-paying gigs</li>
          </ul>
        </div>
        
        <div className="conclusion">
          <h2>Key Takeaways</h2>
          <ul>
            <li>Hourly rate matters more than total hours for wealth building</li>
            <li>Low-expense hustles (tutoring, freelancing) compound faster</li>
            <li>Consistent investing turns small earnings into life-changing sums</li>
            <li>The right 5-10 hours/week can outperform 20+ hours of low-paying gigs</li>
          </ul>
          <p>
            Use this calculator to find your optimal balance between time invested and financial return.
          </p>
        </div>
      </div>
    </div>
  );
};

const SideHustleBlog = () => {
  return (
    <div className="side-hustle-blog">
      <article className="blog-content">
        <header className="blog-header">
          <h1>Your 4 Hours Could Be Worth ₹45 Lakh: The Shocking Math of Side Hustle Investing</h1>
          <p className="meta">Last Updated: {new Date().toLocaleDateString('en-IN')} • 7 min read</p>
        </header>
        
        <section className="intro-section">
          <p className="lead">
            Our <strong>Side Hustle Calculator</strong> reveals that driving for Swiggy 4 hours daily could grow to 
            <strong>₹45 lakh in 10 years</strong> if invested. Discover how to maximize your extra time for both 
            immediate income and long-term wealth.
          </p>
        </section>
        
        <SideHustleCalculator />
        
        <section className="content-section">
          <h2>The Rise of the Gig Economy in India</h2>
          
          <p>
            India's side hustle economy has exploded, with key insights:
          </p>
          
          <ul>
            <li><strong>53 million</strong> Indians now earn from gig work</li>
            <li><strong>Top earners:</strong> Make ₹25-50k/month from 15-20hrs/week</li>
            <li><strong>Most popular:</strong> Delivery (42%), tutoring (18%), freelancing (15%)</li>
            <li><strong>Typical investment:</strong> Just 7% invest their side income</li>
          </ul>
          
          <div className="case-study">
            <h3>From Zomato to Financial Freedom</h3>
            <p>
              Priya delivered food earning ₹15k/month (6hrs/day). She switched to UX design freelancing (3hrs/day @₹500/hr), 
              earning ₹18k/month while saving 3 hours daily. After 8 years, her invested freelance earnings crossed ₹1.2 crore.
            </p>
          </div>
          
          <h2>Hourly Rate vs Time Investment</h2>
          
          <div className="comparison">
            <div className="pros-cons">
              <h3>Food Delivery</h3>
              <div className="cons">
                <h4>40 Hours/Week</h4>
                <ul>
                  <li>₹150/hr gross</li>
                  <li>₹30/hr expenses</li>
                  <li>₹4,800 weekly net</li>
                  <li>10 year investment: ₹1.2 crore</li>
                </ul>
              </div>
            </div>
            
            <div className="pros-cons">
              <h3>Tutoring + Investing</h3>
              <div className="pros">
                <h4>15 Hours/Week</h4>
                <ul>
                  <li>₹300/hr gross</li>
                  <li>₹5/hr expenses</li>
                  <li>₹3,300 weekly net</li>
                  <li>10 year investment: ₹1.8 crore</li>
                </ul>
              </div>
            </div>
          </div>
          
          <h2>Optimizing Your Side Hustle Strategy</h2>
          
          <p>
            The most successful side hustlers follow these principles:
          </p>
          
          <ol>
            <li>
              <strong>Skill premium:</strong> Add ₹100-200/hour with each new skill level
            </li>
            <li>
              <strong>Time arbitrage:</strong> Use low-demand hours for skill-building
            </li>
            <li>
              <strong>Automated investing:</strong> Set up auto-SIPs from hustle income
            </li>
            <li>
              <strong>Tax efficiency:</strong> Claim expenses and use 44ADA for freelancers
            </li>
          </ol>
          
          <div className="strategies">
            <h3>Platform-Specific Strategies</h3>
            <ul>
              <li>
                <strong>Swiggy/Zomato:</strong> Cluster deliveries in high-surge areas
              </li>
              <li>
                <strong>Urban Company:</strong> Bundle services for premium pricing
              </li>
              <li>
                <strong>Chegg/TeacherOn:</strong> Specialize in high-demand subjects
              </li>
              <li>
                <strong>Upwork/Fiverr:</strong> Build portfolio with small gigs first
              </li>
            </ul>
          </div>
          
          <div className="conclusion">
            <h2>Key Takeaways</h2>
            <ul>
              <li>High-skill hustles often outperform more hours at low wages</li>
              <li>Just ₹10k/month invested at 12% becomes ₹23L in 10 years</li>
              <li>The best hustle combines good hourly rates with scalability</li>
              <li>Time saved is often more valuable than extra income</li>
            </ul>
            <p>
              Use our <strong>Side Hustle Calculator</strong> above to find your optimal balance between 
              time investment and financial return.
            </p>
          </div>
        </section>
        
        <footer className="blog-footer">
          <div className="tags">
            <span>#SideHustle</span>
            <span>#FinancialFreedom</span>
            <span>#GigEconomy</span>
            <span>#Investing</span>
          </div>
        </footer>
      </article>
    </div>
  );
};

export default SideHustleBlog;