// ProductListPage.js
import React from 'react';
import './ProductListPage.css'; // Import CSS for styling
import productList from './productList';
// Assuming productList is an array of objects containing product names, categories, and URLs
// const productList = [
//   { name: 'Product 2', category: 'Category 1', url: '/product2' },
//   { name: 'Product 3', category: 'Category 2', url: '/product3' },
//   { name: 'Product 3', category: 'Category 3', url: '/product3' },
//   { name: 'Product 3', category: 'Category 4', url: '/product3' },
//   { name: 'Product 3', category: 'Category 5', url: '/product3' },
//   { name: 'Product 3', category: 'Category 6', url: '/product3' },
//   { name: 'Product 3', category: 'Category 7', url: '/product3' },
//   { name: 'Product 3', category: 'Category 8', url: '/product3' },
//   { name: 'Product 3', category: 'Category 9', url: '/product3' },
//   { name: 'Product 3', category: 'Category 2', url: '/product3' },
//   // Add more products as needed
// ];

function ProductListPage() {
  // Extract unique categories from productList
  const categories = [...new Set(productList.map(product => product.category))];

  return (
    <div className='top'>
    <div className="product-list-page">
      <h2>Product List</h2>
      <div className="category-columns">
      <ul>
        <li key="p1" className="product-item">
            <a href="https://shoppingdeals.in/amazon-quiz">Amazon Quiz</a>
        </li>
        <li key="p2" className="product-item">
            <a href="https://shoppingdeals.in/bedsheet">Bedsheet Deals</a>
        </li>
        <li key="p3" className="product-item">
            <a href="https://shoppingdeals.in/laptop">Laptop Deals</a>
        </li>
        <li key="p4" className="product-item">
            <a href="https://shoppingdeals.in/women-fashion-discount">Women's Fashion</a>
        </li>
        <li key="p5" className="product-item">
            <a href="https://shoppingdeals.in/mini-ac-portable-ac">Mini Ac deal</a>
        </li>
        <li key="p6" className="product-item">
            <a href="https://shoppingdeals.in/mini-ac-portable-ac">Mini Ac deal</a>
        </li>
        <li key="p7" className="product-item">
            <a href="https://shoppingdeals.in/finance/nri-investment-calculator">finance/nri-investment-calculator</a>
        </li>
        <li key="p8" className="product-item">
            <a href="https://shoppingdeals.in/finance/credit-card-calculator">finance/credit-card-calculator</a>
        </li>
        <li key="p9" className="product-item">
            <a href="https://shoppingdeals.in/finance/loan-forclosour-calculator">finance/loan-forclosour-calculator</a>
        </li>
        <li key="p10" className="product-item">
            <a href="https://shoppingdeals.in/finance/real-estate-roi-calculator">finance/real-estate-roi-calculator</a>
        </li>
        <li key="p11" className="product-item">
            <a href="https://shoppingdeals.in/finance/sip-vs-lumpsum-calculator">finance/sip-vs-lumpsum-calculator</a>
        </li>
        <li key="p12" className="product-item">
            <a href="https://shoppingdeals.in/finance/education-cost-calculator">finance/education-cost-calculator</a>
        </li>
        <li key="p13" className="product-item">
            <a href="https://shoppingdeals.in/finance/car-lease-vs-buy">finance/car-lease-vs-buy</a>
        </li>
        <li key="p14" className="product-item">
            <a href="https://shoppingdeals.in/finance/emi-calculator">finance/emi-calculator</a>
        </li>
        <li key="p15" className="product-item">
            <a href="https://shoppingdeals.in/finance/retirement-planner"> Retirement Calculator</a>
        </li>
        <li key="p16" className="product-item">
            <a href="https://shoppingdeals.in/finance/marriage-cost-planner"> Marriage Cost Planner</a>
        </li>
        <li key="p17" className="product-item">
            <a href="https://shoppingdeals.in/finance/side-hustle-roi"> Side Hustle ROI</a>
        </li>
        <li key="p17" className="product-item">
            <a href="https://shoppingdeals.in/finance"> Finance Tools</a>
        </li>
        
      </ul>
        {categories.map((category, index) => (
          <div key={index} className="category-column">
            {/* <h3>{category}</h3> */}
            <ul>
              {productList.map((product, idx) => (
                product.category === category && (
                  <li key={idx} className="product-item">
                    <a href={product.url}>{product.name}</a>
                  </li>
                )
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default ProductListPage;
