import React, { useState, useEffect } from 'react';
import './RetirementPlanner.css';
import { Chart } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Add this at the top with other imports
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const RetirementPlanner = () => {
  // User Inputs
  const [currentAge, setCurrentAge] = useState(35);
  const [retirementAge, setRetirementAge] = useState(60);
  const [lifeExpectancy, setLifeExpectancy] = useState(85);
  const [currentExpenses, setCurrentExpenses] = useState(50000);
  const [existingCorpus, setExistingCorpus] = useState(1000000);
  
  // Inflation Parameters
  const [generalInflation, setGeneralInflation] = useState(6);
  const [healthcareInflation, setHealthcareInflation] = useState(9);
  
  // Investment Parameters
  const [preRetirementReturn, setPreRetirementReturn] = useState(10);
  const [postRetirementReturn, setPostRetirementReturn] = useState(7);
  
  // Withdrawal Strategy
  const [withdrawalStrategy, setWithdrawalStrategy] = useState('fixed4percent');
  
  // Results
  const [requiredCorpus, setRequiredCorpus] = useState(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);
  const [yearlyWithdrawals, setYearlyWithdrawals] = useState([]);
  const [corpusProjection, setCorpusProjection] = useState([]);

  const calculateRetirement = () => {
    // Calculate retirement duration
    const retirementYears = lifeExpectancy - retirementAge;
    
    // Calculate inflated expenses at retirement
    let retirementExpenses = currentExpenses * 12;
    for (let year = 1; year <= (retirementAge - currentAge); year++) {
      retirementExpenses *= (1 + generalInflation / 100);
    }
    
    // Adjust for healthcare (assuming 20% of expenses are healthcare)
    const nonHealthcareExpenses = retirementExpenses * 0.8;
    const healthcareExpenses = retirementExpenses * 0.2;
    
    // Calculate required corpus
    let corpus = 0;
    let currentHealthcare = healthcareExpenses;
    let currentNonHealthcare = nonHealthcareExpenses;
    const withdrawals = [];
    const corpusProj = [];
    
    // Calculate using different withdrawal strategies
    if (withdrawalStrategy === 'fixed4percent') {
      // 4% rule - first year 4%, then adjust for inflation
      const firstYearWithdrawal = retirementExpenses;
      corpus = firstYearWithdrawal * 25; // 4% = 1/25
      
      // Project withdrawals
      let currentCorpus = corpus;
      for (let year = 1; year <= retirementYears; year++) {
        const totalExpenses = nonHealthcareExpenses * Math.pow(1 + generalInflation / 100, year - 1) +
                             healthcareExpenses * Math.pow(1 + healthcareInflation / 100, year - 1);
        
        withdrawals.push({
          year,
          age: retirementAge + year - 1,
          expenses: totalExpenses,
          withdrawal: firstYearWithdrawal * Math.pow(1 + generalInflation / 100, year - 1)
        });
        
        // Reduce corpus by withdrawal and add returns
        currentCorpus = (currentCorpus - withdrawals[year-1].withdrawal) * (1 + postRetirementReturn / 100);
        corpusProj.push({
          year,
          corpus: currentCorpus
        });
      }
    } else {
      // Dynamic withdrawal - adjust based on portfolio performance
      // More complex calculation would go here
    }
    
    // Calculate monthly investment needed
    const investmentYears = retirementAge - currentAge;
    const futureValueOfExisting = existingCorpus * Math.pow(1 + preRetirementReturn / 100, investmentYears);
    const remainingCorpus = corpus - futureValueOfExisting;
    
    let monthlyInv = 0;
    if (remainingCorpus > 0) {
      const monthlyRate = preRetirementReturn / 12 / 100;
      const months = investmentYears * 12;
      monthlyInv = remainingCorpus * monthlyRate / 
                  (Math.pow(1 + monthlyRate, months) - 1);
    }
    
    setRequiredCorpus(corpus);
    setMonthlyInvestment(monthlyInv);
    setYearlyWithdrawals(withdrawals);
    setCorpusProjection(corpusProj);
  };

  useEffect(() => {
    calculateRetirement();
  }, [
    currentAge, retirementAge, lifeExpectancy, currentExpenses, existingCorpus,
    generalInflation, healthcareInflation, preRetirementReturn, postRetirementReturn,
    withdrawalStrategy
  ]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const formatPercent = (value) => {
    return value.toFixed(1) + '%';
  };

  return (
    <div className="retirement-planner">
      <div className="planner-header">
        <h1>Inflation-Adjusted Retirement Planner</h1>
        <p className="subtitle">Will your ₹1 crore retirement fund actually be enough?</p>
      </div>
      
      <div className="planner-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Personal Details</h2>
            
            <div className="input-row">
              <label htmlFor="currentAge">Current Age</label>
              <div className="range-container">
                <input
                  type="range"
                  id="currentAge"
                  min="25"
                  max="60"
                  value={currentAge}
                  onChange={(e) => setCurrentAge(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={currentAge}
                  onChange={(e) => setCurrentAge(parseInt(e.target.value) || 0)}
                  min="25"
                  max="60"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="retirementAge">Planned Retirement Age</label>
              <div className="range-container">
                <input
                  type="range"
                  id="retirementAge"
                  min={currentAge + 1}
                  max="70"
                  value={retirementAge}
                  onChange={(e) => setRetirementAge(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={retirementAge}
                  onChange={(e) => setRetirementAge(parseInt(e.target.value) || 0)}
                  min={currentAge + 1}
                  max="70"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="lifeExpectancy">Life Expectancy</label>
              <div className="range-container">
                <input
                  type="range"
                  id="lifeExpectancy"
                  min={retirementAge + 1}
                  max="100"
                  value={lifeExpectancy}
                  onChange={(e) => setLifeExpectancy(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={lifeExpectancy}
                  onChange={(e) => setLifeExpectancy(parseInt(e.target.value) || 0)}
                  min={retirementAge + 1}
                  max="100"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Financial Details</h2>
            
            <div className="input-row">
              <label htmlFor="currentExpenses">Current Monthly Expenses (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="currentExpenses"
                  min="10000"
                  max="200000"
                  step="1000"
                  value={currentExpenses}
                  onChange={(e) => setCurrentExpenses(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={currentExpenses}
                  onChange={(e) => setCurrentExpenses(parseInt(e.target.value) || 0)}
                  min="10000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="existingCorpus">Existing Retirement Corpus (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="existingCorpus"
                  min="0"
                  max="50000000"
                  step="100000"
                  value={existingCorpus}
                  onChange={(e) => setExistingCorpus(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={existingCorpus}
                  onChange={(e) => setExistingCorpus(parseInt(e.target.value) || 0)}
                  min="0"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Inflation Parameters</h2>
            
            <div className="input-row">
              <label htmlFor="generalInflation">General Inflation (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="generalInflation"
                  min="3"
                  max="10"
                  step="0.1"
                  value={generalInflation}
                  onChange={(e) => setGeneralInflation(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={generalInflation}
                  onChange={(e) => setGeneralInflation(parseFloat(e.target.value) || 0)}
                  min="3"
                  max="10"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="healthcareInflation">Healthcare Inflation (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="healthcareInflation"
                  min="5"
                  max="15"
                  step="0.1"
                  value={healthcareInflation}
                  onChange={(e) => setHealthcareInflation(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={healthcareInflation}
                  onChange={(e) => setHealthcareInflation(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="15"
                  step="0.1"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Investment Parameters</h2>
            
            <div className="input-row">
              <label htmlFor="preRetirementReturn">Pre-Retirement Returns (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="preRetirementReturn"
                  min="5"
                  max="15"
                  step="0.1"
                  value={preRetirementReturn}
                  onChange={(e) => setPreRetirementReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={preRetirementReturn}
                  onChange={(e) => setPreRetirementReturn(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="15"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="postRetirementReturn">Post-Retirement Returns (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="postRetirementReturn"
                  min="3"
                  max="10"
                  step="0.1"
                  value={postRetirementReturn}
                  onChange={(e) => setPostRetirementReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={postRetirementReturn}
                  onChange={(e) => setPostRetirementReturn(parseFloat(e.target.value) || 0)}
                  min="3"
                  max="10"
                  step="0.1"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Withdrawal Strategy</h2>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="withdrawalStrategy"
                  value="fixed4percent"
                  checked={withdrawalStrategy === 'fixed4percent'}
                  onChange={() => setWithdrawalStrategy('fixed4percent')}
                />
                4% Rule (fixed % + inflation)
              </label>
              <label>
                <input
                  type="radio"
                  name="withdrawalStrategy"
                  value="dynamic"
                  checked={withdrawalStrategy === 'dynamic'}
                  onChange={() => setWithdrawalStrategy('dynamic')}
                />
                Dynamic Withdrawal
              </label>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="reality-check">
            <h2>Reality Check</h2>
            <div className="reality-card">
              <p>Your retirement corpus needs to be</p>
              <p className="corpus-amount">{formatCurrency(requiredCorpus)}</p>
              <p>to maintain your current lifestyle</p>
            </div>
          </div>
          
          <div className="action-required">
            <h2>Action Required</h2>
            <div className="action-card">
              <p>You need to invest</p>
              <p className="investment-amount">{formatCurrency(monthlyInvestment)}</p>
              <p>per month until retirement</p>
            </div>
          </div>
          
          <div className="withdrawal-projections">
            <h2>Annual Withdrawals</h2>
            <div className="projection-table">
              <div className="table-header">
                <div>Age</div>
                <div>Expenses</div>
                <div>Withdrawal</div>
                <div>Corpus Left</div>
              </div>
              {yearlyWithdrawals.slice(0, 5).map((year) => (
                <div className="table-row" key={year.year}>
                  <div>{year.age}</div>
                  <div>{formatCurrency(year.expenses)}</div>
                  <div>{formatCurrency(year.withdrawal)}</div>
                  <div>{formatCurrency(corpusProjection[year.year-1]?.corpus || 0)}</div>
                </div>
              ))}
              {yearlyWithdrawals.length > 5 && (
                <div className="table-footer">
                  ...showing first 5 of {yearlyWithdrawals.length} years
                </div>
              )}
            </div>
          </div>
          
          <div className="chart-placeholder">
            <h2>Corpus Projection</h2>
            <div className="chart-container">
            {/* // Replace the empty chart-placeholder div with this: */}
            {/* <div className="chart-container"> */}
                <svg width="100%" height="300" viewBox="0 0 800 300">
                  {/* X-axis */}
                  <line x1="50" y1="250" x2="750" y2="250" stroke="#ccc" strokeWidth="1" />
                  
                  {/* Y-axis */}
                  <line x1="50" y1="50" x2="50" y2="250" stroke="#ccc" strokeWidth="1" />
                  
                  {/* Corpus Projection Line */}
                  <polyline
                    fill="none"
                    stroke="#1976d2"
                    strokeWidth="2"
                    points={
                      corpusProjection.map((item, index) => 
                        `${50 + (700 / corpusProjection.length) * index},${250 - (item.corpus / requiredCorpus * 200)}`
                      ).join(' ')
                    }
                  />
                  
                  {/* Withdrawals Line */}
                  <polyline
                    fill="none"
                    stroke="#388e3c"
                    strokeWidth="2"
                    points={
                      yearlyWithdrawals.map((item, index) => 
                        `${50 + (700 / yearlyWithdrawals.length) * index},${250 - (item.withdrawal / yearlyWithdrawals[0].withdrawal * 200)}`
                      ).join(' ')
                    }
                  />
                  
                  {/* Labels */}
                  {corpusProjection.filter((_, index) => index % 5 === 0).map((item, index) => (
                    <text key={index} x={50 + (700 / corpusProjection.length) * (index * 5)} y="265" fontSize="10" textAnchor="middle">
                      {retirementAge + (index * 5)}
                    </text>
                  ))}
                  
                  {/* Legend */}
                  <rect x="600" y="30" width="20" height="2" fill="#1976d2" />
                  <text x="625" y="35" fontSize="12">Corpus</text>
                  <rect x="600" y="50" width="20" height="2" fill="#388e3c" />
                  <text x="625" y="55" fontSize="12">Withdrawals</text>
                </svg>
              {/* </div> */}
              {/* In a real implementation, you would use Chart.js or similar here */}
              <p>Visualization showing corpus growth and depletion over time</p>
              <div className="chart-legend">
                <div className="legend-item">
                  <span className="color-box corpus"></span>
                  <span>Retirement Corpus</span>
                </div>
                <div className="legend-item">
                  <span className="color-box withdrawal"></span>
                  <span>Annual Withdrawals</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetirementPlanner;