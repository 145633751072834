import React from 'react';
import { Helmet } from 'react-helmet-async';
import "./FinanceTools.css";

const FinanceHomepage = () => {
  const financeTools = [
    {
        title: "Side Hustle ROI",
        description: "Compare gig economy options & investment potential of extra income",
        icon: "💼",
        url: "https://shoppingdeals.in/finance/side-hustle-roi",
        color: "cyan-gradient"
    },
    {
        title: "Rent vs Buy Calculator",
        description: "Compare long-term costs of renting vs buying a home in Indian cities",
        icon: "🏡",
        url: "https://shoppingdeals.in/finance/rent-vs-buy-calculator",
        color: "amber-gradient"
    },
    {
      title: "Loan Foreclosure Calculator",
      description: "Calculate savings from prepaying home/car loans with penalty considerations",
      icon: "🏦",
      url: "https://shoppingdeals.in/finance/loan-forclosour-calculator",
      color: "green-gradient"
    },
    {
      title: "Real Estate ROI Calculator",
      description: "Compare rental yields, capital appreciation & tax implications",
      icon: "🏠",
      url: "https://shoppingdeals.in/finance/real-estate-roi-calculator",
      color: "red-gradient"
    },
    {
      title: "SIP vs Lumpsum Calculator",
      description: "Which investment strategy works better in different market conditions?",
      icon: "📈",
      url: "https://shoppingdeals.in/finance/sip-vs-lumpsum-calculator",
      color: "yellow-gradient"
    },
    {
      title: "Education Cost Calculator",
      description: "Plan for Indian & abroad education with inflation & currency risk",
      icon: "🎓",
      url: "https://shoppingdeals.in/finance/education-cost-calculator",
      color: "indigo-gradient"
    },
    {
      title: "Car Lease vs Buy",
      description: "Compare total cost of ownership for leasing vs purchasing vehicles",
      icon: "🚗",
      url: "https://shoppingdeals.in/finance/car-lease-vs-buy",
      color: "orange-gradient"
    },
    {
      title: "EMI Calculator",
      description: "Calculate loan EMIs for homes, cars, education with prepayment options",
      icon: "🧮",
      url: "https://shoppingdeals.in/finance/emi-calculator",
      color: "teal-gradient"
    },
    {
      title: "Retirement Calculator",
      description: "Plan your corpus needed accounting for inflation & medical costs",
      icon: "🧓",
      url: "https://shoppingdeals.in/finance/retirement-planner",
      color: "pink-gradient"
    },
    {
      title: "Marriage Cost Planner",
      description: "Budget for Indian weddings across city tiers with investment comparison",
      icon: "💍",
      url: "https://shoppingdeals.in/finance/marriage-cost-planner",
      color: "rose-gradient"
    },
    {
    title: "NRI Investment Calculator",
    description: "Compare NRE FDs, Indian mutual funds & global ETFs with currency risk analysis",
    icon: "🌍",
    url: "https://shoppingdeals.in/finance/nri-investment-calculator",
    color: "blue-gradient"
    },
    {
    title: "Credit Card Calculator",
    description: "Avalanche vs Snowball payoff methods with interest savings comparison",
    icon: "💳",
    url: "https://shoppingdeals.in/finance/credit-card-calculator",
    color: "purple-gradient"
    }
  ];

  return (
    <div className="finance-homepage">
      <Helmet>
        <title>Financial Calculators & Tools | ShoppingDeals</title>
        <meta name="description" content="Comprehensive collection of financial calculators for investments, loans, retirement planning and more" />
      </Helmet>

      <header className="finance-header">
        <div className="header-content">
          <h1>Financial Calculators</h1>
          <p>Make smarter money decisions with our interactive tools</p>
        </div>
      </header>

      <main className="finance-main">
        <div className="tools-grid">
          {financeTools.map((tool, index) => (
            <a 
              key={index}
              href={tool.url}
              className={`tool-card ${tool.color}`}
            >
              <div className="card-content">
                <div className="tool-icon">{tool.icon}</div>
                <h2>{tool.title}</h2>
                <p>{tool.description}</p>
                <div className="card-footer">
                  <span>Explore Tool</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </a>
          ))}
        </div>
      </main>

      <section className="value-props">
        <div className="props-container">
          <h2>Why Use Our Financial Tools?</h2>
          <div className="props-grid">
            <div className="prop-card">
              <div className="prop-icon">🔍</div>
              <h3>Data-Driven Decisions</h3>
              <p>Our calculators use real-world financial models to give accurate projections</p>
            </div>
            <div className="prop-card">
              <div className="prop-icon">💡</div>
              <h3>India-Specific</h3>
              <p>Tailored for Indian tax laws, interest rates and market conditions</p>
            </div>
            <div className="prop-card">
              <div className="prop-icon">🎯</div>
              <h3>Actionable Insights</h3>
              <p>Get clear recommendations, not just numbers</p>
            </div>
          </div>
        </div>
      </section>

      <footer className="finance-footer">
        <div className="footer-content">
          <div className="footer-main">
            <div className="footer-brand">
              <h3>ShoppingDeals Finance</h3>
              <p>Empowering your financial journey</p>
            </div>
            {/* <div className="footer-links">
              <a href="#">Privacy Policy</a>
              <a href="#">Terms of Service</a>
              <a href="#">Contact Us</a>
            </div> */}
          </div>
          <div className="footer-copyright">
            © {new Date().getFullYear()} ShoppingDeals.in. All calculators are for educational purposes only.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FinanceHomepage;