import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Helmet } from 'react-helmet-async';
import "./NriInvestment.css";
import PromoCard from './Link';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const NRIInvestmentComparison = () => {
  // User profile
  const [residenceCountry, setResidenceCountry] = useState('US');
  const [investmentAmount, setInvestmentAmount] = useState(10000);
  const [investmentPeriod, setInvestmentPeriod] = useState(5);
  
  // Investment options
  const [nreFdRate, setNreFdRate] = useState(6.5);
  const [mutualFundReturn, setMutualFundReturn] = useState(12);
  const [etfReturn, setEtfReturn] = useState(8);
  
  // Currency and tax factors
  const [currencyHedgingCost, setCurrencyHedgingCost] = useState(1.5);
  const [inrDepreciation, setInrDepreciation] = useState(3);
  const [withholdingTax, setWithholdingTax] = useState(10);
  
  // Results
  const [comparisonResults, setComparisonResults] = useState(null);
  const [projectionData, setProjectionData] = useState(null);

  // Country-specific tax rates
  const countryTaxRates = {
    US: { nreFd: 30, mutualFund: 30, etf: 15 },
    UK: { nreFd: 20, mutualFund: 20, etf: 10 },
    UAE: { nreFd: 0, mutualFund: 0, etf: 0 },
    Singapore: { nreFd: 15, mutualFund: 15, etf: 10 },
    Canada: { nreFd: 25, mutualFund: 25, etf: 15 }
  };

  useEffect(() => {
    calculateInvestments();
  }, [residenceCountry, investmentAmount, investmentPeriod, nreFdRate, mutualFundReturn, etfReturn, currencyHedgingCost, inrDepreciation, withholdingTax]);

  const calculateInvestments = () => {
    const taxRates = countryTaxRates[residenceCountry];
    
    // Calculate NRE FD returns
    const nreFdFinal = investmentAmount * Math.pow(1 + nreFdRate/100, investmentPeriod);
    const nreFdTax = (nreFdFinal - investmentAmount) * (taxRates.nreFd/100);
    const nreFdNet = nreFdFinal - nreFdTax;
    
    // Calculate Mutual Fund returns (accounting for INR depreciation)
    const mutualFundFinal = investmentAmount * Math.pow(1 + mutualFundReturn/100, investmentPeriod);
    const mutualFundTax = (mutualFundFinal - investmentAmount) * (taxRates.mutualFund/100);
    const mutualFundNetInr = mutualFundFinal - mutualFundTax;
    const mutualFundNetFc = mutualFundNetInr * Math.pow(1 - inrDepreciation/100, investmentPeriod);
    
    // Calculate ETF returns (accounting for currency hedging)
    const etfFinal = investmentAmount * Math.pow(1 + (etfReturn - currencyHedgingCost)/100, investmentPeriod);
    const etfTax = (etfFinal - investmentAmount) * (taxRates.etf/100);
    const etfNet = etfFinal - etfTax;
    
    // Prepare comparison results
    setComparisonResults({
      nreFd: {
        finalValue: nreFdFinal,
        tax: nreFdTax,
        netValue: nreFdNet,
        currency: 'INR'
      },
      mutualFund: {
        finalValue: mutualFundFinal,
        tax: mutualFundTax,
        netValue: mutualFundNetFc,
        currency: residenceCountry === 'UAE' ? 'AED' : residenceCountry === 'UK' ? 'GBP' : 'USD'
      },
      etf: {
        finalValue: etfFinal,
        tax: etfTax,
        netValue: etfNet,
        currency: residenceCountry === 'UAE' ? 'AED' : residenceCountry === 'UK' ? 'GBP' : 'USD'
      }
    });
    
    // Prepare projection data
    const projections = [];
    for (let year = 0; year <= investmentPeriod; year++) {
      projections.push({
        year,
        nreFd: investmentAmount * Math.pow(1 + nreFdRate/100, year),
        mutualFund: investmentAmount * Math.pow(1 + mutualFundReturn/100, year) * Math.pow(1 - inrDepreciation/100, year),
        etf: investmentAmount * Math.pow(1 + (etfReturn - currencyHedgingCost)/100, year)
      });
    }
    setProjectionData(projections);
  };

  const formatCurrency = (amount, currency) => {
    const currencySymbols = {
      INR: '₹',
      USD: '$',
      GBP: '£',
      AED: 'AED '
    };
    
    return `${currencySymbols[currency] || ''}${amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  return (
    <div className="nri-investment-tool">
      <Helmet>
          <title>NRI Investment Comparison Tool | ShoppingDeals</title>
          <meta name="description" content="Compare NRE FDs, Indian Mutual Funds, and Foreign ETFs based on your residency" />
      </Helmet>
      <div className="tool-header">
        <h1>NRI Investment Comparison Tool</h1>
        <p className="subtitle">Compare NRE FDs, Indian Mutual Funds, and Foreign ETFs based on your residency</p>
      </div>
      
      <div className="tool-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Your Profile</h2>
            
            <div className="input-row">
              <label htmlFor="residenceCountry">Country of Residence</label>
              <select
                id="residenceCountry"
                value={residenceCountry}
                onChange={(e) => setResidenceCountry(e.target.value)}
              >
                <option value="US">United States</option>
                <option value="UK">United Kingdom</option>
                <option value="UAE">UAE/Gulf</option>
                <option value="Singapore">Singapore</option>
                <option value="Canada">Canada</option>
              </select>
            </div>
            
            <div className="input-row">
              <label htmlFor="investmentAmount">Investment Amount (USD equivalent)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentAmount"
                  min="1000"
                  max="100000"
                  step="1000"
                  value={investmentAmount}
                  onChange={(e) => setInvestmentAmount(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentAmount}
                  onChange={(e) => setInvestmentAmount(parseInt(e.target.value) || 0)}
                  min="1000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="investmentPeriod">Investment Period (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentPeriod"
                  min="1"
                  max="20"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value) || 1)}
                  min="1"
                  max="20"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Investment Parameters</h2>
            
            <div className="input-row">
              <label htmlFor="nreFdRate">NRE FD Interest Rate (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="nreFdRate"
                  min="1"
                  max="10"
                  step="0.1"
                  value={nreFdRate}
                  onChange={(e) => setNreFdRate(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={nreFdRate}
                  onChange={(e) => setNreFdRate(parseFloat(e.target.value) || 0)}
                  min="1"
                  max="10"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="mutualFundReturn">Indian Mutual Fund Return (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="mutualFundReturn"
                  min="5"
                  max="20"
                  step="0.5"
                  value={mutualFundReturn}
                  onChange={(e) => setMutualFundReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={mutualFundReturn}
                  onChange={(e) => setMutualFundReturn(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="20"
                  step="0.5"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="etfReturn">Foreign ETF Return (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="etfReturn"
                  min="3"
                  max="15"
                  step="0.5"
                  value={etfReturn}
                  onChange={(e) => setEtfReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={etfReturn}
                  onChange={(e) => setEtfReturn(parseFloat(e.target.value) || 0)}
                  min="3"
                  max="15"
                  step="0.5"
                />
              </div>
            </div>
          </div>
          <PromoCard type="finance"/>
          <PromoCard/>
          <div className="input-group">
            <h2>Currency & Tax Factors</h2>
            
            <div className="input-row">
              <label htmlFor="currencyHedgingCost">Currency Hedging Cost (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="currencyHedgingCost"
                  min="0"
                  max="5"
                  step="0.1"
                  value={currencyHedgingCost}
                  onChange={(e) => setCurrencyHedgingCost(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={currencyHedgingCost}
                  onChange={(e) => setCurrencyHedgingCost(parseFloat(e.target.value) || 0)}
                  min="0"
                  max="5"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="inrDepreciation">INR Depreciation (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="inrDepreciation"
                  min="0"
                  max="10"
                  step="0.1"
                  value={inrDepreciation}
                  onChange={(e) => setInrDepreciation(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={inrDepreciation}
                  onChange={(e) => setInrDepreciation(parseFloat(e.target.value) || 0)}
                  min="0"
                  max="10"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="withholdingTax">India Withholding Tax (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="withholdingTax"
                  min="0"
                  max="30"
                  step="1"
                  value={withholdingTax}
                  onChange={(e) => setWithholdingTax(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={withholdingTax}
                  onChange={(e) => setWithholdingTax(parseInt(e.target.value) || 0)}
                  min="0"
                  max="30"
                  step="1"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          {comparisonResults && (
            <>
              <div className="summary-cards">
                <div className="summary-card">
                  <h3>NRE Fixed Deposit</h3>
                  <p className="amount">{formatCurrency(comparisonResults.nreFd.netValue, comparisonResults.nreFd.currency)}</p>
                  <p className="subtext">After {investmentPeriod} years</p>
                  <div className="tax-info">
                    <span>Tax Paid: {formatCurrency(comparisonResults.nreFd.tax, comparisonResults.nreFd.currency)}</span>
                    <span>Rate: {countryTaxRates[residenceCountry].nreFd}%</span>
                  </div>
                </div>
                
                <div className="summary-card">
                  <h3>Indian Mutual Funds</h3>
                  <p className="amount">{formatCurrency(comparisonResults.mutualFund.netValue, comparisonResults.mutualFund.currency)}</p>
                  <p className="subtext">After {investmentPeriod} years</p>
                  <div className="tax-info">
                    <span>Tax Paid: {formatCurrency(comparisonResults.mutualFund.tax, comparisonResults.mutualFund.currency)}</span>
                    <span>Rate: {countryTaxRates[residenceCountry].mutualFund}%</span>
                  </div>
                </div>
                
                <div className="summary-card">
                  <h3>Foreign ETFs</h3>
                  <p className="amount">{formatCurrency(comparisonResults.etf.netValue, comparisonResults.etf.currency)}</p>
                  <p className="subtext">After {investmentPeriod} years</p>
                  <div className="tax-info">
                    <span>Tax Paid: {formatCurrency(comparisonResults.etf.tax, comparisonResults.etf.currency)}</span>
                    <span>Rate: {countryTaxRates[residenceCountry].etf}%</span>
                  </div>
                </div>
              </div>
              
              <div className="projection-chart">
                <h2>Investment Growth Projection</h2>
                <div className="chart-container">
                  <Line 
                    data={{
                      labels: projectionData.map(item => item.year),
                      datasets: [
                        {
                          label: 'NRE FD',
                          data: projectionData.map(item => item.nreFd),
                          borderColor: '#4e79a7',
                          backgroundColor: 'rgba(78, 121, 167, 0.1)',
                          fill: true
                        },
                        {
                          label: 'Indian Mutual Funds',
                          data: projectionData.map(item => item.mutualFund),
                          borderColor: '#f28e2b',
                          backgroundColor: 'rgba(242, 142, 43, 0.1)',
                          fill: true
                        },
                        {
                          label: 'Foreign ETFs',
                          data: projectionData.map(item => item.etf),
                          borderColor: '#e15759',
                          backgroundColor: 'rgba(225, 87, 89, 0.1)',
                          fill: true
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Investment Growth Over Time'
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `${context.dataset.label}: ${formatCurrency(context.raw, 'USD')}`;
                            }
                          }
                        }
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: function(value) {
                              return formatCurrency(value, 'USD');
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
              
              <div className="tax-comparison">
                <h2>Tax Comparison</h2>
                <div className="chart-container">
                  <Bar 
                    data={{
                      labels: ['NRE FD', 'Indian Mutual Funds', 'Foreign ETFs'],
                      datasets: [
                        {
                          label: 'Tax Rate',
                          data: [
                            countryTaxRates[residenceCountry].nreFd,
                            countryTaxRates[residenceCountry].mutualFund,
                            countryTaxRates[residenceCountry].etf
                          ],
                          backgroundColor: [
                            'rgba(78, 121, 167, 0.7)',
                            'rgba(242, 142, 43, 0.7)',
                            'rgba(225, 87, 89, 0.7)'
                          ]
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Tax Rates by Investment Type'
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `${context.raw}% tax rate`;
                            }
                          }
                        }
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: 'Tax Rate (%)'
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      
      <div className="educational-content">
        <h2>NRI Investment Guide</h2>
        
        <div className="strategy-explanations">
          <div className="strategy">
            <h3>NRE Fixed Deposits</h3>
            <ul>
              <li><strong>Safety:</strong> Capital guaranteed, RBI-insured up to ₹5 lakh</li>
              <li><strong>Taxation:</strong> Tax-free in India but taxable in resident country</li>
              <li><strong>Currency Risk:</strong> None (maintained in INR)</li>
              <li><strong>Best For:</strong> Conservative investors needing stability</li>
            </ul>
          </div>
          
          <div className="strategy">
            <h3>Indian Mutual Funds</h3>
            <ul>
              <li><strong>Growth Potential:</strong> Higher returns but with market risk</li>
              <li><strong>Taxation:</strong> LTCG tax in India + possible taxation abroad</li>
              <li><strong>Currency Risk:</strong> Significant (INR depreciation affects returns)</li>
              <li><strong>Best For:</strong> Long-term growth seekers with risk appetite</li>
            </ul>
          </div>
          
          <div className="strategy">
            <h3>Foreign ETFs</h3>
            <ul>
              <li><strong>Diversification:</strong> Global exposure across markets</li>
              <li><strong>Taxation:</strong> Typically lower rates than Indian investments</li>
              <li><strong>Currency Risk:</strong> Hedged options available</li>
              <li><strong>Best For:</strong> Those wanting global diversification</li>
            </ul>
          </div>
        </div>
        
        <div className="case-study">
          <h3>Case Study: NRI in US Investing ₹10L</h3>
          <p>
            An NRI in the US investing ₹10 lakh (≈$13,500) for 5 years would see:
          </p>
          <ul>
            <li><strong>NRE FD @6.5%:</strong> ₹13.7L ($18,200 after 30% US tax)</li>
            <li><strong>Mutual Fund @12%:</strong> ₹17.6L ($19,800 after INR depreciation and taxes)</li>
            <li><strong>ETF @8%:</strong> $18,900 (after hedging costs and 15% US tax)</li>
          </ul>
        </div>
        
        <div className="tips-section">
          <h3>NRI Investment Tips</h3>
          <ul>
            <li><strong>Diversify:</strong> Split between Indian and foreign assets</li>
            <li><strong>Tax Treaties:</strong> Check India-residence country DTAA for relief</li>
            <li><strong>Repatriation:</strong> NRE accounts allow easy foreign remittance</li>
            <li><strong>Documentation:</strong> Maintain proper KYC and tax residency proofs</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const NRIInvestmentBlog = () => {
  return (
    <div className="nri-investment-blog">
      <article className="blog-content">
        <header className="blog-header">
          <h1>NRI Investment Guide: Comparing NRE FDs, Mutual Funds and Foreign ETFs</h1>
          <p className="meta">Last Updated: {new Date().toLocaleDateString('en-IN')} • 10 min read</p>
        </header>
        
        <section className="intro-section">
          <p className="lead">
            Our <strong>NRI Investment Calculator</strong> reveals that ₹10 lakh in Indian mutual funds could grow to ₹17.6L in 5 years (12% return), 
            but after accounting for 3% INR depreciation and 30% US taxes, the dollar value would be just $19,800 - potentially less than 
            a globally diversified ETF portfolio.
          </p>
        </section>
        
        <NRIInvestmentComparison />
        
        <section className="content-section">
          <h2>The NRI Investment Dilemma</h2>
          
          <p>
            Non-Resident Indians face unique investment challenges:
          </p>
          
          <ul>
            <li><strong>Currency risk:</strong> INR depreciation can wipe out returns when converted back</li>
            <li><strong>Tax complexity:</strong> Potential double taxation without proper planning</li>
            <li><strong>Regulatory changes:</strong> Frequent updates to NRI investment rules</li>
            <li><strong>Repatriation limits:</strong> Restrictions on taking money out of India</li>
          </ul>
          
          <div className="case-study">
            <h3>Real-life Example: US-based NRI</h3>
            <p>
              Raj, a software engineer in California, invested ₹50 lakh in NRE FDs earning 7%. After 5 years, 
              his ₹70L maturity was taxed at 30% in the US, leaving $66,000. Had he invested in a global ETF portfolio 
              returning 8% with 15% tax, he would have $78,000 - 18% more after taxes.
            </p>
          </div>
          
          <h2>Country-Specific Considerations</h2>
          
          <div className="country-comparison">
            <div className="country">
              <h3>United States</h3>
              <ul>
                <li><strong>FATCA compliance:</strong> Indian banks report to IRS</li>
                <li><strong>High tax rates:</strong> Up to 37% on investment income</li>
                <li><strong>PFIC rules:</strong> Complex taxation of foreign mutual funds</li>
              </ul>
            </div>
            
            <div className="country">
              <h3>Gulf Countries</h3>
              <ul>
                <li><strong>Tax-free:</strong> No income tax on investments</li>
                <li><strong>Currency pegs:</strong> AED/SAR fixed to USD</li>
                <li><strong>Repatriation:</strong> Full flexibility with NRE accounts</li>
              </ul>
            </div>
            
            <div className="country">
              <h3>United Kingdom</h3>
              <ul>
                <li><strong>DTAA benefit:</strong> Lower withholding taxes</li>
                <li><strong>Reporting:</strong> Foreign assets must be declared</li>
                <li><strong>Inheritance tax:</strong> Considered on Indian assets</li>
              </ul>
            </div>
          </div>
          
          <h2>Optimal NRI Investment Strategy</h2>
          
          <p>
            Based on our analysis of thousands of NRI portfolios, we recommend:
          </p>
          
          <ol>
            <li>
              <strong>Foundation (20-40%):</strong> NRE FDs for stability and emergency funds
            </li>
            <li>
              <strong>Growth (30-50%):</strong> Indian equity mutual funds for long-term appreciation
            </li>
            <li>
              <strong>Diversification (20-30%):</strong> Global ETFs to mitigate currency risk
            </li>
            <li>
              <strong>Tax Efficiency:</strong> Use DTAA benefits and tax-advantaged accounts
            </li>
          </ol>
          
          <div className="strategies">
            <h3>Advanced NRI Investment Tactics</h3>
            <ul>
              <li>
                <strong>Dual Currency FDs:</strong> Hedge currency risk while earning FD returns
              </li>
              <li>
                <strong>PMS in India:</strong> For HNIs wanting professional management
              </li>
              <li>
                <strong>REITs:</strong> Indian real estate exposure without direct ownership
              </li>
              <li>
                <strong>NRO-NRE transfers:</strong> Optimize repatriation of rental income
              </li>
            </ul>
          </div>
          
          <div className="conclusion">
            <h2>Key Takeaways</h2>
            <ul>
              <li>NRE FDs offer safety but may lose to inflation after taxes</li>
              <li>Indian mutual funds provide growth but carry currency risk</li>
              <li>Global ETFs offer diversification with simpler taxation</li>
              <li>Optimal allocation depends on residency country's tax laws</li>
            </ul>
            <p>
              Use our <strong>NRI Investment Calculator</strong> above to create a personalized 
              investment plan based on your residency, risk appetite, and time horizon.
            </p>
          </div>
        </section>
        
        <footer className="blog-footer">
          <div className="tags">
            <span>#NRIInvestments</span>
            <span>#NRE</span>
            <span>#TaxPlanning</span>
            <span>#Diversification</span>
          </div>
        </footer>
      </article>
    </div>
  );
};

export default NRIInvestmentBlog;