import GetItemById from '../Apis/GetItemById';
import React, { useEffect} from 'react'
import { useParams} from 'react-router-dom'
import ItemDetailsCard from "../Cards/ItemDetailsCard";
import "../Cards/card-style.css";
import { Helmet } from 'react-helmet-async';

const ItemDetails = props => {
    const { id } = useParams();
    let { error, item} = GetItemById(id);
    useEffect(() => {
    //     setId(id);
    
    }, [id]);

    return (
        (item != null)?
        <div>
            <Helmet>
                    <title>{item.description}</title>
                    <meta name="description" content="Best Offers for {item.description}" />
            </Helmet>
            <div className="home-page">
                <ItemDetailsCard item={item} />
            </div>
        </div> : <div>{error}</div>
    )
   
}

export default ItemDetails
