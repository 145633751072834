import React, { useState, useEffect } from 'react';
import './SipVsLumpSumBlog.css';


const SipVsLumpSumCalculator = () => {
  // Investment Parameters
  const [investmentAmount, setInvestmentAmount] = useState(1000000);
  const [sipAmount, setSipAmount] = useState(10000);
  const [investmentPeriod, setInvestmentPeriod] = useState(10);
  const [expectedReturn, setExpectedReturn] = useState(12);
  const [volatility, setVolatility] = useState(15);
  
  // Tax Parameters
  const [taxRegime, setTaxRegime] = useState('old');
  const [ltcgTax, setLtcgTax] = useState(10);
  
  // Results
  const [lumpSumValue, setLumpSumValue] = useState(0);
  const [sipValue, setSipValue] = useState(0);
  const [taxOnLumpSum, setTaxOnLumpSum] = useState(0);
  const [taxOnSip, setTaxOnSip] = useState(0);
  const [netLumpSum, setNetLumpSum] = useState(0);
  const [netSip, setNetSip] = useState(0);
  const [yearlyData, setYearlyData] = useState([]);

  // Calculate investment returns with volatility
  const calculateReturns = () => {
    const monthlyReturn = expectedReturn / 12 / 100;
    const monthlyVolatility = volatility / Math.sqrt(12) / 100;
    
    // Lump sum calculation with volatility
    let lumpSum = investmentAmount;
    let sipTotal = 0;
    const yearlyResults = [];
    
    for (let year = 1; year <= investmentPeriod; year++) {
      // Simulate monthly returns with volatility
      let yearEndLumpSum = lumpSum;
      let yearEndSip = sipTotal;
      
      for (let month = 1; month <= 12; month++) {
        // Random return within volatility range
        const randomFactor = 1 + (monthlyReturn + (Math.random() - 0.5) * monthlyVolatility * 2);
        yearEndLumpSum *= randomFactor;
        
        // SIP calculation
        if (month === 1) {
          yearEndSip = (yearEndSip + sipAmount) * randomFactor;
        } else {
          yearEndSip = (yearEndSip + sipAmount) * randomFactor;
        }
      }
      
      lumpSum = yearEndLumpSum;
      sipTotal = yearEndSip;
      
      yearlyResults.push({
        year,
        lumpSum,
        sipTotal,
        sipInvested: sipAmount * 12 * year
      });
    }
    
    // Calculate taxes
    const calculateTax = (gain, regime) => {
      if (regime === 'new') return 0;
      const taxableGain = Math.max(0, gain - 100000); // LTCG exemption
      return taxableGain * ltcgTax / 100;
    };
    
    const lumpSumGain = lumpSum - investmentAmount;
    const sipGain = sipTotal - (sipAmount * 12 * investmentPeriod);
    
    const lumpSumTax = calculateTax(lumpSumGain, taxRegime);
    const sipTax = calculateTax(sipGain, taxRegime);
    
    setLumpSumValue(lumpSum);
    setSipValue(sipTotal);
    setTaxOnLumpSum(lumpSumTax);
    setTaxOnSip(sipTax);
    setNetLumpSum(lumpSum - lumpSumTax);
    setNetSip(sipTotal - sipTax);
    setYearlyData(yearlyResults);
  };

  useEffect(() => {
    calculateReturns();
  }, [
    investmentAmount, sipAmount, investmentPeriod,
    expectedReturn, volatility, taxRegime, ltcgTax
  ]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const formatPercent = (value) => {
    return value.toFixed(1) + '%';
  };

  return (
    <div className="sip-calculator">
      <div className="calculator-header">
        <h1>SIP vs Lump Sum Investment Calculator</h1>
        <p className="subtitle">Compare systematic and one-time investment strategies with market volatility</p>
      </div>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Investment Parameters</h2>
            
            <div className="input-row">
              <label htmlFor="investmentAmount">Lump Sum Amount (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentAmount"
                  min="100000"
                  max="10000000"
                  step="10000"
                  value={investmentAmount}
                  onChange={(e) => setInvestmentAmount(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentAmount}
                  onChange={(e) => setInvestmentAmount(parseInt(e.target.value) || 0)}
                  min="100000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="sipAmount">Monthly SIP Amount (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="sipAmount"
                  min="1000"
                  max="100000"
                  step="1000"
                  value={sipAmount}
                  onChange={(e) => setSipAmount(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={sipAmount}
                  onChange={(e) => setSipAmount(parseInt(e.target.value) || 0)}
                  min="1000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="investmentPeriod">Investment Period (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentPeriod"
                  min="1"
                  max="30"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value) || 0)}
                  min="1"
                  max="30"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="expectedReturn">Expected Return (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="expectedReturn"
                  min="5"
                  max="20"
                  step="0.5"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="20"
                  step="0.5"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="volatility">Market Volatility (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="volatility"
                  min="5"
                  max="40"
                  step="1"
                  value={volatility}
                  onChange={(e) => setVolatility(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={volatility}
                  onChange={(e) => setVolatility(parseInt(e.target.value) || 0)}
                  min="5"
                  max="40"
                  step="1"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Tax Parameters</h2>
            
            <div className="input-row">
              <label>Tax Regime</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="taxRegime"
                    value="old"
                    checked={taxRegime === 'old'}
                    onChange={() => setTaxRegime('old')}
                  />
                  Old (with LTCG)
                </label>
                <label>
                  <input
                    type="radio"
                    name="taxRegime"
                    value="new"
                    checked={taxRegime === 'new'}
                    onChange={() => setTaxRegime('new')}
                  />
                  New (no LTCG)
                </label>
              </div>
            </div>
            
            {taxRegime === 'old' && (
              <div className="input-row">
                <label htmlFor="ltcgTax">LTCG Tax Rate (%)</label>
                <div className="range-container">
                  <input
                    type="range"
                    id="ltcgTax"
                    min="0"
                    max="20"
                    step="1"
                    value={ltcgTax}
                    onChange={(e) => setLtcgTax(parseInt(e.target.value))}
                  />
                  <input
                    type="number"
                    value={ltcgTax}
                    onChange={(e) => setLtcgTax(parseInt(e.target.value) || 0)}
                    min="0"
                    max="20"
                    step="1"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        
        <div className="results-section">
          <div className="comparison-summary">
            <h2>Investment Comparison</h2>
            <div className="summary-cards">
              <div className="summary-card lump-sum">
                <h3>Lump Sum Investment</h3>
                <div className="result-row">
                  <span>Final Value:</span>
                  <span>{formatCurrency(lumpSumValue)}</span>
                </div>
                <div className="result-row">
                  <span>Tax Payable:</span>
                  <span>{formatCurrency(taxOnLumpSum)}</span>
                </div>
                <div className="result-row total">
                  <span>Net Value:</span>
                  <span>{formatCurrency(netLumpSum)}</span>
                </div>
              </div>
              
              <div className="summary-card sip">
                <h3>SIP Investment</h3>
                <div className="result-row">
                  <span>Final Value:</span>
                  <span>{formatCurrency(sipValue)}</span>
                </div>
                <div className="result-row">
                  <span>Tax Payable:</span>
                  <span>{formatCurrency(taxOnSip)}</span>
                </div>
                <div className="result-row total">
                  <span>Net Value:</span>
                  <span>{formatCurrency(netSip)}</span>
                </div>
              </div>
            </div>
            
            <div className="difference-card">
              <h3>Difference After {investmentPeriod} Years</h3>
              <p className="difference-amount">
                {netLumpSum > netSip ? (
                  <>Lump Sum is <strong>{formatCurrency(netLumpSum - netSip)}</strong> higher</>
                ) : (
                  <>SIP is <strong>{formatCurrency(netSip - netLumpSum)}</strong> higher</>
                )}
              </p>
            </div>
          </div>
          
          <div className="growth-chart">
            <h2>Value Growth Over Time</h2>
            <div className="chart-placeholder">
              {/* In a real implementation, you would use Chart.js or similar here */}
              <p>Growth chart showing SIP vs Lump Sum performance</p>
              <div className="chart-legend">
                <div className="legend-item">
                  <span className="color-box lump-sum"></span>
                  <span>Lump Sum Investment</span>
                </div>
                <div className="legend-item">
                  <span className="color-box sip"></span>
                  <span>SIP Investment</span>
                </div>
              </div>
            </div>
          </div>
          
          <div className="yearly-breakdown">
            <h2>Yearly Breakdown</h2>
            <div className="breakdown-table">
              <div className="table-header">
                <div>Year</div>
                <div>Lump Sum</div>
                <div>SIP Value</div>
                <div>SIP Invested</div>
              </div>
              {yearlyData.map((data) => (
                <div className="table-row" key={data.year}>
                  <div>{data.year}</div>
                  <div>{formatCurrency(data.lumpSum)}</div>
                  <div>{formatCurrency(data.sipTotal)}</div>
                  <div>{formatCurrency(data.sipInvested)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SipVsLumpSumCalculator;