import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Helmet } from 'react-helmet-async';
import "./MarriageCost.css";
import PromoCard from './Link';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MarriageCostPlanner = () => {
  // Wedding parameters
  const [cityTier, setCityTier] = useState('metro');
  const [weddingBudget, setWeddingBudget] = useState(2000000);
  const [loanPercentage, setLoanPercentage] = useState(0);
  const [loanTenure, setLoanTenure] = useState(5);
  const [loanInterest, setLoanInterest] = useState(12);
  
  // Investment comparison
  const [investmentReturn, setInvestmentReturn] = useState(12);
  const [investmentPeriod, setInvestmentPeriod] = useState(20);
  
  // Results
  const [costBreakdown, setCostBreakdown] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [investmentProjection, setInvestmentProjection] = useState(null);

  // City-wise cost breakdowns
  const cityCosts = {
    metro: {
      name: 'Metro (Mumbai/Delhi)',
      breakdown: {
        venue: 35,
        catering: 25,
        photography: 10,
        attire: 12,
        jewelry: 15,
        miscellaneous: 3
      },
      multiplier: 1.5
    },
    tier1: {
      name: 'Tier 1 (Bangalore/Hyderabad)',
      breakdown: {
        venue: 30,
        catering: 25,
        photography: 8,
        attire: 15,
        jewelry: 18,
        miscellaneous: 4
      },
      multiplier: 1.2
    },
    tier2: {
      name: 'Tier 2 (Jaipur/Lucknow)',
      breakdown: {
        venue: 25,
        catering: 30,
        photography: 5,
        attire: 18,
        jewelry: 20,
        miscellaneous: 2
      },
      multiplier: 1
    },
    tier3: {
      name: 'Tier 3 (Smaller Cities)',
      breakdown: {
        venue: 20,
        catering: 35,
        photography: 3,
        attire: 20,
        jewelry: 20,
        miscellaneous: 2
      },
      multiplier: 0.7
    }
  };

  useEffect(() => {
    calculateWeddingCosts();
    calculateInvestmentComparison();
  }, [cityTier, weddingBudget, loanPercentage, loanTenure, loanInterest, investmentReturn, investmentPeriod]);

  const calculateWeddingCosts = () => {
    const city = cityCosts[cityTier];
    const baseBudget = weddingBudget * city.multiplier;
    
    // Calculate cost breakdown
    const breakdown = Object.entries(city.breakdown).map(([category, percent]) => ({
      category,
      amount: (baseBudget * percent) / 100,
      percent
    }));
    
    setCostBreakdown({
      total: baseBudget,
      breakdown,
      cityName: city.name
    });
    
    // Calculate loan details if applicable
    if (loanPercentage > 0) {
      const loanAmount = (baseBudget * loanPercentage) / 100;
      const monthlyRate = loanInterest / 12 / 100;
      const months = loanTenure * 12;
      const emi = loanAmount * monthlyRate * Math.pow(1 + monthlyRate, months) / 
                 (Math.pow(1 + monthlyRate, months) - 1);
      
      setLoanDetails({
        amount: loanAmount,
        emi,
        totalPayment: emi * months,
        totalInterest: (emi * months) - loanAmount
      });
    } else {
      setLoanDetails(null);
    }
  };

  const calculateInvestmentComparison = () => {
    const projections = [];
    for (let year = 0; year <= investmentPeriod; year++) {
      projections.push({
        year,
        value: weddingBudget * Math.pow(1 + investmentReturn/100, year)
      });
    }
    setInvestmentProjection(projections);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="marriage-planner">
      <Helmet>
          <title>Marriage Cost Planner | ShoppingDeals</title>
          <meta name="description" content="Marriage Cost Planner (India)" />
      </Helmet>
      <div className="planner-header">
        <h1>Marriage Cost Planner (India)</h1>
        <p className="subtitle">Plan your dream wedding and see what that money could become if invested instead</p>
      </div>
      
      <div className="planner-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Wedding Details</h2>
            
            <div className="input-row">
              <label htmlFor="cityTier">City Tier</label>
              <select
                id="cityTier"
                value={cityTier}
                onChange={(e) => setCityTier(e.target.value)}
              >
                <option value="metro">Metro (Mumbai/Delhi)</option>
                <option value="tier1">Tier 1 (Bangalore/Hyderabad)</option>
                <option value="tier2">Tier 2 (Jaipur/Lucknow)</option>
                <option value="tier3">Tier 3 (Smaller Cities)</option>
              </select>
            </div>
            
            <div className="input-row">
              <label htmlFor="weddingBudget">Wedding Budget (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="weddingBudget"
                  min="500000"
                  max="10000000"
                  step="100000"
                  value={weddingBudget}
                  onChange={(e) => setWeddingBudget(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={weddingBudget}
                  onChange={(e) => setWeddingBudget(parseInt(e.target.value) || 0)}
                  min="500000"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Wedding Loan</h2>
            
            <div className="input-row">
              <label htmlFor="loanPercentage">Loan Percentage</label>
              <div className="range-container">
                <input
                  type="range"
                  id="loanPercentage"
                  min="0"
                  max="100"
                  value={loanPercentage}
                  onChange={(e) => setLoanPercentage(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={loanPercentage}
                  onChange={(e) => setLoanPercentage(parseInt(e.target.value) || 0)}
                  min="0"
                  max="100"
                />
              </div>
            </div>
            
            {loanPercentage > 0 && (
              <>
                <div className="input-row">
                  <label htmlFor="loanTenure">Loan Tenure (Years)</label>
                  <div className="range-container">
                    <input
                      type="range"
                      id="loanTenure"
                      min="1"
                      max="10"
                      value={loanTenure}
                      onChange={(e) => setLoanTenure(parseInt(e.target.value))}
                    />
                    <input
                      type="number"
                      value={loanTenure}
                      onChange={(e) => setLoanTenure(parseInt(e.target.value) || 1)}
                      min="1"
                      max="10"
                    />
                  </div>
                </div>
                
                <div className="input-row">
                  <label htmlFor="loanInterest">Loan Interest Rate (% p.a.)</label>
                  <div className="range-container">
                    <input
                      type="range"
                      id="loanInterest"
                      min="8"
                      max="20"
                      step="0.5"
                      value={loanInterest}
                      onChange={(e) => setLoanInterest(parseFloat(e.target.value))}
                    />
                    <input
                      type="number"
                      value={loanInterest}
                      onChange={(e) => setLoanInterest(parseFloat(e.target.value) || 8)}
                      min="8"
                      max="20"
                      step="0.5"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          
          <div className="input-group">
            <h2>Investment Comparison</h2>
            
            <div className="input-row">
              <label htmlFor="investmentReturn">Expected Return (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentReturn"
                  min="5"
                  max="20"
                  step="0.5"
                  value={investmentReturn}
                  onChange={(e) => setInvestmentReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentReturn}
                  onChange={(e) => setInvestmentReturn(parseFloat(e.target.value) || 5)}
                  min="5"
                  max="20"
                  step="0.5"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="investmentPeriod">Investment Period (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="investmentPeriod"
                  min="5"
                  max="30"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={investmentPeriod}
                  onChange={(e) => setInvestmentPeriod(parseInt(e.target.value) || 5)}
                  min="5"
                  max="30"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          {costBreakdown && (
            <>
              <div className="summary-cards">
                <div className="summary-card">
                  <h3>Total Wedding Cost</h3>
                  <p className="amount">{formatCurrency(costBreakdown.total)}</p>
                  <p className="subtext">{costBreakdown.cityName}</p>
                </div>
                
                {loanDetails && (
                  <div className="summary-card">
                    <h3>Loan EMI</h3>
                    <p className="amount">{formatCurrency(loanDetails.emi)}</p>
                    <p className="subtext">For {loanTenure} years</p>
                    <div className="loan-info">
                      <span>Total Interest: {formatCurrency(loanDetails.totalInterest)}</span>
                    </div>
                  </div>
                )}
                
                <div className="summary-card highlight">
                  <h3>Potential Investment Value</h3>
                  <p className="amount">{formatCurrency(investmentProjection?.[investmentPeriod]?.value || 0)}</p>
                  <p className="subtext">After {investmentPeriod} years</p>
                  <div className="return-info">
                    <span>{investmentReturn}% annual return</span>
                  </div>
                </div>
              </div>
              
              <div className="cost-breakdown">
                <h2>Wedding Cost Breakdown</h2>
                <div className="chart-container">
                  <Bar 
                    data={{
                      labels: costBreakdown.breakdown.map(item => item.category),
                      datasets: [{
                        label: 'Amount (₹)',
                        data: costBreakdown.breakdown.map(item => item.amount),
                        backgroundColor: [
                          '#4e79a7',
                          '#f28e2b',
                          '#e15759',
                          '#76b7b2',
                          '#59a14f',
                          '#edc948'
                        ]
                      }]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Where Your Wedding Budget Goes'
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `${context.dataset.label}: ${formatCurrency(context.raw)} (${costBreakdown.breakdown[context.dataIndex].percent}%)`;
                            }
                          }
                        }
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: function(value) {
                              return formatCurrency(value);
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
              
              <div className="investment-comparison">
                <h2>What If You Invested Instead?</h2>
                <div className="chart-container">
                  <Line 
                    data={{
                      labels: investmentProjection.map(item => item.year),
                      datasets: [{
                        label: 'Investment Value',
                        data: investmentProjection.map(item => item.value),
                        borderColor: '#59a14f',
                        backgroundColor: 'rgba(89, 161, 79, 0.1)',
                        fill: true,
                        tension: 0.1
                      }]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Potential Growth If Invested'
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
                            }
                          }
                        }
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: function(value) {
                              return formatCurrency(value);
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
              
              {loanDetails && (
                <div className="loan-impact">
                  <h2>Loan Impact Analysis</h2>
                  <div className="chart-container">
                    <Bar 
                      data={{
                        labels: ['Total Loan', 'Principal', 'Interest'],
                        datasets: [{
                          label: 'Amount (₹)',
                          data: [
                            loanDetails.totalPayment,
                            loanDetails.amount,
                            loanDetails.totalInterest
                          ],
                          backgroundColor: [
                            '#4e79a7',
                            '#59a14f',
                            '#e15759'
                          ]
                        }]
                      }}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          title: {
                            display: true,
                            text: 'Total Loan Cost Breakdown'
                          },
                          tooltip: {
                            callbacks: {
                              label: function(context) {
                                return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
                              }
                            }
                          }
                        },
                        scales: {
                          y: {
                            ticks: {
                              callback: function(value) {
                                return formatCurrency(value);
                              }
                            }
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <PromoCard type="finance"/>
      <PromoCard/>
      <div className="educational-content">
        <h2>Wedding Planning Insights</h2>
        
        <div className="insights">
          <div className="insight">
            <h3>Metro vs Tier 2 City Costs</h3>
            <p>
              A ₹20L wedding in Jaipur might cost ₹30L in Mumbai due to higher venue and vendor costs. 
              The same budget gets you 30% more in tier 2 cities.
            </p>
          </div>
          
          <div className="insight">
            <h3>The Hidden Cost of Wedding Loans</h3>
            <p>
              A ₹10L wedding loan at 12% for 5 years costs ₹2.2L in interest - that's 22% extra. 
              Many couples end up paying more in interest than their original jewelry budget.
            </p>
          </div>
          
          <div className="insight highlight">
            <h3>The Power of Investing</h3>
            <p>
              ₹20L invested at 12% return becomes ₹2.7 crore in 20 years. That's the cost difference between 
              a lavish wedding and potential early retirement.
            </p>
          </div>
        </div>
        
        <div className="case-study">
          <h3>Real Couple Example: Mumbai vs Indore</h3>
          <p>
            Priya & Rahul planned identical weddings in Mumbai (₹35L) and Indore (₹22L). By choosing Indore 
            and investing the ₹13L difference, they gained ₹1.8 crore after 20 years while still having their 
            dream wedding.
          </p>
        </div>
        
        <div className="tips-section">
          <h3>Smart Wedding Planning Tips</h3>
          <ul>
            <li><strong>Off-season discounts:</strong> 15-20% savings on venues from April-June</li>
            <li><strong>Local vendors:</strong> Tier 2 city vendors often charge 30-40% less</li>
            <li><strong>Digital invites:</strong> Save ₹50,000-1L on printing and postage</li>
            <li><strong>Pre-owned jewelry:</strong> Family pieces can save lakhs</li>
            <li><strong>Limit guest list:</strong> Each additional guest costs ₹2,000-5,000</li>
          </ul>
        </div>
        
        <div className="conclusion">
          <h2>Key Takeaways</h2>
          <ul>
            <li>City choice impacts costs more than any other single factor</li>
            <li>Wedding loans often cost 20-30% extra in interest payments</li>
            <li>The same money invested could grow 10-15x in 20 years</li>
            <li>Small savings in multiple categories can fund your honeymoon</li>
          </ul>
          <p>
            Use this planner to balance your dream wedding with long-term financial security.
          </p>
        </div>
      </div>
    </div>
  );
};

const MarriageCostBlog = () => {
  return (
    <div className="marriage-blog">
      <article className="blog-content">
        <header className="blog-header">
          <h1>Your ₹20L Wedding Could Become ₹2.7 Crore: The Shocking Math of Marriage Costs in India</h1>
          <p className="meta">Last Updated: {new Date().toLocaleDateString('en-IN')} • 8 min read</p>
        </header>
        
        <section className="intro-section">
          <p className="lead">
            Our <strong>Marriage Cost Calculator</strong> reveals that a ₹20 lakh wedding in Mumbai could cost you 
            <strong>₹2.7 crore in lost potential</strong> over 20 years. Discover how to have your dream wedding 
            without sacrificing your financial future.
          </p>
        </section>
        
        <MarriageCostPlanner />
        
        <section className="content-section">
          <h2>The Rising Cost of Indian Weddings</h2>
          
          <p>
            Indian wedding costs have grown at <strong>25% annually</strong> since 2015, far outpacing inflation:
          </p>
          
          <ul>
            <li><strong>Average metro wedding:</strong> ₹25-50 lakhs</li>
            <li><strong>Tier 2 city wedding:</strong> ₹15-30 lakhs</li>
            <li><strong>Destination weddings:</strong> ₹75 lakhs-2 crores</li>
            <li><strong>63% of couples:</strong> Take loans for their weddings</li>
          </ul>
          
          <div className="case-study">
            <h3>Mumbai vs Jaipur: A ₹15L Difference</h3>
            <p>
              Identical weddings cost ₹35L in Mumbai but just ₹20L in Jaipur. The ₹15L difference invested at 12% 
              for 20 years grows to ₹1.45 crore - enough to buy a house in the same Jaipur neighborhood.
            </p>
          </div>
          
          <h2>The True Cost of Wedding Loans</h2>
          
          <div className="comparison">
            <div className="pros-cons">
              <h3>₹10L Wedding Loan</h3>
              <div className="cons">
                <h4>Cost Breakdown</h4>
                <ul>
                  <li>5 year term @12% interest</li>
                  <li>EMI: ₹22,244</li>
                  <li>Total payment: ₹13.35L</li>
                  <li>Interest: ₹3.35L (33.5% extra)</li>
                </ul>
              </div>
            </div>
            
            <div className="pros-cons">
              <h3>₹10L Invested Instead</h3>
              <div className="pros">
                <h4>Potential Growth</h4>
                <ul>
                  <li>12% annual return</li>
                  <li>5 years: ₹17.62L</li>
                  <li>10 years: ₹31.06L</li>
                  <li>20 years: ₹96.46L</li>
                </ul>
              </div>
            </div>
          </div>
          
          <h2>Balancing Dreams and Financial Reality</h2>
          
          <p>
            You don't have to choose between a memorable wedding and financial security. Smart couples are finding 
            ways to have both:
          </p>
          
          <ol>
            <li>
              <strong>Prioritize:</strong> Splurge on 2-3 most important elements, save on others
            </li>
            <li>
              <strong>Negotiate:</strong> Venues and caterers often have 15-20% flexibility
            </li>
            <li>
              <strong>Time it right:</strong> Off-season weddings save 15-25%
            </li>
            <li>
              <strong>Invest early:</strong> Start a "wedding fund" investment 2-3 years before
            </li>
          </ol>
          
          <div className="strategies">
            <h3>City-Specific Cost Cutting</h3>
            <ul>
              <li>
                <strong>Mumbai/Delhi:</strong> Suburban venues cost 30-40% less than city centers
              </li>
              <li>
                <strong>Bangalore:</strong> Weekday weddings save ₹2-5L on venues
              </li>
              <li>
                <strong>Jaipur/Udaipur:</strong> Local artisans provide decor at 50% discount
              </li>
              <li>
                <strong>South India:</strong> Temple weddings reduce venue costs by 80%
              </li>
            </ul>
          </div>
          
          <div className="conclusion">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Every ₹1L saved on your wedding could become ₹10-15L in 20 years</li>
              <li>Wedding loans typically add 25-35% to your total cost</li>
              <li>Tier 2 cities offer similar experiences at 30-50% discount</li>
              <li>Small compromises can fund your honeymoon or home downpayment</li>
            </ul>
            <p>
              Use our <strong>Marriage Cost Planner</strong> above to create your perfect balance between 
              celebration and long-term wealth building.
            </p>
          </div>
        </section>
        
        <footer className="blog-footer">
          <div className="tags">
            <span>#WeddingPlanning</span>
            <span>#FinancialFreedom</span>
            <span>#IndianWeddings</span>
            <span>#SmartInvesting</span>
          </div>
        </footer>
      </article>
    </div>
  );
};

export default MarriageCostBlog;