import { FiPieChart, FiTag, FiArrowRight } from 'react-icons/fi';
import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const gradientShift = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const PromoCardContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 1.75rem;
  margin: 1.5rem 0;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  display: flex;
  align-items: center;
  text-align: left;
  border: none;
  overflow: hidden;
  position: relative;
  background-size: 200% 200%;
  animation: ${gradientShift} 12s ease infinite;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
    animation: ${pulseAnimation} 2s ease infinite, ${gradientShift} 8s ease infinite;
  }

  &:active {
    transform: translateY(-1px);
  }

  @media (max-width: 768px) {
    padding: 1.25rem;
    margin: 1rem 0;
  }
`;

const PromoContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  z-index: 2;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    text-align: center;
    gap: 1.25rem;
  }
`;

const IconContainer = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  flex-shrink: 0;

  ${PromoCardContainer}:hover & {
    background: rgba(255, 255, 255, 0.25);
    transform: rotate(5deg);
  }

  @media (max-width: 480px) {
    display: none;
    padding: 0.75rem;
  }
`;

const PromoIcon = styled.div`
  font-size: 2rem;
  color: white;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const PromoHeadline = styled.h2`
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  color: white;
  letter-spacing: 0.5px;
  font-family: 'Inter', sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 480px) {
    font-size: 1.3rem;
    margin-bottom: 0.4rem;
  }
`;

const PromoSubtext = styled.p`
  font-size: 1.1rem;
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.6;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
  }

  @media (max-width: 480px) {
    font-size: 0.95rem;
  }
`;

const PromoArrow = styled.div`
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.4s ease;
  flex-shrink: 0;

  ${PromoCardContainer}:hover & {
    transform: translateX(8px);
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const GlowEffect = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 70%);
  z-index: 1;
  pointer-events: none;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const FinancePromoCard = styled(PromoCardContainer)`
  background: linear-gradient(135deg, #4361ee, #3a0ca3, #7209b7);
`;

const DealsPromoCard = styled(PromoCardContainer)`
  background: linear-gradient(135deg, #f72585, #b5179e, #7209b7);
`;

const PromoCard = ({ type, onClick }) => {
  const CardComponent = type === 'finance' ? FinancePromoCard : DealsPromoCard;
  const IconComponent = type === 'finance' ? FiPieChart : FiTag;
  const headline = type === 'finance' 
    ? 'EXPLORE OUR FINANCE TOOLS' 
    : 'MONEY-SAVING DEALS AWAIT!';
  const subtext = type === 'finance' 
    ? 'Calculate loans, investments & savings with our smart calculators' 
    : 'Discover exclusive discounts & coupon codes on Shoppingdeals';
  const url = type === 'finance' 
    ? 'https://shoppingdeals.in/finance' 
    : 'https://shoppingdeals.in';

  return (
    <CardComponent 
      onClick={() => window.location.href = url}
      onMouseMove={(e) => {
        const card = e.currentTarget;
        const glow = card.querySelector('.glow-effect');
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        
        glow.style.left = `${x - 60}px`;
        glow.style.top = `${y - 60}px`;
      }}
    >
      <GlowEffect className="glow-effect" />
      <PromoContent>
        <IconContainer>
          <PromoIcon as={IconComponent} />
        </IconContainer>
        <TextContainer>
          <PromoHeadline>{headline}</PromoHeadline>
          <PromoSubtext>{subtext}</PromoSubtext>
        </TextContainer>
        <PromoArrow as={FiArrowRight} />
      </PromoContent>
    </CardComponent>
  );
};

export default PromoCard;