import React from 'react';
import { Helmet } from 'react-helmet-async';
import SearchItem from "../pages/SearchItem";

const styles = {
    container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.6,
    maxWidth: 1200,
    margin: '0 auto',
    padding: 20,
    paddingTop: 50
    },
    heading1: {
    color: '#1a237e',
    borderBottom: '2px solid #f50057',
    paddingBottom: 10,
    marginBottom: 25,
    },
    heading2: {
    color: '#283593',
    marginTop: 30,
    textAlign: 'left',
    },
    heading3: {
    paddingTop: '30px',
    color: '#3f51b5',
    textAlign: 'left',
    },
    offerSection: {
    backgroundColor: '#f5f5f5',
    padding: 15,
    borderRadius: 8,
    margin: '20px 0',
    textAlign: 'left',
    },
    keyword: {
    color: '#f50057',
    fontWeight: 'bold',
    textAlign: 'left',
    },
    termsNote: {
    backgroundColor: '#fff8e1',
    padding: 15,
    borderLeft: '4px solid #ffc107',
    margin: '15px 0',
    textAlign: 'left',
    },
    list: {
    paddingLeft: 20,
    textAlign: 'left',
    },
    dl: {
    margin: '20px 0',
    textAlign: 'left',
    },
    dt: {
    fontWeight: 'bold',
    marginTop: 15,
    textAlign: 'left',
    },
    dd: {
    marginLeft: 20,
    textAlign: 'left',
    },
    p2: {
    textAlign: 'left',
    },
    span: {
    textAlign: 'left',
    },
    li: {
    textAlign: 'left',
    }
};


const WomenFashion = () => {
return (
<div>
    <Helmet>
        <title>Trendy Tops for Women | Shop Stylish Crop, Tank & Halter Neck Tops Online</title>
        <meta name="description" content="Discover stylish tops for women – crop tops, tank tops & halter necks designed to flatter every body type. Elevate your fashion with our diverse range of trendy tops. Shop now for perfect fits & stunning designs!" />
    </Helmet>
    <div style={styles.container}>

    <div style={styles.container}>
        <section style={styles.offerSection}>
            <h1 style={styles.heading1}>Elevate Your Fashion with Top Online Shopping for Women's Tops</h1>
            <h2 style={styles.heading2}>Discover the Perfect Tops for Every Body Type</h2>

            <p2>Are you searching for <strong>stylish tops</strong> that <strong>fit perfectly</strong> on your <strong>body type</strong>? With <strong>top online shopping</strong> destinations, you can explore a <strong>diverse range</strong> of <strong>women's tops</strong> - from trendy <strong>crop tops</strong> to classic <strong>tank tops</strong> - all designed to <strong>elevate your fashion</strong> game. Whether you prefer casual basics or <strong>stunning designs</strong>, there's something for every style in this <strong>wide variety</strong> of options.</p2>

            <h2 style={styles.heading2}>Trendy Tops for Women This Season</h2>

            <p2>This season's <strong>trendy tops</strong> include everything from comfortable <strong>halter neck</strong> styles to fashionable <strong>crop tops</strong>. Our <strong>range of tops</strong> includes options for all occasions - pair them with your favorite <strong>bottom wear</strong> to create complete looks. With <strong>online shopping</strong>, you can easily find <strong>women's tops</strong> that match your personal style and <strong>body type</strong>.</p2>

            <h2 style={styles.heading2}>Tank Tops Collection</h2>

            <p2>Our <strong>tank tops</strong> collection offers the perfect blend of comfort and style. Available in a <strong>wide variety</strong> of colors and fabrics, these <strong>women's tops</strong> are ideal for layering or wearing solo. The <strong>diverse range</strong> includes everything from basic cotton styles to <strong>stunning designs</strong> with embellishments that <strong>elevate your fashion</strong> instantly.</p2>

            <h2 style={styles.heading2}>Crop Tops for Every Occasion</h2>

            <p2><strong>Crop tops</strong> continue to dominate the fashion scene, and our collection features all the latest <strong>types of tops</strong> in this category. Pair them with <strong>high waisted</strong> jeans or skirts for a balanced silhouette. Through <strong>top online shopping</strong> platforms, you can find <strong>crop tops</strong> that <strong>fit perfectly</strong> and flatter your <strong>body type</strong>.</p2>

            <h2 style={styles.heading2}>Halter Neck Tops for Elegant Looks</h2>

            <p2>Our <strong>halter neck</strong> selection offers <strong>stylish tops</strong> that are perfect for special occasions. These <strong>women's tops</strong> create a flattering neckline and work well for various <strong>body types</strong>. The <strong>stunning designs</strong> in our <strong>halter neck</strong> collection will help you <strong>elevate your fashion</strong> effortlessly.</p2>

            <h2 style={styles.heading2}>Why Choose Our Online Shopping Experience</h2>

            <p2>When you shop our <strong>top online shopping</strong> platform, you get access to:<br/>
            - A <strong>diverse range</strong> of <strong>types of tops</strong><br/>
            - <strong>Stylish tops</strong> that <strong>fit perfectly</strong><br/>
            - <strong>Trendy tops</strong> at affordable prices<br/>
            - Easy returns and exchanges<br/>
            - <strong>Stunning designs</strong> for every <strong>body type</strong></p2>

            <h2 style={styles.heading2}>How to Style Your New Tops</h2>

            <p2>Complete your looks by pairing our <strong>women's tops</strong> with the right <strong>bottom wear</strong>:<br/>
            - <strong>Crop tops</strong> with <strong>high waisted</strong> pants<br/>
            - <strong>Tank tops</strong> with denim shorts<br/>
            - <strong>Halter neck</strong> tops with midi skirts<br/>
            The <strong>wide variety</strong> of <strong>types of tops</strong> in our collection ensures you'll find options for any occasion.</p2>

            <h2 style={styles.heading2}>Final Fashion Tips</h2>

            <p2>To make the most of your <strong>online shopping</strong> experience:<br/>
            - Check size guides to ensure <strong>fit perfectly</strong><br/>
            - Mix and match with your existing <strong>bottom wear</strong><br/>
            - Experiment with different <strong>types of tops</strong><br/>
            - Use our filters to navigate the <strong>wide variety</strong> of options<br/>
            - Read reviews to find <strong>stylish tops</strong> that work for your <strong>body type</strong></p2>

            <p2>With our <strong>top online shopping</strong> selection of <strong>women's tops</strong>, you can refresh your wardrobe with <strong>trendy tops</strong> that <strong>elevate your fashion</strong>. From <strong>tank tops</strong> to <strong>crop tops</strong> and <strong>halter neck</strong> styles, our <strong>diverse range</strong> has something for everyone. Start browsing today to discover <strong>stunning designs</strong> that will make you look and feel amazing!</p2>
        </section>
    </div>
    <h2 style={styles.heading2}>Here are the Best Women's Fashion Offers in India</h2>
    
    </div>
    <div className="card-container">
        <SearchItem search="women's"/>
    </div>
</div>
);
};

export default WomenFashion;

