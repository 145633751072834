import React, { useState, useEffect, useContext } from "react";
import AdminHomeItem from "./AdminHomeItem";
import Errorpage from "../../../components/pages/Errorpage";
import "../../Cards/card-style.css";
import { userContext } from "../../../App";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// socket.onAny((eventName, ...args) => {
// //  console.log("event: " + eventName + " val: " + args);
//  //toast("Wow so easy!");
//  //alert(args);
// });

const Home = () => {
  //console.log("Called Home.js");
  const { dispatch } = useContext(userContext);
  // const [newDealCount, setNewDealCount] = useState(0);
  let newDealCount;
  const [validToken, setValidToken] = useState(false);

  // var connectionOptions = {
  //   "force new connection": false,
  //   reconnectionAttempts: "Infinity",
  //   timeout: 1000,
  //   //"transports" : ["http"]
  //   transports: ["websocket", "polling", "flashsocket"],
  // };
  // // const socket = io.connect("http://localhost:5000", connectionOptions);
  // const socket = io.connect("https://eashopping.herokuapp.com/", connectionOptions);
  // socket.on("new_item", (arg) => {
  //   // console.log(arg);
  //   setNewDealCount(newDealCount+1);
  //   toast("New Deal: \n" + arg, {
  //     position: "bottom-right",
  //     autoClose: 15000,
  //     hideProgressBar: true,
  //     closeOnClick: false,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  //   //alert(arg)
  // });

  useEffect(()=>{
    validateToken();
    dispatch({ type: "Notification", payload: newDealCount});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[newDealCount]);
  

  const validateToken = async () => {
    const token = localStorage.getItem('token');
    const res = await fetch(process.env.REACT_APP_API + '/validateToken', {
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'Authorization': `Bearer ${token}` 
      }
    });
    if(res.status === 200){
      setValidToken(true);
    }
  }

  useEffect(() => {
    validateToken();
  },[]);

  if(!validToken) {
    return  <Errorpage/>;
  }

  return (
    <>
      <div className="home-page">
        <ToastContainer  
         limit={1} 
         position="bottom-right"
         autoClose={5000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         pauseOnFocusLoss
         draggable
         pauseOnHover
         theme="colored"
        />

        {/*<div
          className="card-body border shadow d-flex justify-content-between mt-3 mb-3 "
          style={{ backgroundColor: "white" }}
        >
          <div className="d-flex ">
            <div>
              <img src={logo} alt="logo" width="50rem" />
            </div>
            <div className="bold-text"> Latest Deals </div>
          </div>
          <div className="d-flex">
            <div className="bold-text pb-2 me-3"> Live </div>
            <div className="mt-1  me-3">
              {" "}
              <FontAwesomeIcon icon={faToggleOn} size="2x" />{" "}
            </div>
          </div>
        </div>*/}
        <div className="row d-flex" style={{ backgroundColor: "grey" }}>
          <div className="col-lg-1" style={{ backgroundColor: "#ffffff" }}>
            {/* Left side panel BurgerMenu
            Handaled in App.js*/}
          </div>
          <div className="col-lg-10 mt-1" style={{ backgroundColor: "white" }}>
            <div className="grid-cotntainer-update">
              <AdminHomeItem />
              {/* <HomeComponent/> */}
            </div>
          </div>
          <div className="col-lg-1 " style={{ backgroundColor: "#ffffff" }}>
            {/* Right side panel */}
            {/* <SidePanel/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
