import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './EmiCalculator.css';
import { Line } from 'react-chartjs-2';
import PromoCard from './Link';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const EMICalculator = () => {
  // Loan details
  const [loanAmount, setLoanAmount] = useState(5000000);
  const [interestRate, setInterestRate] = useState(8.5);
  const [loanTenure, setLoanTenure] = useState(20);
  
  // Prepayment details
  const [prepaymentAmount, setPrepaymentAmount] = useState(100000);
  const [prepaymentFrequency, setPrepaymentFrequency] = useState('yearly');
  const [prepaymentStartYear, setPrepaymentStartYear] = useState(1);
  
  // Results
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalInterestWithPrepayment, setTotalInterestWithPrepayment] = useState(0);
  const [tenureReduction, setTenureReduction] = useState(0);
  const [savings, setSavings] = useState(0);
  const [chartData, setChartData] = useState({});

  // Calculate EMI
  const calculateEMI = (principal, rate, tenure) => {
    const monthlyRate = rate / 12 / 100;
    const months = tenure * 12;
    return principal * monthlyRate * Math.pow(1 + monthlyRate, months) / 
           (Math.pow(1 + monthlyRate, months) - 1);
  };

  // Calculate loan schedule with prepayment
  const calculateLoanSchedule = () => {
    const monthlyRate = interestRate / 12 / 100;
    const months = loanTenure * 12;
    let balance = loanAmount;
    let totalInterestPaid = 0;
    let totalInterestPaidWithPrepayment = 0;
    
    const regularEMI = calculateEMI(loanAmount, interestRate, loanTenure);
    setEmi(regularEMI);
    
    // Calculate without prepayment
    let tempBalance = loanAmount;
    for (let i = 0; i < months; i++) {
      const interest = tempBalance * monthlyRate;
      const principal = regularEMI - interest;
      tempBalance -= principal;
      totalInterestPaid += interest;
    }
    setTotalInterest(totalInterestPaid);
    
    // Calculate with prepayment
    let prepaymentMonths = [];
    if (prepaymentFrequency === 'yearly') {
      prepaymentMonths = Array.from({ length: loanTenure - prepaymentStartYear + 1 }, 
        (_, i) => (prepaymentStartYear + i) * 12 - 1);
    } else if (prepaymentFrequency === 'half-yearly') {
      prepaymentMonths = Array.from({ length: (loanTenure - prepaymentStartYear + 1) * 2 }, 
        (_, i) => (prepaymentStartYear * 12) + (i * 6) - 1);
    } else { // quarterly
      prepaymentMonths = Array.from({ length: (loanTenure - prepaymentStartYear + 1) * 4 }, 
        (_, i) => (prepaymentStartYear * 12) + (i * 3) - 1);
    }
    
    let monthsSaved = 0;
    let tempBalanceWithPrepayment = loanAmount;
    let monthsPaid = 0;
    const labels = [];
    const regularInterest = [];
    const prepaymentInterest = [];
    
    for (let i = 0; i < months; i++) {
      const interest = tempBalanceWithPrepayment * monthlyRate;
      let principal = regularEMI - interest;
      
      // Check if this is a prepayment month
      if (prepaymentMonths.includes(i) && tempBalanceWithPrepayment > 0) {
        const effectivePrepayment = Math.min(prepaymentAmount, tempBalanceWithPrepayment - principal);
        principal += effectivePrepayment;
      }
      
      tempBalanceWithPrepayment -= principal;
      totalInterestPaidWithPrepayment += interest;
      monthsPaid++;
      
      // Record data for chart
      if (i % 12 === 0) {
        labels.push(`Year ${i/12 + 1}`);
        regularInterest.push(totalInterestPaid * (i+1) / months);
        prepaymentInterest.push(totalInterestPaidWithPrepayment);
      }
      
      if (tempBalanceWithPrepayment <= 0) {
        monthsSaved = months - monthsPaid;
        break;
      }
    }
    
    setTotalInterestWithPrepayment(totalInterestPaidWithPrepayment);
    setTenureReduction(monthsSaved / 12);
    setSavings(totalInterestPaid - totalInterestPaidWithPrepayment);
    
    // Prepare chart data
    setChartData({
      labels,
      datasets: [
        {
          label: 'Without Prepayment',
          data: regularInterest,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          fill: true,
          tension: 0.1
        },
        {
          label: 'With Prepayment',
          data: prepaymentInterest,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          fill: true,
          tension: 0.1
        }
      ]
    });
  };

  useEffect(() => {
    calculateLoanSchedule();
  }, [loanAmount, interestRate, loanTenure, prepaymentAmount, prepaymentFrequency, prepaymentStartYear]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="emi-calculator">
      <Helmet>
          <title>EMI Calculator with Prepayment | ShoppingDeals</title>
          <meta name="description" content="EMI Calculator with Prepayment Analysis" />
      </Helmet>
      <h1>EMI Calculator with Prepayment Analysis</h1>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h3>Loan Details</h3>
            
            <div className="input-row">
              <label htmlFor="loanAmount">Loan Amount (₹)</label>
              <input
                type="range"
                id="loanAmount"
                min="100000"
                max="20000000"
                step="100000"
                value={loanAmount}
                onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              />
              <div className="input-with-unit">
                <input
                  type="number"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(parseInt(e.target.value) || 0)}
                  min="100000"
                  max="20000000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="interestRate">Interest Rate (% p.a.)</label>
              <input
                type="range"
                id="interestRate"
                min="5"
                max="20"
                step="0.1"
                value={interestRate}
                onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              />
              <div className="input-with-unit">
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="20"
                  step="0.1"
                />
                <span>%</span>
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="loanTenure">Loan Tenure (Years)</label>
              <input
                type="range"
                id="loanTenure"
                min="1"
                max="30"
                value={loanTenure}
                onChange={(e) => setLoanTenure(parseInt(e.target.value))}
              />
              <div className="input-with-unit">
                <input
                  type="number"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(parseInt(e.target.value) || 0)}
                  min="1"
                  max="30"
                />
                <span>years</span>
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h3>Prepayment Details</h3>
            
            <div className="input-row">
              <label htmlFor="prepaymentAmount">Prepayment Amount (₹)</label>
              <input
                type="range"
                id="prepaymentAmount"
                min="10000"
                max="500000"
                step="10000"
                value={prepaymentAmount}
                onChange={(e) => setPrepaymentAmount(parseInt(e.target.value))}
              />
              <div className="input-with-unit">
                <input
                  type="number"
                  value={prepaymentAmount}
                  onChange={(e) => setPrepaymentAmount(parseInt(e.target.value) || 0)}
                  min="10000"
                  max="500000"
                  step="10000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="prepaymentFrequency">Prepayment Frequency</label>
              <select
                id="prepaymentFrequency"
                value={prepaymentFrequency}
                onChange={(e) => setPrepaymentFrequency(e.target.value)}
              >
                <option value="yearly">Yearly</option>
                <option value="half-yearly">Half-Yearly</option>
                <option value="quarterly">Quarterly</option>
              </select>
            </div>
            
            <div className="input-row">
              <label htmlFor="prepaymentStartYear">Start Prepayment After</label>
              <select
                id="prepaymentStartYear"
                value={prepaymentStartYear}
                onChange={(e) => setPrepaymentStartYear(parseInt(e.target.value))}
              >
                {Array.from({ length: loanTenure }, (_, i) => (
                  <option key={i+1} value={i+1}>{i+1} Year{i !== 0 ? 's' : ''}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="summary-cards">
            <div className="summary-card">
              <h4>Monthly EMI</h4>
              <p className="amount">{formatCurrency(emi)}</p>
            </div>
            
            <div className="summary-card">
              <h4>Interest Without Prepayment</h4>
              <p className="amount">{formatCurrency(totalInterest)}</p>
            </div>
            
            <div className="summary-card">
              <h4>Interest With Prepayment</h4>
              <p className="amount">{formatCurrency(totalInterestWithPrepayment)}</p>
            </div>
            
            <div className="summary-card highlight">
              <h4>Total Savings</h4>
              <p className="amount">{formatCurrency(savings)}</p>
            </div>
            
            <div className="summary-card">
              <h4>Tenure Reduction</h4>
              <p className="amount">{tenureReduction.toFixed(1)} years</p>
            </div>
          </div>
          
          <div className="chart-container">
            <h3>Interest Paid Over Time</h3>
            <div className="chart-wrapper">
              {chartData.labels && <Line data={chartData} />}
            </div>
          </div>
        </div>
      </div>
      <PromoCard type="finance"/>
      <PromoCard/>
      <div className="prepayment-tips">
        <h3>Prepayment Tips</h3>
        <ul>
          <li>Prepay early in the loan tenure to maximize interest savings</li>
          <li>Check with your bank for prepayment penalties (none for floating rate home loans in India)</li>
          <li>Consider partial prepayments instead of waiting to accumulate large amounts</li>
          <li>Compare prepayment returns with other investment options</li>
        </ul>
      </div>
    </div>
  );
};

export default EMICalculator;