import React, { useEffect, useState } from "react";
import '../../Cards/card-style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEye  } from '@fortawesome/fontawesome-free-regular'
// import { faComment, faClock, faThumbsUp, faThumbsDown } from '@fortawesome/fontawesome-free-regular'
// import imgexp from '../../../asset/expired.jpg';
// import CommentPreview from './CommentsPreview';
// import DislikePopup from './DislikePopup';
// import ReactGa from 'react-ga4';
import '../admin.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmptyItemCard = props => {
  // const [item, setItem] = useState({});
  const [imgsrc, setImgsrc] = useState(null);
  const [like, setLike] = useState(0);
  const [dislike, setDislike] = useState(0);
  const [comment, setComment] = useState(0); 
  const [mrp, setMrp] = useState(0); 
  const [op, setOp] = useState(0); 
  const [time, setTime] = useState('');
  // const [expired, setExpired] = useState('new');
  // const [display, setDisplay] = useState(false);
  const [vendor, setVendor] = useState('amazon');
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('');
  const [tags, setTags] = useState("");
  const [id, setId] = useState("");
  const [link, setLink] = useState("");
  const [viewcount, setViewCount] = useState('');
  // const [preview, setPreview] = useState(false);
  // const [dislikePreview, setDislikePreview] = useState(false);
  
  const callGetItem = async () => {
    var data = props.item;
    // setItem(data);
    setImgsrc(data.imgsrc);
    setId(data._id);
    setLike(data.like);
    setDislike(data.dislike);
    // setExpired(data.status);
    setStatus(data.status);
    setTags(data.tags);
    setDescription(data.description);
    setMrp(data.mrp);
    setOp(data.op);
    setLink(data.link);
    setViewCount(data.viewcount);
    var curr_time = new Date().getTime() / 1000 | 0;
    var tmp_time = (curr_time - data.time) / 60 | 0; //mins
    if (tmp_time > 59) {
      tmp_time = tmp_time / 60 | 0; //hrs
      if (tmp_time > 23) {
        tmp_time = tmp_time / 24 | 0; //days
        if (tmp_time > 29) {
          tmp_time = tmp_time / 30 | 0; //months
          if (tmp_time > 11) {
            tmp_time = tmp_time / 12 | 0; //years
            tmp_time = tmp_time + " years";
          } else {
            tmp_time = tmp_time + " months";
          }
        } else {
          tmp_time = tmp_time + " days";
        }
      } else {
        tmp_time = tmp_time + " hours";
      }
    } else {
      tmp_time = tmp_time + " mins";
    }
    data.time = tmp_time;
    setTime(tmp_time);
    // setDisplay(true);
  }

  useEffect(() => {
    callGetItem();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OverlayImage = () => {
    return (
      <>
        <div className="card-img-top-sm">
          <img src={imgsrc} alt={props.imgsrc} className="imgOriginalDivSm" />
        </div>
      </>
    )
  }

  const updateTimestamp = async () => {
    const res = await fetch(process.env.REACT_APP_API + '/updatetimestamp', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id
      })
    });
    if (res.status === 422) {
      // window.alert("Invalid Data. res");
      console.log("Invalid Data",res);
    } else {
      toast("Time Stamp updated:" + description);
      // window.alert("Item updated Sucessful");
      console.log("Time Stamp updated");
    }

  }

  const deleteItem = async () => {
    const res = await fetch(process.env.REACT_APP_API + '/deleteitem', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id
      })
    });
    if (res.status === 422) {
      // window.alert("Invalid Data. res");
      console.log("Invalid Data",res);
    } else {
      // window.alert("Item updated Sucessful");
      console.log(res.status);
      toast("Item Deleted:" + description);

    }

  }
  
  const updateItem = async (e) => {
    e.preventDefault();
    //const {  description, imgsrc, link, vendor, mrp, op, status, like, comment, dislike } = item;
    //const id = item._id;
    const res = await fetch(process.env.REACT_APP_API + '/updateitem', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id, description, imgsrc, link, vendor, status, mrp, op, like, comment, dislike, tags, note
      })
    });
    //const data = await res.message;
    if (res.status === 201) {
      toast("Updated: " + description);
    } else {
      console.log("Invalid Data. res");

    }
  }

  const previewItem = async () => {
    var ext_link = link.replace('simplycheaper-21', '');
    window.open(ext_link);
  }
 
  const ItmeLoaded = () => {
    // const handleDropdownChange = (e) => {
    //   setStatus(e.target.value );
    // }
    return (
      <>
        <div className='admin-grid-item'
        >
          {/* <div className="card-item text-center" style={{ max_width: "15rem" }}> */}
          <div className="admin-card-item text-center">
                {/*<img src={imgUrl} alt={props.imgsrc} className="card-img-top"/>*/}
                <OverlayImage />
            <form id="update_item_form" method="POST" key="unique101">
              <div className="form-group">
                <div className="card-body-admin text-dark">
                  <div className="d-flex">
                    <div> <FontAwesomeIcon icon={faClock} size="xs" />&nbsp;&nbsp;</div>
                    <div className="font-weight-light">{time} &nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div> <FontAwesomeIcon icon={faEye} size="xs" />&nbsp;</div>
                    <div className="font-weight-light">{viewcount} &nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="btn btn-secondary btn-sm" onClick={previewItem}>
                      Preview
                    </div>
                  </div>
                  <textarea
                    type="text"
                    id="form_description"
                    className="form-control form-control-sm"
                    // onChange={(e) => setDescription(e.target.value)}
                    onBlur={(e) => setDescription(e.target.value)}
                    placeholder="Product Description"
                    defaultValue={description}
                    required={true}
                  />
                  {/* <h4 className="card-title">{props.name}</h4> */}
                  {/* <p className="text-description">{item.description}</p> */}
                  {/* <div className="font-weight-light">{tags} </div> */}
                  <div className="d-flex justify-content-between px-3 pb-0">
                    <div className="form-group">
                    <select className="form-control form-control-sm" id="form_tags" 
                    onChange={(e) => setTags(e.target.value)} 
                    defaultValue={tags}>
                    <option>none</option>
                      <option>bag</option>
                      <option>beauty</option>
                      <option>car-and-bike</option>
                      <option>clothing</option>
                      <option>computers</option>
                      <option>electronics</option>
                      <option>e-accessories</option>
                      <option>footwear</option>
                      <option>grocery</option>
                      <option>home</option>
                      <option>home-improvement</option>
                      <option>jewelry</option>
                      <option>kitchen</option>
                      <option>large-appliances</option>
                      <option>mobiles</option>
                      <option>musical-instruments</option>
                      <option>office</option>
                      <option>personal-care</option>
                      <option>sports</option>
                      <option>toys</option>
                      <option>watches</option>
                    </select>

                  </div>
                    <div className="form-group">
                      <select className="form-control form-control-sm" id="form_status" 
                      onChange={(e) => setStatus(e.target.value)} 
                      defaultValue={status}>
                        <option>expired</option>
                        <option>new</option>
                      </select>
                    </div>
                  </div>
                  <textarea
                    type="text"
                    id="form_img"
                    className="form-control form-control-sm"
                    onBlur={(e) => setImgsrc(e.target.value)}
                    placeholder="Imagre URL"
                    defaultValue={imgsrc}
                    required={true}
                  />
                   <textarea
                    type="text"
                    id="form_link"
                    className="form-control form-control-sm"
                    onBlur={(e) => setLink(e.target.value)}
                    placeholder="Link"
                    defaultValue={link}
                    required={true}
                  />
                  {/* <div className="font-weight-light">{imgUrl} </div> */}
                  {/* <div className="font-weight-light">{status} </div> */}

                  <div className="d-flex justify-content-between px-3 pb-0">
                    {/* <p className="text-secondary"><s>&#8377;{item.mrp}</s></p>
                <p className="text-dark bg-light ps-1 pe-1">{item.vendor}</p>
                <p className="text-primary">&#8377;{item.op}</p> */}
                    <input
                      type="text"
                      id="form_mrp"
                      className="form-control form-control-sm"
                      onBlur={(e) => setMrp(e.target.value)}
                      placeholder="Status"
                      defaultValue={mrp}
                      required={true}
                    />
                    <input
                      type="text"
                      id="form_vendor"
                      className="form-control form-control-sm"
                      onBlur={(e) => setVendor(e.target.value)}
                      placeholder="Vendor"
                      defaultValue={vendor}
                      required={true}
                    />
                    <input
                      type="text"
                      id="form_op"
                      className="form-control form-control-sm"
                      onBlur={(e) => setOp(e.target.value)}
                      placeholder="OP"
                      defaultValue={op}
                      required={true}
                    />
                    <input
                      type="text"
                      id="form_note"
                      className="form-control form-control-sm"
                      onBlur={(e) => setNote(e.target.value)}
                      placeholder="note"
                      defaultValue={note}
                      required={true}
                    />
                  </div>
                  {/* <div className="btn-get-deal" onClick={() => window.open(item.link, "_blank")}> */}
                  <div className="d-flex justify-content-between px-2 mt-1 mb-1">
                    <div className="btn btn-warning btn-sm" onClick={updateTimestamp}>
                      TIME
                    </div>
                    <div className="btn btn-danger btn-sm" onClick={deleteItem}>
                      DELETE
                    </div>
                    <div className="btn btn-primary btn-sm" onClick={updateItem}>
                      UPDATE
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ps-3 pe-3 mt-0">
                    {/* <div className="d-flex" onClick={addLike}>
                  <div> <FontAwesomeIcon icon={faThumbsUp} size="xs" />&nbsp;</div>
                  <div className="font-weight-light">{like}</div>
                </div>
                <div className="d-flex" onClick={() => setPreview(true)}>
                  <div> <FontAwesomeIcon icon={faComment} size="xs" />&nbsp;</div>
                  <div className="font-weight-light">{item.comment}</div>
                </div>
                <div className="d-flex" onClick={handleDislike}>
                  <div> <FontAwesomeIcon icon={faThumbsDown} size="xs" />&nbsp;</div>
                  <div className="font-weight-light">{dislike}</div>
                </div> */}
                    <input
                      type="text"
                      id="form_like"
                      className="form-control form-control-sms"
                      onBlur={(e) => setLike(e.target.value)}
                      placeholder="Status"
                      defaultValue={like}
                      required={true}
                    />
                    <input
                      type="text"
                      id="form_comment"
                      className="form-control form-control-sm"
                      onBlur={(e) => setComment(e.target.value)}
                      placeholder="Status"
                      defaultValue={comment}
                      required={true}
                    />
                    <input
                      type="text"
                      id="form_dislike"
                      className="form-control form-control-sm"
                      onBlur={(e) => setDislike(e.target.value)}
                      placeholder="Status"
                      defaultValue={dislike}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <AddMessage trigger={preview} setTrigger={setPreview} /> */}
        {/* <ExpirePopup trigger={dislikePreview} setTrigger={setDislikePreview} setExp={setExpired} /> */}
      </>
    );
  }

  return  (
  
  <>
      <ItmeLoaded />
    </>
  ) ;
}


export default EmptyItemCard;