import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EducationCostCalculator = () => {
  // Education Parameters
  const [childAge, setChildAge] = useState(5);
  const [educationType, setEducationType] = useState('indian_engineering');
  const [educationStartAge, setEducationStartAge] = useState(18);
  
  // Cost Parameters
  const [currentCost, setCurrentCost] = useState(200000);
  const [educationInflation, setEducationInflation] = useState(10);
  const [currencyRisk, setCurrencyRisk] = useState(3);
  
  // Investment Strategy
  const [investmentOption, setInvestmentOption] = useState('ssy');
  const [monthlyInvestment, setMonthlyInvestment] = useState(5000);
  const [expectedReturn, setExpectedReturn] = useState(8);
  
  // Results
  const [futureCost, setFutureCost] = useState(0);
  const [shortfall, setShortfall] = useState(0);
  const [projectionData, setProjectionData] = useState([]);
  const [loanEmi, setLoanEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);

  // Education options with current costs
  const educationOptions = [
    { id: 'indian_engineering', name: 'Indian Engineering (IIT)', cost: 200000 },
    { id: 'indian_medical', name: 'Indian Medical (MBBS)', cost: 1000000 },
    { id: 'ivy_league', name: 'Ivy League (USD)', cost: 50000, currency: 'USD' },
    { id: 'uk_university', name: 'UK University (GBP)', cost: 25000, currency: 'GBP' },
    { id: 'indian_mba', name: 'Indian MBA (IIM)', cost: 2500000 },
    { id: 'custom', name: 'Custom', cost: 0 }
  ];

  useEffect(() => {
    calculateProjection();
  }, [childAge, educationType, educationStartAge, currentCost, 
      educationInflation, investmentOption, monthlyInvestment, expectedReturn]);

  const calculateProjection = () => {
    const yearsToEducation = educationStartAge - childAge;
    const selectedEducation = educationOptions.find(opt => opt.id === educationType);
    
    // Calculate future cost
    let baseCost = selectedEducation ? selectedEducation.cost : currentCost;
    if (selectedEducation?.currency) {
      // Apply currency risk for foreign education
      baseCost = baseCost * 75; // Assuming 75 INR = 1 USD/GBP
    }
    
    const cost = baseCost * Math.pow(1 + educationInflation / 100, yearsToEducation);
    setFutureCost(cost);
    
    // Calculate investment growth
    const months = yearsToEducation * 12;
    const monthlyRate = expectedReturn / 12 / 100;
    const investmentValue = monthlyInvestment * 
      (Math.pow(1 + monthlyRate, months) - 1) / monthlyRate;
    
    // Calculate shortfall
    const shortfallAmount = Math.max(0, cost - investmentValue);
    setShortfall(shortfallAmount);
    
    // Calculate loan details if shortfall
    if (shortfallAmount > 0) {
      const loanTenure = 10; // 10 years repayment
      const loanRate = 9; // 9% education loan interest
      const monthlyLoanRate = loanRate / 12 / 100;
      const loanMonths = loanTenure * 12;
      const emi = shortfallAmount * monthlyLoanRate * 
        Math.pow(1 + monthlyLoanRate, loanMonths) / 
        (Math.pow(1 + monthlyLoanRate, loanMonths) - 1);
      
      setLoanEmi(emi);
      setTotalInterest(emi * loanMonths - shortfallAmount);
    } else {
      setLoanEmi(0);
      setTotalInterest(0);
    }
    
    // Prepare projection data for chart
    const projection = [];
    for (let year = 0; year <= yearsToEducation; year++) {
      const yearCost = baseCost * Math.pow(1 + educationInflation / 100, year);
      const investmentMonths = year * 12;
      let investmentVal = 0;
      
      if (investmentMonths > 0) {
        investmentVal = monthlyInvestment * 
          (Math.pow(1 + monthlyRate, investmentMonths) - 1) / monthlyRate;
      }
      
      projection.push({
        year: childAge + year,
        cost: yearCost,
        investment: investmentVal
      });
    }
    
    setProjectionData(projection);
  };

  const handleEducationTypeChange = (e) => {
    const type = e.target.value;
    setEducationType(type);
    
    // Update current cost when education type changes
    const selected = educationOptions.find(opt => opt.id === type);
    if (selected && type !== 'custom') {
      setCurrentCost(selected.cost);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="education-calculator">
      <div className="calculator-header">
        <h1>Education Cost Calculator (India-Specific)</h1>
        <p className="subtitle">Plan for your child's education with accurate future cost projections</p>
      </div>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Education Details</h2>
            
            <div className="input-row">
              <label htmlFor="childAge">Child's Current Age</label>
              <div className="range-container">
                <input
                  type="range"
                  id="childAge"
                  min="0"
                  max="17"
                  value={childAge}
                  onChange={(e) => setChildAge(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={childAge}
                  onChange={(e) => setChildAge(parseInt(e.target.value) || 0)}
                  min="0"
                  max="17"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="educationType">Education Type</label>
              <select
                id="educationType"
                value={educationType}
                onChange={handleEducationTypeChange}
              >
                {educationOptions.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </select>
            </div>
            
            {educationType === 'custom' && (
              <div className="input-row">
                <label htmlFor="currentCost">Current Annual Cost (₹)</label>
                <input
                  type="number"
                  id="currentCost"
                  value={currentCost}
                  onChange={(e) => setCurrentCost(parseInt(e.target.value) || 0)}
                  min="0"
                />
              </div>
            )}
            
            <div className="input-row">
              <label htmlFor="educationStartAge">Education Start Age</label>
              <div className="range-container">
                <input
                  type="range"
                  id="educationStartAge"
                  min={childAge + 1}
                  max="30"
                  value={educationStartAge}
                  onChange={(e) => setEducationStartAge(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={educationStartAge}
                  onChange={(e) => setEducationStartAge(parseInt(e.target.value) || 0)}
                  min={childAge + 1}
                  max="30"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="educationInflation">Education Inflation (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="educationInflation"
                  min="5"
                  max="20"
                  step="0.5"
                  value={educationInflation}
                  onChange={(e) => setEducationInflation(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={educationInflation}
                  onChange={(e) => setEducationInflation(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="20"
                  step="0.5"
                />
              </div>
            </div>
            
            {(educationType === 'ivy_league' || educationType === 'uk_university') && (
              <div className="input-row">
                <label htmlFor="currencyRisk">Currency Risk (% p.a.)</label>
                <div className="range-container">
                  <input
                    type="range"
                    id="currencyRisk"
                    min="0"
                    max="10"
                    step="0.5"
                    value={currencyRisk}
                    onChange={(e) => setCurrencyRisk(parseFloat(e.target.value))}
                  />
                  <input
                    type="number"
                    value={currencyRisk}
                    onChange={(e) => setCurrencyRisk(parseFloat(e.target.value) || 0)}
                    min="0"
                    max="10"
                    step="0.5"
                  />
                </div>
              </div>
            )}
          </div>
          
          <div className="input-group">
            <h2>Investment Strategy</h2>
            
            <div className="input-row">
              <label>Investment Option</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="investmentOption"
                    value="ssy"
                    checked={investmentOption === 'ssy'}
                    onChange={() => setInvestmentOption('ssy')}
                  />
                  Sukanya Samriddhi Yojana (SSY)
                </label>
                <label>
                  <input
                    type="radio"
                    name="investmentOption"
                    value="mp2"
                    checked={investmentOption === 'mp2'}
                    onChange={() => setInvestmentOption('mp2')}
                  />
                  Post Office MP2
                </label>
                <label>
                  <input
                    type="radio"
                    name="investmentOption"
                    value="mutual_fund"
                    checked={investmentOption === 'mutual_fund'}
                    onChange={() => setInvestmentOption('mutual_fund')}
                  />
                  Equity Mutual Funds
                </label>
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="monthlyInvestment">Monthly Investment (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="monthlyInvestment"
                  min="1000"
                  max="100000"
                  step="1000"
                  value={monthlyInvestment}
                  onChange={(e) => setMonthlyInvestment(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={monthlyInvestment}
                  onChange={(e) => setMonthlyInvestment(parseInt(e.target.value) || 0)}
                  min="1000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="expectedReturn">Expected Return (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="expectedReturn"
                  min="5"
                  max="15"
                  step="0.5"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="15"
                  step="0.5"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="summary-cards">
            <div className="summary-card">
              <h3>Future Education Cost</h3>
              <p className="amount">{formatCurrency(futureCost)}</p>
              <p className="subtext">When your child turns {educationStartAge}</p>
            </div>
            
            <div className="summary-card">
              <h3>Investment Value</h3>
              <p className="amount">{formatCurrency(
                projectionData[projectionData.length - 1]?.investment || 0
              )}</p>
              <p className="subtext">After {educationStartAge - childAge} years</p>
            </div>
            
            <div className={`summary-card ${shortfall > 0 ? 'danger' : 'success'}`}>
              <h3>{shortfall > 0 ? 'Shortfall Amount' : 'Surplus Amount'}</h3>
              <p className="amount">{formatCurrency(Math.abs(shortfall))}</p>
              <p className="subtext">
                {shortfall > 0 ? 'Additional funds needed' : 'You have enough saved'}
              </p>
            </div>
          </div>
          
          {shortfall > 0 && (
            <div className="loan-details">
              <h2>Education Loan Details</h2>
              <div className="loan-cards">
                <div className="loan-card">
                  <h3>Loan Required</h3>
                  <p className="amount">{formatCurrency(shortfall)}</p>
                </div>
                <div className="loan-card">
                  <h3>Estimated EMI</h3>
                  <p className="amount">{formatCurrency(loanEmi)}</p>
                </div>
                <div className="loan-card">
                  <h3>Total Interest</h3>
                  <p className="amount">{formatCurrency(totalInterest)}</p>
                </div>
              </div>
            </div>
          )}
          
          <div className="projection-chart">
            <h2>Cost vs Investment Projection</h2>
            <div className="chart-container">
              <Line 
                data={{
                  labels: projectionData.map(item => item.year),
                  datasets: [
                    {
                      label: 'Education Cost',
                      data: projectionData.map(item => item.cost),
                      borderColor: '#e53935',
                      backgroundColor: 'rgba(229, 57, 53, 0.1)',
                      fill: true,
                      tension: 0.1
                    },
                    {
                      label: 'Investment Value',
                      data: projectionData.map(item => item.investment),
                      borderColor: '#388e3c',
                      backgroundColor: 'rgba(56, 142, 60, 0.1)',
                      fill: true,
                      tension: 0.1
                    }
                  ]
                }}
                options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Education Cost vs Savings Projection'
                    },
                    tooltip: {
                      callbacks: {
                        label: function(context) {
                          return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
                        }
                      }
                    }
                  },
                  scales: {
                    y: {
                      ticks: {
                        callback: function(value) {
                          if (value >= 10000000) return `${value/10000000} Cr`;
                          if (value >= 100000) return `${value/100000} L`;
                          return value;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          
          <div className="comparison-table">
            <h2>Current Education Costs (2023)</h2>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Institution Type</th>
                    <th>Program</th>
                    <th>Current Cost (₹)</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>IIT (Indian Institute of Technology)</td>
                    <td>B.Tech</td>
                    <td>₹2-2.5 Lakh/year</td>
                    <td>4 years</td>
                  </tr>
                  <tr>
                    <td>IIM (Indian Institute of Management)</td>
                    <td>MBA</td>
                    <td>₹20-25 Lakh total</td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>Government Medical College</td>
                    <td>MBBS</td>
                    <td>₹50,000-1 Lakh/year</td>
                    <td>5.5 years</td>
                  </tr>
                  <tr>
                    <td>Private Medical College</td>
                    <td>MBBS</td>
                    <td>₹10-25 Lakh/year</td>
                    <td>5.5 years</td>
                  </tr>
                  <tr>
                    <td>Ivy League (USA)</td>
                    <td>Undergraduate</td>
                    <td>$50-60K/year (₹37-45 Lakh/year)</td>
                    <td>4 years</td>
                  </tr>
                  <tr>
                    <td>UK University</td>
                    <td>Undergraduate</td>
                    <td>£25-35K/year (₹25-35 Lakh/year)</td>
                    <td>3 years</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationCostCalculator;
