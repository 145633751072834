import React from 'react';
import '../../css/AboutUs.css';
import HomeItem from "./HomeItem";

const AboutUs = () => {
    return (
        <div>
            <div className="card-container">
                <HomeItem/>
            </div>
        <div className="about-us-container">
            <div className="about-us-content">
                <h1>About Us</h1>
                <p>At ShoppingDeals, our mission is simple: to bring you the best deals and savings on a wide range of products and services. We understand that finding great deals can sometimes be overwhelming and time-consuming, which is why we've created a platform that makes it easy for you to discover and take advantage of incredible offers.</p>
                <p><b>What sets us apart?</b></p>
                <p className="left-align"><b>Curated Selection:</b> Our team scours the web to handpick the most enticing deals across various categories, ensuring that you have access to a diverse and high-quality range of products and services.</p>
                <p className="left-align"><b>Exclusive Discounts:</b> We collaborate directly with brands and merchants to secure exclusive discounts and promotions, enabling you to save money on your favorite products or discover new ones at unbeatable prices.</p>
                <p className="left-align"><b>User-Friendly Interface:</b> Our website is designed with your convenience in mind. With intuitive navigation and search functionalities, you can quickly find the deals that matter to you and start enjoying the savings right away.</p>
                <p className="left-align"><b>Timely Updates:</b> Deals come and go quickly, so we make it a priority to provide real-time updates. Be the first to know about limited-time offers, flash sales, and special promotions by subscribing to our newsletter or following us on social media.</p>
                <p className="left-align">Whether you're looking for electronics, fashion, home goods, travel packages, or even local experiences, ShoppingDeals has got you covered. Join our community of savvy shoppers and start unlocking incredible savings today!</p>
                <p className="left-align">Stay tuned for exciting updates and new deals every day. We're here to help you make the most of your hard-earned money.</p>
                <p className="left-align">For any Product or Brand related queries please contct us at <u>contact@shoppingdeals.in</u></p>
                <p>Happy shopping!</p>

                <p>The ShoppingDeals Team</p>
                <p className="disclaimer">Disclaimer: Please note that we are not responsible for any third-party websites that may be linked from our website.</p>
            </div>
        </div>
        </div>
    );
}

export default AboutUs;
