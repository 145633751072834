import React, { useState, useEffect } from 'react';
import './RealEstateROI.css';

const RealEstateROICalculator = () => {
  // Property Inputs
  const [propertyPrice, setPropertyPrice] = useState(10000000);
  const [downPayment, setDownPayment] = useState(20);
  const [loanTenure, setLoanTenure] = useState(20);
  const [interestRate, setInterestRate] = useState(8.5);
  
  // Rental Inputs
  const [monthlyRent, setMonthlyRent] = useState(30000);
  const [vacancyRate, setVacancyRate] = useState(10);
  const [rentGrowth, setRentGrowth] = useState(5);
  
  // Cost Inputs
  const [maintenanceCost, setMaintenanceCost] = useState(1);
  const [propertyTax, setPropertyTax] = useState(0.5);
  const [registrationCost, setRegistrationCost] = useState(6);
  const [gstRate, setGstRate] = useState(1);
  const [agentFee, setAgentFee] = useState(1);
  
  // REIT Comparison
  const [reitYield, setReitYield] = useState(7);
  const [reitGrowth, setReitGrowth] = useState(8);
  
  // Results
  const [netRentalYield, setNetRentalYield] = useState(0);
  const [annualCashFlow, setAnnualCashFlow] = useState(0);
  const [totalHiddenCosts, setTotalHiddenCosts] = useState(0);
  const [reitReturn, setReitReturn] = useState(0);
  const [propertyReturn, setPropertyReturn] = useState(0);
  const [yearlyBreakdown, setYearlyBreakdown] = useState([]);

  const calculateROI = () => {
    // Calculate upfront costs
    const loanAmount = propertyPrice * (100 - downPayment) / 100;
    const emi = calculateEMI(loanAmount, interestRate, loanTenure);
    const registration = propertyPrice * registrationCost / 100;
    const gst = propertyPrice * gstRate / 100;
    
    // Annual costs
    const annualMaintenance = propertyPrice * maintenanceCost / 100;
    const annualTax = propertyPrice * propertyTax / 100;
    const annualAgentFee = monthlyRent * 12 * agentFee / 100;
    const effectiveRent = monthlyRent * (100 - vacancyRate) / 100;
    
    // Yearly calculations
    const breakdown = [];
    let cumulativeReturn = 0;
    let cumulativeReitReturn = 0;
    let currentRent = effectiveRent;
    let currentValue = propertyPrice;
    
    for (let year = 1; year <= 10; year++) {
      // Property cash flow
      const yearlyRent = currentRent * 12;
      const yearlyCosts = annualMaintenance + annualTax + (year === 1 ? registration + gst : 0) + 
                         (year % 2 === 0 ? annualAgentFee : 0);
      const yearlyCashFlow = yearlyRent - yearlyCosts - (emi * 12);
      
      // Appreciation (conservative 5% for India)
      currentValue *= 1.05;
      currentRent *= 1 + rentGrowth / 100;
      
      // REIT comparison
      const reitYearlyReturn = (10000000 * (reitYield / 100)) * Math.pow(1 + reitGrowth / 100, year);
      
      breakdown.push({
        year,
        rent: yearlyRent,
        costs: yearlyCosts,
        cashFlow: yearlyCashFlow,
        propertyValue: currentValue,
        reitValue: 10000000 * Math.pow(1 + (reitYield + reitGrowth) / 100, year)
      });
    }
    
    // Final calculations
    const totalHidden = registration + gst + (annualMaintenance * 10) + (annualTax * 10) + (annualAgentFee * 5);
    const netYield = ((effectiveRent * 12) - annualMaintenance - annualTax) / propertyPrice * 100;
    
    setNetRentalYield(netYield);
    setAnnualCashFlow(breakdown[0].cashFlow);
    setTotalHiddenCosts(totalHidden);
    setYearlyBreakdown(breakdown);
    setPropertyReturn(breakdown[breakdown.length - 1].propertyValue);
    setReitReturn(breakdown[breakdown.length - 1].reitValue);
  };

  const calculateEMI = (principal, rate, tenure) => {
    const monthlyRate = rate / 12 / 100;
    const months = tenure * 12;
    return principal * monthlyRate * Math.pow(1 + monthlyRate, months) / 
           (Math.pow(1 + monthlyRate, months) - 1);
  };

  useEffect(() => {
    calculateROI();
  }, [
    propertyPrice, downPayment, loanTenure, interestRate,
    monthlyRent, vacancyRate, rentGrowth, maintenanceCost,
    propertyTax, registrationCost, gstRate, agentFee,
    reitYield, reitGrowth
  ]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const formatPercent = (value) => {
    return value.toFixed(1) + '%';
  };

  return (
    <div className="roi-calculator">
      <div className="calculator-header">
        <h1>Real Estate ROI Calculator with Hidden Costs</h1>
        <p className="subtitle">Discover the true returns after all expenses and compare with REIT investments</p>
      </div>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Property Details</h2>
            
            <div className="input-row">
              <label htmlFor="propertyPrice">Property Price (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="propertyPrice"
                  min="500000"
                  max="50000000"
                  step="100000"
                  value={propertyPrice}
                  onChange={(e) => setPropertyPrice(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={propertyPrice}
                  onChange={(e) => setPropertyPrice(parseInt(e.target.value) || 0)}
                  min="500000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="downPayment">Down Payment (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="downPayment"
                  min="10"
                  max="100"
                  value={downPayment}
                  onChange={(e) => setDownPayment(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={downPayment}
                  onChange={(e) => setDownPayment(parseInt(e.target.value) || 0)}
                  min="10"
                  max="100"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Loan Details</h2>
            
            <div className="input-row">
              <label htmlFor="loanTenure">Loan Tenure (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="loanTenure"
                  min="5"
                  max="30"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(parseInt(e.target.value) || 0)}
                  min="5"
                  max="30"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="interestRate">Interest Rate (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="interestRate"
                  min="5"
                  max="15"
                  step="0.1"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                  min="5"
                  max="15"
                  step="0.1"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Rental Details</h2>
            
            <div className="input-row">
              <label htmlFor="monthlyRent">Monthly Rent (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="monthlyRent"
                  min="5000"
                  max="200000"
                  step="1000"
                  value={monthlyRent}
                  onChange={(e) => setMonthlyRent(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={monthlyRent}
                  onChange={(e) => setMonthlyRent(parseInt(e.target.value) || 0)}
                  min="5000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="vacancyRate">Vacancy Rate (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="vacancyRate"
                  min="0"
                  max="30"
                  value={vacancyRate}
                  onChange={(e) => setVacancyRate(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={vacancyRate}
                  onChange={(e) => setVacancyRate(parseInt(e.target.value) || 0)}
                  min="0"
                  max="30"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="rentGrowth">Rent Growth (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="rentGrowth"
                  min="0"
                  max="15"
                  value={rentGrowth}
                  onChange={(e) => setRentGrowth(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={rentGrowth}
                  onChange={(e) => setRentGrowth(parseInt(e.target.value) || 0)}
                  min="0"
                  max="15"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Hidden Costs</h2>
            
            <div className="input-row">
              <label htmlFor="maintenanceCost">Maintenance (% of property value)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="maintenanceCost"
                  min="0"
                  max="5"
                  step="0.1"
                  value={maintenanceCost}
                  onChange={(e) => setMaintenanceCost(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={maintenanceCost}
                  onChange={(e) => setMaintenanceCost(parseFloat(e.target.value) || 0)}
                  min="0"
                  max="5"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="propertyTax">Property Tax (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="propertyTax"
                  min="0"
                  max="2"
                  step="0.1"
                  value={propertyTax}
                  onChange={(e) => setPropertyTax(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={propertyTax}
                  onChange={(e) => setPropertyTax(parseFloat(e.target.value) || 0)}
                  min="0"
                  max="2"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="registrationCost">Registration (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="registrationCost"
                  min="1"
                  max="10"
                  value={registrationCost}
                  onChange={(e) => setRegistrationCost(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={registrationCost}
                  onChange={(e) => setRegistrationCost(parseInt(e.target.value) || 0)}
                  min="1"
                  max="10"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="gstRate">GST (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="gstRate"
                  min="0"
                  max="5"
                  value={gstRate}
                  onChange={(e) => setGstRate(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={gstRate}
                  onChange={(e) => setGstRate(parseInt(e.target.value) || 0)}
                  min="0"
                  max="5"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="agentFee">Agent Fee (% of rent)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="agentFee"
                  min="0"
                  max="5"
                  step="0.5"
                  value={agentFee}
                  onChange={(e) => setAgentFee(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={agentFee}
                  onChange={(e) => setAgentFee(parseFloat(e.target.value) || 0)}
                  min="0"
                  max="5"
                  step="0.5"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>REIT Comparison</h2>
            
            <div className="input-row">
              <label htmlFor="reitYield">REIT Dividend Yield (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="reitYield"
                  min="3"
                  max="12"
                  step="0.1"
                  value={reitYield}
                  onChange={(e) => setReitYield(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={reitYield}
                  onChange={(e) => setReitYield(parseFloat(e.target.value) || 0)}
                  min="3"
                  max="12"
                  step="0.1"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="reitGrowth">REIT Growth (% p.a.)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="reitGrowth"
                  min="0"
                  max="15"
                  step="0.1"
                  value={reitGrowth}
                  onChange={(e) => setReitGrowth(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  value={reitGrowth}
                  onChange={(e) => setReitGrowth(parseFloat(e.target.value) || 0)}
                  min="0"
                  max="15"
                  step="0.1"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="reality-check">
            <h2>Reality Check</h2>
            <div className="reality-card">
              <p>Your apparent <strong>{formatPercent(monthlyRent * 12 / propertyPrice * 100)} rental yield</strong> is actually</p>
              <p className="actual-yield">{formatPercent(netRentalYield)} after all costs</p>
            </div>
          </div>
          
          <div className="summary-cards">
            <div className="summary-card">
              <h3>Total Hidden Costs</h3>
              <p className="amount">{formatCurrency(totalHiddenCosts)}</p>
              <p className="subtext">Over 10 years</p>
            </div>
            
            <div className="summary-card">
              <h3>Annual Cash Flow</h3>
              <p className="amount">{formatCurrency(annualCashFlow)}</p>
              <p className="subtext">Year 1</p>
            </div>
            
            <div className="summary-card">
              <h3>Net Rental Yield</h3>
              <p className="amount">{formatPercent(netRentalYield)}</p>
              <p className="subtext">After all expenses</p>
            </div>
          </div>
          
          <div className="comparison-section">
            <h2>10 Year Comparison</h2>
            <div className="comparison-cards">
              <div className="comparison-card property">
                <h3>Property Investment</h3>
                <p className="value">{formatCurrency(propertyReturn)}</p>
                <p className="return">Total Value</p>
              </div>
              
              <div className="comparison-card reit">
                <h3>REIT Investment</h3>
                <p className="value">{formatCurrency(reitReturn)}</p>
                <p className="return">Total Value</p>
              </div>
            </div>
          </div>
          
          <div className="yearly-breakdown">
            <h2>Yearly Breakdown</h2>
            <div className="breakdown-table">
              <div className="table-header">
                <div>Year</div>
                <div>Rent</div>
                <div>Costs</div>
                <div>Cash Flow</div>
              </div>
              {yearlyBreakdown.map((year) => (
                <div className="table-row" key={year.year}>
                  <div>{year.year}</div>
                  <div>{formatCurrency(year.rent)}</div>
                  <div>{formatCurrency(year.costs)}</div>
                  <div>{formatCurrency(year.cashFlow)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateROICalculator;