// Login.js

import React, { useState } from 'react';
import './Login.css'; // Import CSS file for styling

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(process.env.REACT_APP_API + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (!response.ok) {
        throw new Error('Failed to login');
      }
      const data = await response.json();
      const token = data.token;
      localStorage.setItem('token', token);
      console.log('Login successful');
    } catch (error) {
      setError('Login failed. Please check your credentials.');
      console.error('Error logging in:', error.message);
      // Handle error (e.g., display error message)
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input 
            type="text" 
            id="username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="btn-login">Login</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}

export default Login;