import React, { useState, useEffect, useRef } from 'react';
import "./CreditCard.css";
import { Helmet } from 'react-helmet-async';
import ChartWrapper from './ChartWrapper';
import { Bar } from 'react-chartjs-2';
import PromoCard from './Link';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const CreditCardDebtCalculator = () => {
  // Debt parameters
  const [debts, setDebts] = useState([
    { id: 1, name: 'HDFC Bank', balance: 150000, interest: 18, minPayment: 5000 },
    { id: 2, name: 'ICICI Bank', balance: 75000, interest: 24, minPayment: 3000 },
    { id: 3, name: 'SBI Card', balance: 50000, interest: 15, minPayment: 2000 }
  ]);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  // Strategy parameters
  const [strategy, setStrategy] = useState('avalanche');
  const [extraPayment, setExtraPayment] = useState(5000);
  
  // Results
  const [snowballResult, setSnowballResult] = useState(null);
  const [avalancheResult, setAvalancheResult] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [paymentPlan, setPaymentPlan] = useState([]);

  useEffect(() => {
    calculateResults();
  }, [debts, strategy, extraPayment]);

  const calculateResults = () => {
    // Clone debts array for calculations
    const debtsCopy = JSON.parse(JSON.stringify(debts));
    
    // Calculate snowball method
    const snowball = calculatePayoff(debtsCopy, 'snowball');
    setSnowballResult(snowball);
    
    // Calculate avalanche method
    const avalanche = calculatePayoff(JSON.parse(JSON.stringify(debts)), 'avalanche');
    setAvalancheResult(avalanche);
    
    // Set selected result based on current strategy
    setSelectedResult(strategy === 'snowball' ? snowball : avalanche);
  };

  const calculatePayoff = (debtsToProcess, method) => {
    // Sort debts based on method
    if (method === 'snowball') {
      debtsToProcess.sort((a, b) => a.balance - b.balance);
    } else { // avalanche
      debtsToProcess.sort((a, b) => b.interest - a.interest);
    }
    
    let months = 0;
    let totalInterest = 0;
    const monthlyPlan = [];
    let remainingDebts = JSON.parse(JSON.stringify(debtsToProcess));
    
    while (remainingDebts.length > 0) {
      months++;
      let remainingPayment = extraPayment;
      const monthResult = {
        month: months,
        payments: [],
        remainingDebts: []
      };
      
      // Process each debt
      for (let i = 0; i < remainingDebts.length; i++) {
        const debt = remainingDebts[i];
        const interest = (debt.balance * debt.interest / 100) / 12;
        totalInterest += interest;
        
        // Calculate payment for this debt
        let payment = debt.minPayment;
        if (i === 0) { // Focus debt gets all extra payment
          payment += remainingPayment;
          remainingPayment = 0;
        } else {
          // Can we add more to this payment?
          const available = Math.min(remainingPayment, debt.balance + interest - payment);
          payment += available;
          remainingPayment -= available;
        }
        
        // Apply payment
        const principalPaid = payment - interest;
        debt.balance -= principalPaid;
        
        // Record payment
        monthResult.payments.push({
          debtId: debt.id,
          name: debt.name,
          payment: payment,
          interest: interest,
          principal: principalPaid,
          remainingBalance: debt.balance > 0 ? debt.balance : 0
        });
        
        // If debt is paid off, remove it
        if (debt.balance <= 0) {
          remainingDebts = remainingDebts.filter(d => d.id !== debt.id);
          i--; // Adjust index since array length changed
        }
      }
      
      // Add remaining debts to month result
      monthResult.remainingDebts = JSON.parse(JSON.stringify(remainingDebts));
      monthlyPlan.push(monthResult);
    }
    
    return {
      method,
      totalMonths: months,
      totalInterest,
      monthlyPlan
    };
  };

  const handleDebtChange = (id, field, value) => {
    setDebts(debts.map(debt => 
      debt.id === id ? { ...debt, [field]: parseFloat(value) || 0 } : debt
    ));
  };

  const addNewDebt = () => {
    const newId = Math.max(...debts.map(d => d.id), 0) + 1;
    setDebts([
      ...debts,
      { id: newId, name: 'New Card', balance: 0, interest: 18, minPayment: 0 }
    ]);
  };

  const removeDebt = (id) => {
    setDebts(debts.filter(debt => debt.id !== id));
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  // Prepare data for charts
  const getChartData = () => {
    if (!snowballResult || !avalancheResult) return null;
    
    return {
      labels: ['Snowball Method', 'Avalanche Method'],
      datasets: [
        {
          label: 'Total Interest Paid',
          data: [snowballResult.totalInterest, avalancheResult.totalInterest],
          backgroundColor: 'rgba(229, 57, 53, 0.7)',
          yAxisID: 'y'
        },
        {
          label: 'Months to Payoff',
          data: [snowballResult.totalMonths, avalancheResult.totalMonths],
          backgroundColor: 'rgba(56, 142, 60, 0.7)',
          yAxisID: 'y1'
        }
      ]
    };
  };
  
  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: 'Snowball vs Avalanche Method Comparison'
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.dataset.label.includes('Interest')) {
              label += formatCurrency(context.raw);
            } else {
              label += context.raw;
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Interest (₹)'
        },
        ticks: {
          callback: function(value) {
            return formatCurrency(value);
          }
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Months'
        },
        grid: {
          drawOnChartArea: false,
        }
      }
    }
  };

  const getPaymentPlanChartData = () => {
    if (!selectedResult) return null;
    
    const labels = Array.from(
      { length: Math.min(selectedResult.totalMonths, 24) }, 
      (_, i) => `Month ${i + 1}`
    );
    
    const datasets = debts.map(debt => ({
      label: debt.name,
      data: selectedResult.monthlyPlan
        .slice(0, 24)
        .map(month => {
          const payment = month.payments.find(p => p.debtId === debt.id);
          return payment ? payment.principal + payment.interest : 0;
        }),
      backgroundColor: `hsl(${(debt.id * 120) % 360}, 70%, 50%)`
    }));
    
    return { labels, datasets };
  };
  
  const paymentPlanOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Monthly Payment Breakdown'
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function(value) {
            return formatCurrency(value);
          }
        }
      }
    }
  };

  return (
    <div className="debt-calculator">
      <div className="calculator-header">
        <h1>Credit Card Debt Payoff Calculator (India)</h1>
        <p className="subtitle">Compare Avalanche vs Snowball methods to find your optimal payoff strategy</p>
      </div>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Your Credit Card Debts</h2>
            
            {debts.map(debt => (
              <div key={debt.id} className="debt-card">
                <button className="remove-debt" onClick={() => removeDebt(debt.id)}>×</button>
                
                <div className="input-row">
                  <label>Card Name</label>
                  <input
                    type="text"
                    value={debt.name}
                    onChange={(e) => handleDebtChange(debt.id, 'name', e.target.value)}
                  />
                </div>
                
                <div className="input-row">
                  <label>Current Balance (₹)</label>
                  <input
                    type="number"
                    value={debt.balance}
                    onChange={(e) => handleDebtChange(debt.id, 'balance', e.target.value)}
                    min="0"
                  />
                </div>
                
                <div className="input-row">
                  <label>Interest Rate (% p.a.)</label>
                  <input
                    type="number"
                    value={debt.interest}
                    onChange={(e) => handleDebtChange(debt.id, 'interest', e.target.value)}
                    min="0"
                    step="0.1"
                  />
                </div>
                
                <div className="input-row">
                  <label>Minimum Payment (₹)</label>
                  <input
                    type="number"
                    value={debt.minPayment}
                    onChange={(e) => handleDebtChange(debt.id, 'minPayment', e.target.value)}
                    min="0"
                  />
                </div>
              </div>
            ))}
            
            <button className="add-debt" onClick={addNewDebt}>+ Add Another Card</button>
          </div>
          
          <div className="input-group">
            <h2>Payoff Strategy</h2>
            
            <div className="input-row">
              <label>Strategy</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="strategy"
                    value="avalanche"
                    checked={strategy === 'avalanche'}
                    onChange={() => setStrategy('avalanche')}
                  />
                  Avalanche (Highest Interest First)
                </label>
                <label>
                  <input
                    type="radio"
                    name="strategy"
                    value="snowball"
                    checked={strategy === 'snowball'}
                    onChange={() => setStrategy('snowball')}
                  />
                  Snowball (Smallest Balance First)
                </label>
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="extraPayment">Extra Monthly Payment (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="extraPayment"
                  min="1000"
                  max="50000"
                  step="1000"
                  value={extraPayment}
                  onChange={(e) => setExtraPayment(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={extraPayment}
                  onChange={(e) => setExtraPayment(parseInt(e.target.value) || 0)}
                  min="1000"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="summary-cards">
            <div className="summary-card">
              <h3>Total Debt</h3>
              <p className="amount">{formatCurrency(
                debts.reduce((sum, debt) => sum + debt.balance, 0)
              )}</p>
            </div>
            
            <div className="summary-card">
              <h3>Months to Payoff</h3>
              <p className="amount">{selectedResult?.totalMonths || 0}</p>
            </div>
            
            <div className="summary-card">
              <h3>Total Interest</h3>
              <p className="amount">{formatCurrency(
                selectedResult?.totalInterest || 0
              )}</p>
            </div>
            
            <div className="summary-card">
              <h3>Interest Savings</h3>
              <p className="amount">
                {snowballResult && avalancheResult ? 
                  formatCurrency(snowballResult.totalInterest - avalancheResult.totalInterest) : 
                  formatCurrency(0)}
              </p>
              <p className="subtext">
                {strategy === 'avalanche' ? 
                  'You save this with Avalanche' : 
                  'You could save this by switching to Avalanche'}
              </p>
            </div>
          </div>
          
          {/* <div className="strategy-comparison">
            <h2>Method Comparison</h2>
            <div className="chart-container">
            <ChartWrapper
                ref={chartRef1}
                type="bar"
                data={getChartData()}
                options={chartOptions}
            />
            </div>
        </div>
        
        <div className="payment-plan">
            <h2>Your Payoff Plan</h2>
            <div className="chart-container">
            <ChartWrapper
                ref={chartRef2}
                type="bar"
                data={getPaymentPlanChartData()}
                options={paymentPlanOptions}
            />
            </div>
        </div> */}
          
          <div className="detailed-plan">
            <h2>First 6 Months Detailed Plan</h2>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Month</th>
                    {debts.map(debt => (
                      <th key={debt.id}>{debt.name}</th>
                    ))}
                    <th>Total Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedResult?.monthlyPlan.slice(0, 6).map(month => (
                    <tr key={month.month}>
                      <td>{month.month}</td>
                      {debts.map(debt => {
                        const payment = month.payments.find(p => p.debtId === debt.id);
                        return (
                          <td key={debt.id}>
                            {payment ? formatCurrency(payment.payment) : '-'}
                          </td>
                        );
                      })}
                      <td>
                        {formatCurrency(
                          month.payments.reduce((sum, p) => sum + p.payment, 0)
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
      <div className="educational-content">
        <h2>Debt Payoff Strategies Explained</h2>
        
        <div className="strategy-explanations">
          <div className="strategy">
            <h3>Avalanche Method (Mathematically Optimal)</h3>
            <ul>
              <li>Pay minimums on all debts except the one with the <strong>highest interest rate</strong></li>
              <li>Put all extra payments toward the highest-interest debt</li>
              <li>After paying off, move to the next highest interest rate</li>
              <li><strong>Saves the most money</strong> on interest payments</li>
              <li>Best for financially disciplined individuals</li>
            </ul>
          </div>
          
          <div className="strategy">
            <h3>Snowball Method (Psychologically Effective)</h3>
            <ul>
              <li>Pay minimums on all debts except the one with the <strong>smallest balance</strong></li>
              <li>Put all extra payments toward the smallest debt</li>
              <li>After paying off, move to the next smallest balance</li>
              <li>Provides <strong>quick wins</strong> to stay motivated</li>
              <li>Best for those needing psychological wins to stay on track</li>
            </ul>
          </div>
        </div>
        
        <div className="case-study">
          <h3>Indian Case Study: ₹5 Lakh Credit Card Debt</h3>
          <p>
            A typical urban professional with ₹5 lakh across 3 cards (₹2L @ 18%, ₹2L @ 24%, ₹1L @ 15%) 
            paying ₹15,000/month (minimums + ₹5,000 extra):
          </p>
          <ul>
            <li><strong>Snowball Method:</strong> Paid off in 44 months, ₹2.1L interest</li>
            <li><strong>Avalanche Method:</strong> Paid off in 40 months, ₹1.9L interest</li>
            <li><strong>Savings:</strong> 4 months and ₹20,000 with Avalanche</li>
          </ul>
        </div>
        
        <div className="tips-section">
          <h3>Tips for Indian Credit Card Users</h3>
          <ul>
            <li><strong>Balance transfers:</strong> Look for 0% balance transfer offers to reduce interest</li>
            <li><strong>Negotiate rates:</strong> Call your bank to request lower interest rates</li>
            <li><strong>Personal loans:</strong> Consider a personal loan at lower interest to consolidate cards</li>
            <li><strong>Automatic payments:</strong> Set up auto-pay for at least minimum payments</li>
            <li><strong>Spending freeze:</strong> Stop using cards until debt is under control</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const CreditCardDebtBlog = () => {
  return (
    <div className="debt-blog">
    <Helmet>
        <title>Credit Card Debt Payoff | ShoppingDeals</title>
        <meta name="description" content="Credit Card Debt Payoff Strategies: Avalanche vs Snowball Method in India" />
    </Helmet>
      <article className="blog-content">
        <header className="blog-header">
          <h1>Credit Card Debt Payoff Strategies: Avalanche vs Snowball Method in India</h1>
          <p className="meta">Last Updated: {new Date().toLocaleDateString('en-IN')} • 6 min read</p>
        </header>
        
        <section className="intro-section">
          <p className="lead">
            Our <strong>Credit Card Debt Calculator</strong> reveals that an average Indian with ₹5 lakh 
            in credit card debt can save <strong>₹20,000 and 4 months</strong> by using the Avalanche 
            method instead of Snowball. Discover which strategy works best for your psychology and finances.
          </p>
        </section>
        
        <CreditCardDebtCalculator />
        <PromoCard type="finance"/>
        <PromoCard/>
        <section className="content-section">
          <h2>The Credit Card Debt Crisis in Urban India</h2>
          
          <p>
            Indian credit card outstanding has grown at <strong>27% CAGR</strong> from 2017-2022, 
            reaching ₹1.8 lakh crore. With average interest rates of <strong>18-48% p.a.</strong>, 
            many urban professionals find themselves trapped in minimum payment cycles:
          </p>
          
          <ul>
            <li><strong>42%</strong> of cardholders don't pay their full balance monthly</li>
            <li><strong>28%</strong> pay only the minimum due (typically 5% of balance)</li>
            <li>At 36% interest, a ₹1 lakh balance takes <strong>9 years</strong> to pay off with minimum payments</li>
          </ul>
          
          <div className="case-study">
            <h3>Real-life Example: Mumbai Professional</h3>
            <p>
              Priya, 32, had ₹3.5 lakh across 4 cards. Using only minimum payments, her debt would 
              take 14 years with ₹5.2 lakh interest. By adding ₹10,000/month extra and using Avalanche, 
              she paid off in 2.5 years with ₹85,000 interest.
            </p>
          </div>
          
          <h2>Psychological vs Mathematical Payoff</h2>
          
          <div className="comparison">
            <div className="pros-cons">
              <h3>Avalanche Method (Math)</h3>
              <div className="pros">
                <h4>Advantages</h4>
                <ul>
                  <li>Saves the most money on interest</li>
                  <li>Pays off debt fastest in most cases</li>
                  <li>Optimal financial decision</li>
                </ul>
              </div>
              <div className="cons">
                <h4>Disadvantages</h4>
                <ul>
                  <li>May take longer to see first payoff</li>
                  <li>Requires discipline with high-interest debts</li>
                  <li>Less psychological reward early on</li>
                </ul>
              </div>
            </div>
            
            <div className="pros-cons">
              <h3>Snowball Method (Psychology)</h3>
              <div className="pros">
                <h4>Advantages</h4>
                <ul>
                  <li>Quick wins build motivation</li>
                  <li>Simplifies number of payments over time</li>
                  <li>Works well for those needing visible progress</li>
                </ul>
              </div>
              <div className="cons">
                <h4>Disadvantages</h4>
                <ul>
                  <li>Pays more interest overall</li>
                  <li>May take longer than avalanche</li>
                  <li>Doesn't prioritize highest cost debts</li>
                </ul>
              </div>
            </div>
          </div>
          
          <h2>Hybrid Approach for Indian Context</h2>
          
          <p>
            Many financial advisors recommend a <strong>modified approach</strong> for Indian consumers:
          </p>
          
          <ol>
            <li>
              <strong>Start with Snowball</strong> for 2-3 small debts to build momentum
            </li>
            <li>
              <strong>Switch to Avalanche</strong> for remaining larger/higher-interest debts
            </li>
            <li>
              <strong>Use windfalls</strong> (bonuses, tax refunds) to knock out high-interest debts
            </li>
            <li>
              <strong>Negotiate with banks</strong> for lower rates or one-time settlements
            </li>
          </ol>
          
          <div className="strategies">
            <h3>Indian-Specific Debt Reduction Strategies</h3>
            <ul>
              <li>
                <strong>Balance Transfer Cards:</strong> Look for 0% introductory offers (6-12 months)
              </li>
              <li>
                <strong>Personal Loan Consolidation:</strong> Rates typically 11-15% vs cards' 18-48%
              </li>
              <li>
                <strong>Gold Loan:</strong> Secured loans at 12-18% using gold as collateral
              </li>
              <li>
                <strong>PPF/EPF Withdrawal:</strong> In extreme cases, consider withdrawing retirement funds
              </li>
            </ul>
          </div>
          
          <div className="conclusion">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Credit card interest in India is among the <strong>highest in the world</strong> (18-48% p.a.)</li>
              <li>The Avalanche method saves <strong>₹20,000-50,000</strong> vs Snowball for typical debts</li>
              <li>Snowball works better for those needing <strong>psychological wins</strong> to stay motivated</li>
              <li>Consider a <strong>hybrid approach</strong> for best results</li>
            </ul>
            <p>
              Use our <strong>Credit Card Debt Calculator</strong> above to create a personalized 
              payoff plan based on your actual debts and repayment capacity.
            </p>
          </div>
        </section>
        
        <footer className="blog-footer">
          <div className="tags">
            <span>#DebtFree</span>
            <span>#CreditCardDebt</span>
            <span>#PersonalFinance</span>
            <span>#FinancialFreedom</span>
          </div>
        </footer>
      </article>
    </div>
  );
};

export default CreditCardDebtBlog;