import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as RiIcons from 'react-icons/ri'
import * as GiIcons from 'react-icons/gi'
import * as GoIcons from 'react-icons/go'
import * as MdIcons from 'react-icons/md'
import { IconAirConditioning } from '@tabler/icons-react';
import { IconHomeQuestion } from '@tabler/icons-react';
import { IconReportMoney } from '@tabler/icons-react';
import { IconBed } from '@tabler/icons-react';
export var SidebarData = [
    {
        title: "Home",
        icon: <AiIcons.AiFillHome />,
        path: '/',
    },
    {
        title: "Amazon Deals",
        path: '/best-deals-on-amazon-india',
        icon: <MdIcons.MdShoppingCart />,
    },
    {
        title: "Hotel and Flight Deals",
        path: '/unlock-unbeatable-travel-deals-cheap-flights-hotels-makemytrip-coupons',
        icon: <MdIcons.MdFlightTakeoff/>,
    },
    {
        title: "Bus Deals",
        path: '/redbus-coupon-codes',
        icon: <FaIcons.FaBus/>,
    },
    {
        title: "Paytm Promo Code",
        path: '/paytm-bus-flight-promo-codes-2023',
        icon: <FaIcons.FaBusAlt/>,
    },
    {
        title: "Amazon Quiz",
        path: '/amazon-quiz',
        icon: <FaIcons.FaRegQuestionCircle  />,
    },
    {
        title: "Finance Tools",
        path: '/finance',
        icon: <FaIcons.FaMoneyBill  />,
    },
    {
        title: "Categories",
        icon: <RiIcons.RiStore2Fill />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Mini AC",
                path: '/mini-ac-portable-ac',
                icon: <IconAirConditioning />,
            },
            {
                title: "BedSheet",
                path: '/bedsheet',
                icon: <IconBed  />,
            },
            {
                title: "Laptop",
                path: '/laptop',
                icon: <MdIcons.MdLaptop  />,
            },
            {
                title: "Women's Fashion",
                path: '/women-fashion-discount',
                icon: <GiIcons.GiSkirt  />,
            },
        ]
    },
    {
        title: "Finance",
        icon: <IconReportMoney />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Rent Vs Buy",
                path: '/finance/rent-vs-buy-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "EMI Calculator",
                path: '/finance/emi-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Car Lease Vs Buy",
                path: '/finance/car-lease-vs-buy',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Education Cost",
                path: '/finance/education-cost-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "SIP Vs LumSum",
                path: '/finance/sip-vs-lumpsum-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Real Estate ROI",
                path: '/finance/real-estate-roi-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Loan Forclouser",
                path: '/finance/loan-forclosour-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Retirement Planner",
                path: '/finance/retirement-planner',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Credit Card Calculator",
                path: '/finance/credit-card-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "NRI Investment",
                path: '/finance/nri-investment-calculator',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Marriage Planner",
                path: '/finance/marriage-cost-planner',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            {
                title: "Side Hustle ROI",
                path: '/finance/side-hustle-roi',
                icon: <FaIcons.FaCaretSquareRight />,
            },
            
            
        ]
    },
    {
        title: "Blogs",
        icon: <RiIcons.RiStore2Fill />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "iPhone 15 Case",
                path: '/blogs/iphone-15-case',
                icon: <MdIcons.MdPhoneAndroid />,
                external: true, 
            },
        ]
    },
    {
        title: "Loot Deals",
        path: '/loot-deals',
        icon: <RiIcons.RiLightbulbFlashFill />,
    },
    {
        title: "About Us",
        path: '/aboutus',
        icon: <GiIcons.GiInfo />,
    },
    {
        title: "Sitemap",
        path: '/sitemap',
        icon: <FaIcons.FaSitemap />,
    }
]

if (process.env.NODE_ENV === 'development') {
    SidebarData = SidebarData.concat(
        {
            title: "Blogs",
            icon: <AiIcons.AiTwotonePrinter />,
            path: '/blogs',
        },
        {
            title: "Admin-tools",
            icon: <AiIcons.AiFillSetting />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: "Add Item",
                    path: '/Azscaledd',
                    icon: <FaIcons.FaAddressBook />,
                },
                {
                    title: "Update",
                    path: '/Uzscalepdate',
                    icon: <RiIcons.RiLightbulbFlashFill />,
                },
                {
                    title: "Search Term",
                    path: '/Szscalearchterm',
                    icon: <RiIcons.RiLightbulbFlashFill />,
                },
                {
                    title: "Item Clicked",
                    path: '/Izscaltemclicked',
                    icon: <RiIcons.RiLightbulbFlashFill />,
                },
                {
                    title: "Admin Log",
                    path: '/Azscaldminlog',
                    icon: <RiIcons.RiPagesLine />,
                },
                
            ]
        },
        {
            title: "Categories",
            icon: <RiIcons.RiStore2Fill />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: "Apparel",
                    path: '/category/apparel',
                    icon: <GiIcons.GiClothes />,
                },
                {
                    title: "Computers",
                    path: '/category/computers',
                    icon: <RiIcons.RiComputerFill />,
                },
                {
                    title: "Electronics",
                    path: '/category/electronics',
                    icon: <GoIcons.GoDeviceCamera />,
                }, {
                    title: "Industrial",
                    path: '/category/industrial',
                    icon: <FaIcons.FaIndustry />,
                }, {
                    title: "Kitchen",
                    path: '/category/kitchen',
                    icon: <MdIcons.MdKitchen />,
                }, {
                    title: "Software",
                    path: '/category/software',
                    icon: <RiIcons.RiUDiskFill />,
                }, {
                    title: "Watches",
                    path: '/category/watches',
                    icon: <GiIcons.GiWatch />,
                }, {
                    title: "Clothing",
                    path: '/category/clothing',
                    icon: <FaIcons.FaTshirt />,
                }, {
                    title: "E-accessories",
                    path: '/category/e-accessories',
                    icon: <FaIcons.FaHeadphones />,
                },
                {
                    title: "Footwear",
                    path: '/category/footwear',
                    icon: <GiIcons.GiRunningShoe />,
                },
                {
                    title: "Home",
                    path: '/category/home',
                    icon: <RiIcons.RiHomeGearFill />,
                },
                {
                    title: "Household",
                    path: '/category/household',
                    icon: <FaIcons.FaWarehouse />,
                },
                {
                    title: "Large-appliances",
                    path: '/category/large-appliances',
                    icon: <RiIcons.RiFridgeFill />,
                },
                {
                    title: "Mobiles",
                    path: '/category/mobiles',
                    icon: <FaIcons.FaMobile />,
                },
                {
                    title: "Personal-care",
                    path: '/category/personal-care',
                    icon: <GiIcons.GiGymBag />,
                },
            ]
        },
    )
} 
