import React from 'react';

const styles = {
  container: {
  fontFamily: 'Arial, sans-serif',
  lineHeight: 1.6,
  maxWidth: 1200,
  margin: '0 auto',
  padding: 20,
  paddingTop: 50
  },
  heading1: {
  color: '#1a237e',
  borderBottom: '2px solid #f50057',
  paddingBottom: 10,
  marginBottom: 25,
  },
  heading2: {
  color: '#283593',
  marginTop: 30,
  textAlign: 'left',
  },
  heading3: {
  paddingTop: '30px',
  color: '#3f51b5',
  textAlign: 'left',
  },
  offerSection: {
  backgroundColor: '#f5f5f5',
  padding: 15,
  borderRadius: 8,
  margin: '20px 0',
  textAlign: 'left',
  },
  keyword: {
  color: '#f50057',
  fontWeight: 'bold',
  textAlign: 'left',
  },
  termsNote: {
  backgroundColor: '#fff8e1',
  padding: 15,
  borderLeft: '4px solid #ffc107',
  margin: '15px 0',
  textAlign: 'left',
  },
  list: {
  paddingLeft: 20,
  textAlign: 'left',
  },
  dl: {
  margin: '20px 0',
  textAlign: 'left',
  },
  dt: {
  fontWeight: 'bold',
  marginTop: 15,
  textAlign: 'left',
  },
  dd: {
  marginLeft: 20,
  textAlign: 'left',
  },
  p2: {
  textAlign: 'left',
  },
  span: {
  textAlign: 'left',
  },
  li: {
  textAlign: 'left',
  }
};


const ExternalHTML = () => {
  return (
    <div style={styles.container}>
    <section style={styles.offerSection}>
        <h1 style={styles.heading1}>Save Big on Bus Travel with AbhiBus: Coupons, KSRTC Booking & More!</h1>
        <h2 style={styles.heading2}>AbhiBus Coupon Code</h2>
        <p2>Looking for the best discounts on bus tickets? Use an <strong>AbhiBus coupon code</strong> to get instant cashback or flat discounts on your next booking. Check their official website or partner platforms for the latest promo codes before checkout.</p2>

        <h2 style={styles.heading2}>AbhiBus Coupons</h2>
        <p2>AbhiBus frequently offers exclusive <strong>AbhiBus coupons</strong> during festivals, holidays, and special sale events. Subscribe to their newsletter or follow them on social media to stay updated on the latest deals.</p2>

        <h2 style={styles.heading2}>AbhiBus KSRTC</h2>
        <p2>Planning a trip with Karnataka State Road Transport Corporation (KSRTC)? <strong>AbhiBus KSRTC</strong> bookings make it easy to reserve seats online with real-time availability and secure payment options. Enjoy hassle-free travel with instant e-tickets.</p2>

        <h2 style={styles.heading2}>AbhiBus Referral Code</h2>
        <p2>Invite friends and earn travel credits with an <strong>AbhiBus referral code</strong>. Share your unique code, and when they book their first trip, both of you get bonus discounts for future rides.</p2>

        <h2 style={styles.heading2}>AbhiBus Customer Care</h2>
        <p2>Need assistance with bookings, cancellations, or refunds? <strong>AbhiBus customer care</strong> is available 24/7 via email, live chat, and phone support to resolve your queries quickly.</p2>

        <h2 style={styles.heading2}>AbhiBus Customer Care Number</h2>
        <p2>For urgent help, call the <strong>AbhiBus customer care number</strong> (usually listed on their website or app). Their team can assist with ticket modifications, payment issues, and bus schedule details.</p2>

        <h2 style={styles.heading2}>AbhiBus Bus Ticket Booking</h2>
        <p2>Booking bus tickets has never been easier! With <strong>AbhiBus bus ticket booking</strong>, you can compare prices, choose preferred seats, and secure your journey in minutes. Enjoy features like multiple payment options and instant confirmation.</p2>

        <p2><strong>Pro Tips for the Best AbhiBus Experience:</strong></p2>
        <p2>• Always check for coupon codes before final payment.</p2>
        <p2>• Book KSRTC or other state buses early for better seat availability.</p2>
        <p2>• Use the referral program to earn extra travel credits.</p2>
        <p2>• Save the customer care number for quick assistance during trips.</p2>
        <p2>• Opt for e-tickets to avoid last-minute hassles.</p2>

        <p2>With these tips and offers, your next bus journey will be affordable and stress-free. Happy traveling with AbhiBus!</p2>
    </section>
    <h2 style={styles.heading2}>Here’s How to Get the Best Deals on AbhiBus</h2>
</div>
  );
};

export default ExternalHTML;