import React, { useEffect } from "react";

const DislikePopup = (props) => {
  const itemId = props.id;
  const statusExpired = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API + "/statusexpired", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemId }),
      });
      if (res.status === 201) {
        const data = await res.json();
        if (data) {
          props.setTrigger(false);
          //props.setExp("expired");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="expired-popup">
        <div className="expired-popup-inner">
          <h4>Deal Expired ?</h4>
            <div className="expired-popup-btn">
              <button
                className="btn btn-success"
                type="submit"
                name="addcomment"
                id="addcomment"
                value="AddItem"
                onClick={statusExpired }
              >
                YES
              </button>

              <button
                className="btn btn-danger"
                onClick={() => props.setTrigger(false)}
              >
                NO
              </button>
            </div>
        </div>
      </div>
    </>
  );
};

export default DislikePopup;
