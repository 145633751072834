import {useEffect, useState} from 'react'

export default function GetItemUnderPrice(price, pageNumber, searchTerm) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [noitem, setNoItem] = useState(false);
    
    useEffect(() => {
        setItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price]);

    useEffect( () => {
        setLoading(true);
        setError(false);
        void async function fetchData() {
            try {
                // const res = await fetch(process.env.REACT_APP_API + '/getitemunderprice', {
                const res = await fetch( 'http://localhost:5000/getitemunderprice', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ price, pageNumber, searchTerm }),
                });
                const data = await res.json();
                //onsole.log(res.status);
                if (res.status === 201) {
                    setItems(prevItems => {
                        return [...prevItems, data.items]
                    });
                    setHasMore(data.hasMore);
                    setNoItem(data.noItem);
                    setLoading(false);
                    //console.log(data);
                } else {
                    setError(true);
                    const error = new Error(res.error);
                    throw error;
                }
            } catch (err) {
                console.log(err);
            }
        }();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price, pageNumber]);


    return {loading, error, items, hasMore, noitem};
}
