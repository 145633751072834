import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CarLeaseVsBuyCalculator = () => {
  // Vehicle Parameters
  const [vehicleType, setVehicleType] = useState('ice');
  const [carPrice, setCarPrice] = useState(1000000);
  const [fuelType, setFuelType] = useState('petrol');
  
  // Ownership Parameters
  const [ownershipYears, setOwnershipYears] = useState(5);
  const [annualMileage, setAnnualMileage] = useState(10000);
  const [resaleValue, setResaleValue] = useState(40);
  
  // Lease Parameters
  const [leaseMonthly, setLeaseMonthly] = useState(25000);
  const [leaseDownPayment, setLeaseDownPayment] = useState(100000);
  const [salarySacrifice, setSalarySacrifice] = useState(false);
  const [taxBracket, setTaxBracket] = useState(30);
  
  // Running Costs
  const [fuelCost, setFuelCost] = useState(100);
  const [evChargingCost, setEvChargingCost] = useState(8);
  const [maintenanceCost, setMaintenanceCost] = useState(15000);
  const [insuranceCost, setInsuranceCost] = useState(20000);
  
  // Results
  const [totalBuyCost, setTotalBuyCost] = useState(0);
  const [totalLeaseCost, setTotalLeaseCost] = useState(0);
  const [breakEvenYears, setBreakEvenYears] = useState(0);
  const [taxSavings, setTaxSavings] = useState(0);
  const [costComparison, setCostComparison] = useState([]);

  useEffect(() => {
    calculateCosts();
  }, [
    vehicleType, carPrice, fuelType, ownershipYears, annualMileage, resaleValue,
    leaseMonthly, leaseDownPayment, salarySacrifice, taxBracket, fuelCost,
    evChargingCost, maintenanceCost, insuranceCost
  ]);

  const calculateCosts = () => {
    // Calculate fuel/energy costs
    let fuelEnergyCost = 0;
    if (vehicleType === 'ice') {
      const mileageEfficiency = fuelType === 'petrol' ? 15 : 20; // kmpl
      const annualFuel = annualMileage / mileageEfficiency;
      fuelEnergyCost = annualFuel * fuelCost * ownershipYears;
    } else {
      const mileageEfficiency = 6; // km per kWh
      const annualEnergy = annualMileage / mileageEfficiency;
      fuelEnergyCost = annualEnergy * evChargingCost * ownershipYears;
    }
    
    // Calculate total buy cost
    const depreciation = carPrice * (100 - resaleValue) / 100;
    const totalMaintenance = maintenanceCost * ownershipYears;
    const totalInsurance = insuranceCost * ownershipYears;
    const buyTotal = depreciation + fuelEnergyCost + totalMaintenance + totalInsurance;
    setTotalBuyCost(buyTotal);
    
    // Calculate total lease cost
    let leaseTotal = leaseDownPayment + (leaseMonthly * 12 * ownershipYears);
    
    // Calculate tax savings if salary sacrifice
    let savings = 0;
    if (salarySacrifice) {
      savings = (leaseMonthly * 12 * taxBracket / 100) * ownershipYears;
      leaseTotal -= savings;
    }
    setTaxSavings(savings);
    setTotalLeaseCost(leaseTotal);
    
    // Calculate break-even point for EV vs ICE
    const evPremium = vehicleType === 'ev' ? 0 : 200000; // Example EV premium
    const iceFuelCost = (annualMileage / 15) * fuelCost * ownershipYears;
    const evEnergyCost = (annualMileage / 6) * evChargingCost * ownershipYears;
    const evMaintenanceSavings = (maintenanceCost * ownershipYears) * 0.4; // EVs cost 40% less to maintain
    const breakEven = evPremium / (
      ((annualMileage / 15) * fuelCost) - 
      ((annualMileage / 6) * evChargingCost) + 
      (maintenanceCost * 0.4)
    );
    setBreakEvenYears(breakEven);
    
    // Prepare data for comparison chart
    setCostComparison([
      {
        label: 'Depreciation/Lease',
        buy: depreciation,
        lease: leaseTotal - (fuelEnergyCost + totalMaintenance + totalInsurance)
      },
      {
        label: 'Fuel/Energy',
        buy: fuelEnergyCost,
        lease: fuelEnergyCost
      },
      {
        label: 'Maintenance',
        buy: totalMaintenance,
        lease: totalMaintenance
      },
      {
        label: 'Insurance',
        buy: totalInsurance,
        lease: totalInsurance
      },
      {
        label: 'Tax Savings',
        buy: 0,
        lease: -savings
      }
    ]);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="car-calculator">
      <div className="calculator-header">
        <h1>Car Lease vs Buy Calculator</h1>
        <p className="subtitle">Compare total cost of ownership including depreciation, fuel, and maintenance</p>
      </div>
      
      <div className="calculator-container">
        <div className="input-section">
          <div className="input-group">
            <h2>Vehicle Details</h2>
            
            <div className="input-row">
              <label>Vehicle Type</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="vehicleType"
                    value="ice"
                    checked={vehicleType === 'ice'}
                    onChange={() => setVehicleType('ice')}
                  />
                  ICE (Petrol/Diesel)
                </label>
                <label>
                  <input
                    type="radio"
                    name="vehicleType"
                    value="ev"
                    checked={vehicleType === 'ev'}
                    onChange={() => setVehicleType('ev')}
                  />
                  Electric Vehicle
                </label>
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="carPrice">Car Price (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="carPrice"
                  min="500000"
                  max="10000000"
                  step="50000"
                  value={carPrice}
                  onChange={(e) => setCarPrice(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={carPrice}
                  onChange={(e) => setCarPrice(parseInt(e.target.value) || 0)}
                  min="500000"
                />
              </div>
            </div>
            
            {vehicleType === 'ice' && (
              <div className="input-row">
                <label>Fuel Type</label>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="fuelType"
                      value="petrol"
                      checked={fuelType === 'petrol'}
                      onChange={() => setFuelType('petrol')}
                    />
                    Petrol
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="fuelType"
                      value="diesel"
                      checked={fuelType === 'diesel'}
                      onChange={() => setFuelType('diesel')}
                    />
                    Diesel
                  </label>
                </div>
              </div>
            )}
          </div>
          
          <div className="input-group">
            <h2>Ownership Parameters</h2>
            
            <div className="input-row">
              <label htmlFor="ownershipYears">Ownership Period (Years)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="ownershipYears"
                  min="1"
                  max="10"
                  value={ownershipYears}
                  onChange={(e) => setOwnershipYears(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={ownershipYears}
                  onChange={(e) => setOwnershipYears(parseInt(e.target.value) || 0)}
                  min="1"
                  max="10"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="annualMileage">Annual Mileage (km)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="annualMileage"
                  min="5000"
                  max="50000"
                  step="1000"
                  value={annualMileage}
                  onChange={(e) => setAnnualMileage(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={annualMileage}
                  onChange={(e) => setAnnualMileage(parseInt(e.target.value) || 0)}
                  min="5000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="resaleValue">Resale Value After {ownershipYears} Years (%)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="resaleValue"
                  min="10"
                  max="70"
                  value={resaleValue}
                  onChange={(e) => setResaleValue(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={resaleValue}
                  onChange={(e) => setResaleValue(parseInt(e.target.value) || 0)}
                  min="10"
                  max="70"
                />
              </div>
            </div>
          </div>
          
          <div className="input-group">
            <h2>Lease Details</h2>
            
            <div className="input-row">
              <label htmlFor="leaseMonthly">Monthly Lease Payment (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="leaseMonthly"
                  min="10000"
                  max="100000"
                  step="1000"
                  value={leaseMonthly}
                  onChange={(e) => setLeaseMonthly(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={leaseMonthly}
                  onChange={(e) => setLeaseMonthly(parseInt(e.target.value) || 0)}
                  min="10000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="leaseDownPayment">Lease Down Payment (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="leaseDownPayment"
                  min="0"
                  max="500000"
                  step="10000"
                  value={leaseDownPayment}
                  onChange={(e) => setLeaseDownPayment(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={leaseDownPayment}
                  onChange={(e) => setLeaseDownPayment(parseInt(e.target.value) || 0)}
                  min="0"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label>
                <input
                  type="checkbox"
                  checked={salarySacrifice}
                  onChange={() => setSalarySacrifice(!salarySacrifice)}
                />
                Corporate Lease (Salary Sacrifice)
              </label>
            </div>
            
            {salarySacrifice && (
              <div className="input-row">
                <label htmlFor="taxBracket">Your Tax Bracket (%)</label>
                <div className="range-container">
                  <input
                    type="range"
                    id="taxBracket"
                    min="5"
                    max="30"
                    step="5"
                    value={taxBracket}
                    onChange={(e) => setTaxBracket(parseInt(e.target.value))}
                  />
                  <input
                    type="number"
                    value={taxBracket}
                    onChange={(e) => setTaxBracket(parseInt(e.target.value) || 0)}
                    min="5"
                    max="30"
                    step="5"
                  />
                </div>
              </div>
            )}
          </div>
          
          <div className="input-group">
            <h2>Running Costs</h2>
            
            {vehicleType === 'ice' ? (
              <div className="input-row">
                <label htmlFor="fuelCost">{fuelType === 'petrol' ? 'Petrol' : 'Diesel'} Cost (₹/liter)</label>
                <div className="range-container">
                  <input
                    type="range"
                    id="fuelCost"
                    min="70"
                    max="120"
                    step="1"
                    value={fuelCost}
                    onChange={(e) => setFuelCost(parseInt(e.target.value))}
                  />
                  <input
                    type="number"
                    value={fuelCost}
                    onChange={(e) => setFuelCost(parseInt(e.target.value) || 0)}
                    min="70"
                    max="120"
                  />
                </div>
              </div>
            ) : (
              <div className="input-row">
                <label htmlFor="evChargingCost">EV Charging Cost (₹/kWh)</label>
                <div className="range-container">
                  <input
                    type="range"
                    id="evChargingCost"
                    min="5"
                    max="15"
                    step="0.5"
                    value={evChargingCost}
                    onChange={(e) => setEvChargingCost(parseFloat(e.target.value))}
                  />
                  <input
                    type="number"
                    value={evChargingCost}
                    onChange={(e) => setEvChargingCost(parseFloat(e.target.value) || 0)}
                    min="5"
                    max="15"
                    step="0.5"
                  />
                </div>
              </div>
            )}
            
            <div className="input-row">
              <label htmlFor="maintenanceCost">Annual Maintenance (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="maintenanceCost"
                  min="5000"
                  max="50000"
                  step="1000"
                  value={maintenanceCost}
                  onChange={(e) => setMaintenanceCost(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={maintenanceCost}
                  onChange={(e) => setMaintenanceCost(parseInt(e.target.value) || 0)}
                  min="5000"
                />
              </div>
            </div>
            
            <div className="input-row">
              <label htmlFor="insuranceCost">Annual Insurance (₹)</label>
              <div className="range-container">
                <input
                  type="range"
                  id="insuranceCost"
                  min="5000"
                  max="50000"
                  step="1000"
                  value={insuranceCost}
                  onChange={(e) => setInsuranceCost(parseInt(e.target.value))}
                />
                <input
                  type="number"
                  value={insuranceCost}
                  onChange={(e) => setInsuranceCost(parseInt(e.target.value) || 0)}
                  min="5000"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="results-section">
          <div className="summary-cards">
            <div className="summary-card buy">
              <h3>Total Buy Cost</h3>
              <p className="amount">{formatCurrency(totalBuyCost)}</p>
              <p className="subtext">Over {ownershipYears} years</p>
            </div>
            
            <div className="summary-card lease">
              <h3>Total Lease Cost</h3>
              <p className="amount">{formatCurrency(totalLeaseCost)}</p>
              <p className="subtext">Over {ownershipYears} years</p>
            </div>
            
            <div className={`summary-card ${totalBuyCost < totalLeaseCost ? 'buy' : 'lease'}`}>
              <h3>Better Option</h3>
              <p className="amount">
                {totalBuyCost < totalLeaseCost ? 'BUY' : 'LEASE'}
              </p>
              <p className="subtext">
                {formatCurrency(Math.abs(totalBuyCost - totalLeaseCost))} {totalBuyCost < totalLeaseCost ? 'cheaper' : 'more expensive'}
              </p>
            </div>
            
            {vehicleType === 'ice' && (
              <div className="summary-card ev">
                <h3>EV Break-Even</h3>
                <p className="amount">
                  {breakEvenYears.toFixed(1)} years
                </p>
                <p className="subtext">
                  For similar EV to become cheaper
                </p>
              </div>
            )}
            
            {salarySacrifice && (
              <div className="summary-card tax">
                <h3>Tax Savings</h3>
                <p className="amount">{formatCurrency(taxSavings)}</p>
                <p className="subtext">Through salary sacrifice</p>
              </div>
            )}
          </div>
          
          <div className="cost-comparison">
            <h2>Cost Breakdown Comparison</h2>
            <div className="chart-container">
              <Bar
                data={{
                  labels: costComparison.map(item => item.label),
                  datasets: [
                    {
                      label: 'Buy',
                      data: costComparison.map(item => item.buy),
                      backgroundColor: 'rgba(54, 162, 235, 0.7)'
                    },
                    {
                      label: 'Lease',
                      data: costComparison.map(item => item.lease),
                      backgroundColor: 'rgba(255, 99, 132, 0.7)'
                    }
                  ]
                }}
                options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Cost Components Comparison'
                    },
                    tooltip: {
                      callbacks: {
                        label: function(context) {
                          return `${context.dataset.label}: ${formatCurrency(context.raw)}`;
                        }
                      }
                    }
                  },
                  scales: {
                    x: {
                      stacked: false,
                    },
                    y: {
                      stacked: false,
                      ticks: {
                        callback: function(value) {
                          if (value >= 10000000) return `${value/10000000} Cr`;
                          if (value >= 100000) return `${value/100000} L`;
                          return value;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          
          <div className="tips-section">
            <h2>Recommendations</h2>
            <div className="tips-grid">
              {totalBuyCost < totalLeaseCost ? (
                <div className="tip-card buy-tip">
                  <h3>Buying is Better When:</h3>
                  <ul>
                    <li>You plan to keep the car long-term (5+ years)</li>
                    <li>You can claim depreciation benefits (business use)</li>
                    <li>You want to build equity in the asset</li>
                    <li>Interest rates on loans are low</li>
                  </ul>
                </div>
              ) : (
                <div className="tip-card lease-tip">
                  <h3>Leasing is Better When:</h3>
                  <ul>
                    <li>You prefer driving new cars every few years</li>
                    <li>You can get corporate/salary sacrifice benefits</li>
                    <li>You want predictable monthly costs</li>
                    <li>Maintenance is included in the lease</li>
                  </ul>
                </div>
              )}
              
              {vehicleType === 'ice' && breakEvenYears < 5 && (
                <div className="tip-card ev-tip">
                  <h3>Consider EV Switch If:</h3>
                  <ul>
                    <li>You drive {annualMileage} km/year or more</li>
                    <li>You can charge at home/work</li>
                    <li>You qualify for EV subsidies</li>
                    <li>You want lower maintenance costs</li>
                  </ul>
                </div>
              )}
              
              {salarySacrifice && (
                <div className="tip-card tax-tip">
                  <h3>Maximize Salary Sacrifice:</h3>
                  <ul>
                    <li>Choose EVs for additional tax benefits</li>
                    <li>Opt for longer lease terms (3-5 years)</li>
                    <li>Include maintenance in the lease package</li>
                    <li>Compare with company car alternatives</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarLeaseVsBuyCalculator;