import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import ExternalComponent from './CouponHome'; // Importing Div2
import ExternalHTML from './CouponHtmlMmt'; // Importing Div3f
import logo from '../../asset/mmt.png'


const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);
  
    useEffect(() => {
      const media = window.matchMedia(query);
      setMatches(media.matches);
      const listener = () => setMatches(media.matches);
      media.addListener(listener);
      return () => media.removeListener(listener);
    }, [query]);
  
    return matches;
  };

const CardComponent = () => {
  const isMobile = useMediaQuery('(max-width: 600px)'); // Check if screen is mobi
  
  const handleShowMoreClick = () => {
    const div3Element = document.getElementById('div3');
    if (div3Element) {
      div3Element.scrollIntoView({ behavior: 'smooth' });
    }
  };


// Styles
const styles = {
    topDiv: {
      paddingTop: '55px',
    },
    card: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto 16px',
      padding: '6px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      display: 'flex',
      minHeight: '120px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
      boxSizing: 'border-box'
    },
    cardContainer: {
      display: 'flex',
      // flexDirection: 'row', // Default layout: logo on the left
      flexDirection: isMobile ? 'column' : 'row', // Stack on mobile, row on desktop
      alignItems: isMobile ? 'flex-start' : 'center', 
      // alignItems: 'center',
      minHeight: '80px',
    },
    column1: {
      // width: '25%',
      width: isMobile ? '100%' : '25%', // Full width on mobile, 25% on desktop
      textAlign: isMobile ? 'center' : 'left', // Center logo on mobile
      marginBottom: isMobile ? '8px' : '0', // Add spacing below logo on mobile
    },
    column2: {
      // width: '75%',
      // paddingLeft: '16px',
      width: isMobile ? '95%' : '75%', // Full width on mobile, 75% on desktop
      paddingLeft: isMobile ? '0' : '8px', // Remove padding on mobile
      boxSizing: 'border-box'
    },
    logo: {
      width: isMobile ? '25%':'80%',
      height: 'auto',
    },
    heading: {
      margin: '0 0 8px 0',
      textAlign: 'left',
      wordWrap: 'break-word', // Ensure heading text wraps
      overflowWrap: 'break-word', // Ensure long words break
      fontWeight: 'bold',
      fontSize: '25px'
    },
    subheadingContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row', 
      justifyContent: 'space-between',
      alignItems: 'center', // Align subheading and link vertically
      marginBottom: '4px',
      width: '100%',
    },
    subheading: {
      margin: 0,
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left', // Align subheading to the left
      flex: 1, // Allow subheading to take remaining space
      marginRight: '8px', // Add some spacing between subheading and link
      wordWrap: 'break-word', // Ensure subheading text wraps
      overflowWrap: 'break-word', // Ensure long words break
    },
    showMoreLink: {
      color: '#007bff',
      textDecoration: 'underline',
      cursor: 'pointer',
      whiteSpace: 'nowrap', // Prevent link from wrapping
    },
    dataRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '30px'
    },
    div3: {
      width: '100%',
      maxWidth: '800px',
      margin: '10px auto 16px',
      padding: '10px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      display: 'flex',
      minHeight: '120px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
    },
    
  };

  return (
      <div>
      <Helmet>
        <title>MakeMyTrip Deals and Coupons</title>
        <meta name="description" content="Find Cheap Deals on Flights and Hotels. MakeMyTrip deals and Coupons" />
      </Helmet>
        <div style={styles.topDiv}></div>
      {/* Div1: Card */}
      <div style={styles.card}>
        <div style={styles.cardContainer}>
          {/* First Column: Logo */}
          <div style={styles.column1}>
            <img src={logo} alt="Logo" style={styles.logo} />
          </div>

          {/* Second Column: Heading, Subheading, Button, and Data Rows */}
          <div style={styles.column2}>
            <h1 style={styles.heading}>Find Cheap Deals on Flights and Hotels</h1>
        
            {/* <p style={styles.subheading}>
              This is a long subheading that will truncate at the end of the line...
            </p>
            <button onClick={handleShowMoreClick} style={styles.button}>
              Show More
            </button> */}
            <div style={styles.subheadingContainer}>
              <p style={styles.subheading}>
              Are you dreaming of your next vacation but worried about the cost? we specialize in helping you find the best travel deals . . .
              </p>
              <a onClick={handleShowMoreClick} style={styles.showMoreLink}>
                Show More
              </a>
            </div>

            {/* Data Rows */}
            <div style={styles.dataRow}>
              <span>Reviews: 120</span>
              <span>Likes: 450</span>
              <span>Shares: 80</span>
            </div>
          </div>
        </div>
      </div>

      {/* Div2: External React Component */}
      {/* <div> */}
        <ExternalComponent endpoint = "/coupons/vendor/MakeMyTrip"/>
        {/* <ExternalComponent endpoint = "/coupons/vendor/Amazon"/> */}
      {/* </div> */}

      {/* Div3: External HTML Element */}
      <div id="div3" style={styles.div3}>
        <ExternalHTML />
      </div>
    </div>
  );
};


export default CardComponent;